import { LanguageStore, ViewContext } from "@manyducks.co/dolla";
import { TabBar } from "@views/TabBar";
import styles from "./Admin.module.css";

export function Admin(props: {}, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  return (
    <div class={styles.layout}>
      <TabBar
        tabs={[
          {
            href: "/admin/server-stats",
            active: (pattern) => pattern.startsWith("/admin/server-stats"),
            text: translate("workspace.admin.serverStats.tabName"),
          },
        ]}
      />

      <div class={styles.content}>{ctx.outlet()}</div>
    </div>
  );
}
