import { $$, type StoreContext } from "@manyducks.co/dolla";

/**
 * Manages the app badge (unread count) for mobile home screen apps.
 */
export function BadgeStore(ctx: StoreContext) {
  const $$number = $$<number>(0);

  ctx.observe($$number, (value) => {
    (navigator as any).setAppBadge?.(value);
    (navigator as any).setClientBadge?.(value);
  });

  return {
    increment: () => {
      $$number.update((n) => n + 1);
    },
    set: (value: number) => {
      $$number.set(value);
    },
    clear: () => {
      $$number.set(0);
    },
  };
}
