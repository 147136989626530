import { $$, unwrap, LanguageStore, type DialogProps, type ViewContext } from "@manyducks.co/dolla";
import { type Project } from "schemas";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";

interface LeaveProjectDialogProps extends DialogProps {
  project: Project;

  /**
   * Called when confirmation is successful
   */
  onConfirm: () => void;
}

export function LeaveProjectDialog(props: LeaveProjectDialogProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  const $$open = $$(props.$$open);
  const onConfirm = unwrap(props.onConfirm);

  return (
    <Dialog
      accentColor={props.project.color}
      onsubmit={() => {
        onConfirm();
        $$open.set(false);
      }}
      transitionIn={props.transitionIn}
      transitionOut={props.transitionOut}
    >
      <DialogHeader
        $$open={$$open}
        title={translate("workspace.project.overview.actions.leaveProject.dialogTitle")}
      />

      <DialogContent>
        <p style={{ padding: "8px 0" }}>
          {translate("workspace.project.overview.actions.leaveProject.dialogMessage", {
            project: props.project.name,
          })}
        </p>
      </DialogContent>

      <DialogFooter>
        <Button type="submit">
          {translate("workspace.project.overview.actions.leaveProject.confirmButtonLabel")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
