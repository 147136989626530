import { $, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { type Project } from "schemas";
import { ThemeStore } from "@stores/ThemeStore";
import FolderIcon from "@views/@icons/Folder";
import styles from "./ProjectCharm.module.css";

interface ProjectCharmProps {
  project: Project | Readable<Project | undefined> | undefined;
}

export function ProjectCharm(props: ProjectCharmProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);
  const $project = $(props.project);

  return (
    <div class={styles.projectCharm} style={theme.getThemeVariables$($($project, (p) => p?.color))}>
      <div class={styles.charmIcon}>
        <FolderIcon />
      </div>
      <span>{$($project, (p) => p?.name ?? "???")}</span>
    </div>
  );
}
