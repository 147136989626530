import {
  $,
  $$,
  DialogStore,
  HTTPStore,
  LanguageStore,
  RouterStore,
  cond,
  repeat,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { IOStore } from "@stores/IOStore";
import { NavStore } from "@stores/NavStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import Plus from "@views/@icons/Plus";
import Update from "@views/@icons/Update";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { type Project } from "schemas";
import { ConfirmDeclineDialog } from "./ConfirmDeclineDialog/ConfirmDeclineDialog";
import { CreateProjectDialog } from "./CreateProjectDialog/CreateProjectDialog";
import { FavoriteListItem } from "./FavoriteListItem/FavoriteListItem";
import styles from "./NavMenu.module.css";
import { ProjectInviteListItem } from "./ProjectInviteListItem/ProjectInviteListItem";
import { ProjectListItem } from "./ProjectListItem/ProjectListItem";

interface NavMenuProps {
  onNavigate?: () => void;
}

export function NavMenu(props: NavMenuProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const io = ctx.getStore(IOStore);
  const http = ctx.getStore(HTTPStore);
  const dialog = ctx.getStore(DialogStore);
  const router = ctx.getStore(RouterStore);
  const nav = ctx.getStore(NavStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $projects = $(projects.$cache, (projects) => [...projects].sort(sortByName));
  const $invites = $(projects.$invites, (invites) =>
    [...invites].sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return +1;
      } else {
        return 0;
      }
    }),
  );

  const $$projectId = $$<number>();

  const socket = io.socket();

  function refreshProjects() {
    return new Promise((resolve, reject) => {
      if (socket.connected) {
        socket.emit("projects:refresh", () => {
          Promise.all([projects.refreshList(), auth.getUsers()]).then(resolve).catch(reject);
        });
      } else {
        Promise.all([projects.refreshList(), auth.getUsers()]).then(resolve).catch(reject);
      }
    });
  }

  async function acceptInvite(id: number) {
    // 1. Send API call to accept invite
    // 2. Send socket message to update room subscriptions
    // 3. Refresh projects
    // 4. Refresh users

    const invite = $invites.get().find((i) => i.id === id);
    if (!invite) {
      throw new Error(`Invite not loaded: ${id}`);
    }

    await http.post(`/api/projects/invites/${id}/accept`);
    await refreshProjects();
  }

  async function declineInvite(id: number) {
    await http.post(`/api/projects/invites/${id}/decline`);
    await refreshProjects();
  }

  return (
    <div class={styles.layout}>
      {cond(
        auth.$updateAvailable,
        <div class={styles.menuSection}>
          <button
            class={styles.updateButton}
            onClick={() => {
              // Reload page
              window.location.href = window.location.href;
            }}
          >
            <div class={styles.updateButtonIcon}>
              <Update />
            </div>
            {translate("workspace.nav.applyUpdateButtonText")}
          </button>
        </div>,
      )}

      <div class={styles.content}>
        <ul class={styles.menuList}>
          <li>
            <a
              href="/me"
              class={[
                styles.navLink,
                styles.adminLink,
                { [styles.active]: $(router.$path, (p) => p.startsWith("/me")) },
              ]}
              onClick={() => {
                props.onNavigate?.();
              }}
            >
              <div class={styles.navLinkIcon}>
                <img src="/icons/quackstats.8.png" alt="" width="16" height="16" />
              </div>
              {translate("workspace.nav.me.label")}
            </a>
          </li>
          {cond(
            $(auth.$me, (me) => me?.isServerAdmin),
            <li>
              <a
                href="/admin"
                class={[
                  styles.navLink,
                  styles.adminLink,
                  { [styles.active]: $(router.$path, (p) => p.startsWith("/admin")) },
                ]}
                onClick={() => {
                  props.onNavigate?.();
                }}
              >
                <div class={styles.navLinkIcon}>
                  <img src="/icons/quackstats.8.png" alt="" width="16" height="16" />
                </div>
                {translate("workspace.nav.admin.label")}
              </a>
            </li>,
          )}
        </ul>

        {cond(
          $(nav.$favorites, (f) => f.length > 0),
          <CollapsibleListSection
            title={translate("workspace.nav.favorites.title")}
            itemCount={$(nav.$favorites, (f) => f.length)}
          >
            <ul class={styles.menuList}>
              {repeat(
                nav.$favorites,
                (f) => f.path,
                ($f) => (
                  <FavoriteListItem
                    $favorite={$f}
                    onClick={() => {
                      props.onNavigate?.();
                    }}
                  />
                ),
              )}
            </ul>
          </CollapsibleListSection>,
        )}

        {cond(
          $($invites, (i) => i.length > 0),
          <CollapsibleListSection
            title={translate("workspace.nav.projectInvites.title")}
            itemCount={$($invites, (i) => i.length)}
          >
            <ul class={styles.menuList}>
              {repeat(
                $invites,
                (i) => i.id,
                ($invite) => (
                  <ProjectInviteListItem
                    invite={$invite}
                    onAccept={() => {
                      const invite = $invite.get();
                      acceptInvite(invite.id);
                    }}
                    onDecline={() => {
                      const invite = $invite.get();
                      dialog.open(ConfirmDeclineDialog, {
                        accentColor: invite.project.color,
                        onConfirm: () => {
                          declineInvite(invite.id);
                        },
                      });
                    }}
                  />
                ),
              )}
            </ul>
          </CollapsibleListSection>,
        )}

        <CollapsibleListSection
          title={translate("workspace.nav.projects.title")}
          itemCount={$($projects, (p) => p.length)}
          controls={[
            {
              id: "add",
              title: translate("workspace.nav.projects.createProjectDialog.title"),
              icon: <Plus />,
              variant: "accent",
              onClick: (e) => {
                const me = auth.$me.get()!;
                dialog.open(CreateProjectDialog, {
                  accentColor: me.color,
                  onConfirm: async (info) => {
                    const project = await projects.createProject(info);
                    router.navigate(`/projects/${project.id}`);
                    props.onNavigate?.();
                  },
                });
              },
            },
          ]}
        >
          <ul class={styles.menuList}>
            {repeat(
              $projects,
              (p) => p.id,
              ($project) => {
                return (
                  <ProjectListItem
                    $project={$project}
                    onClick={() => {
                      props.onNavigate?.();
                    }}
                  />
                );
              },
            )}
          </ul>
        </CollapsibleListSection>
      </div>
    </div>
  );
}

function sortByName(a: Project, b: Project) {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  if (aName < bName) {
    return -1;
  } else if (aName > bName) {
    return 1;
  } else {
    return 0;
  }
}
