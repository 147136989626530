import { $$, type ViewContext, type Writable } from "@manyducks.co/dolla";
import styles from "./Card.module.css";

interface CardContentProps {
  /**
   * Ref to receive the content DOM node.
   */
  ref?: Writable<HTMLElement | undefined>;

  initialScrollPosition?: number;
}

/**
 * Holds the card's main content.
 */
export function CardContent(props: CardContentProps, ctx: ViewContext) {
  const ref = $$(props.ref);
  const render = ctx.getStore("render");

  if (props.initialScrollPosition) {
    ctx.onConnected(() => {
      setTimeout(() => {
        render.update(() => {
          ref.get()?.scrollTo({
            top: props.initialScrollPosition!,
            behavior: "smooth",
          });
        });
      }, 100);
    });
  }

  return (
    <div class={styles.cardContent} ref={ref}>
      {ctx.outlet()}
    </div>
  );
}
