export default {
  "chat": "Chat_chat_7a635",
  "layout": "Chat_layout_7a635",
  "pushDisabledBanner": "Chat_pushDisabledBanner_7a635",
  "pushDisabledBannerControls": "Chat_pushDisabledBannerControls_7a635",
  "endOfMessages": "Chat_endOfMessages_7a635",
  "dividerLine": "Chat_dividerLine_7a635",
  "dividerLabel": "Chat_dividerLabel_7a635",
  "chatHeader": "Chat_chatHeader_7a635",
  "chatTitle": "Chat_chatTitle_7a635",
  "listContent": "Chat_listContent_7a635",
  "chatList": "Chat_chatList_7a635",
  "chatListItem": "Chat_chatListItem_7a635",
  "chatListItemIcon": "Chat_chatListItemIcon_7a635",
  "active": "Chat_active_7a635"
};import "ni:sha-256;IsqPq6G8gOEM9YJ5cVyeyC4MbBqR5Y850O9XvF59w5o";