export default {
  "layout": "MySettings_layout_ede71",
  "content": "MySettings_content_ede71",
  "formToolBar": "MySettings_formToolBar_ede71",
  "formGroup": "MySettings_formGroup_ede71",
  "userName": "MySettings_userName_ede71",
  "nameForm": "MySettings_nameForm_ede71",
  "nameFormButtons": "MySettings_nameFormButtons_ede71",
  "userStatus": "MySettings_userStatus_ede71",
  "userAvatar": "MySettings_userAvatar_ede71",
  "userColorInput": "MySettings_userColorInput_ede71",
  "userColorButtons": "MySettings_userColorButtons_ede71",
  "editor": "MySettings_editor_ede71",
  "editorButtons": "MySettings_editorButtons_ede71",
  "formLabel": "MySettings_formLabel_ede71",
  "sectionHeader": "MySettings_sectionHeader_ede71",
  "headerTitle": "MySettings_headerTitle_ede71",
  "sectionList": "MySettings_sectionList_ede71",
  "settingsButton": "MySettings_settingsButton_ede71",
  "flowVertical": "MySettings_flowVertical_ede71",
  "statusLabel": "MySettings_statusLabel_ede71",
  "toggleGroup": "MySettings_toggleGroup_ede71",
  "colorSchemeOption": "MySettings_colorSchemeOption_ede71"
};import "ni:sha-256;NcSUAjyvTRBKN-lPbqDryHIBfcPwOE2j6-tIWJImDX8";