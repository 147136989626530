import { $, $$, LanguageStore, cond, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { AssignMenu } from "@views/TaskListItem/AssignMenu";
import { AuthStore } from "@stores/AuthStore";
import { ThemeStore } from "@stores/ThemeStore";
import DownArrow from "@views/@icons/DownArrow";
import { HoverMenu } from "@views/HoverMenu";
import { UserAvatar } from "@views/UserAvatar";
import styles from "./UserPicker.module.css";

interface UserPickerProps {
  $selectedId: Readable<number | undefined>;
  $projectId: Readable<number>;
  onchange: (newId: number | undefined) => void;
  $disabled?: Readable<boolean>;
  emptyLabel?: string | Readable<string>;
}

export function UserPicker({ $selectedId, onchange, ...props }: UserPickerProps, ctx: ViewContext) {
  const { $users } = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  // const $disabled = readable(props.$disabled ?? false);
  const $selected = $($users, $selectedId, (users, id) => users?.find((u) => u.id === id));

  const $project = $(props.$projectId, projects.$cache, (id, projects) => projects.find((p) => p.id === id));

  const $$assignMenuIsOpen = $$(false);
  const $$assignMenuAnchor = $$<HTMLElement>();

  return (
    <div
      class={styles.container}
      style={theme.getThemeVariables$(
        $($selected, $project, (user, project) => user?.color ?? project?.color),
      )}
    >
      <button
        type="button"
        ref={$$assignMenuAnchor}
        class={styles.button}
        onClick={(e) => {
          $$assignMenuIsOpen.set(true);
        }}
      >
        {cond(
          $selected,
          <div class={styles.avatar}>
            <UserAvatar src={$($selected, (user) => user?.avatar ?? "")} />
          </div>,
        )}
        <div class={styles.label}>
          {$(
            $selected,
            $currentLanguage,
            (user) => user?.name ?? translate("views.userPicker.notAssignedLabel").get(),
          )}
        </div>
        <div class={styles.indicator}>
          <DownArrow />
        </div>
      </button>

      <HoverMenu
        $$open={$$assignMenuIsOpen}
        $anchorRef={$$assignMenuAnchor}
        color={$($project, (p) => p?.color)}
        distanceFromAnchor={14}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
      >
        <AssignMenu
          $projectId={props.$projectId}
          $value={$($selectedId, (id) => id ?? null)}
          onChange={(user) => {
            onchange(user?.id);
            $$assignMenuIsOpen.set(false);
          }}
        />
      </HoverMenu>

      {/* <Searchdown<number | null>
        size={props.size}
        color={computed($selected, (u) => u?.color ?? "#888")}
        $value={computed($selectedId, (id) => id ?? null)}
        suggestions={$suggestions}
        emptyLabel={props.emptyLabel}
        onInputChange={(value) => {
          ctx.log({ value });
        }}
        onSelect={(suggestion) => {
          ctx.log({ suggestion });
          onchange(suggestion.value as number);
        }}
      /> */}
    </div>
  );
}
