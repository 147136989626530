import { $, $$, cond, LanguageStore, Readable, repeat, ViewContext } from "@manyducks.co/dolla";
import styles from "./DayDetails.module.css";
import { CardContent, CardToolbar } from "@views/Card";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import { BackButton } from "@views/BackButton";
import { sticky } from "@helpers/sticky";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { TasksStore } from "@stores/TasksStore";
import { Task } from "schemas";
import { getHours, isBefore, isSameDay, startOfDay } from "date-fns";
import { parseDueDate } from "../parseDueDate";
import { TaskListItem } from "@views/TaskListItem";
import { TextInput } from "@views/TextInput";
import { Button } from "@views/Button";
import { AuthStore } from "@stores/AuthStore";
import { ProjectCharm } from "@views/Charms";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import Time from "@views/@icons/Time";

interface DayDetailsProps {
  $selectedDate: Readable<Date | undefined>;
  onBackButtonClicked: () => void;
}

export function DayDetails(props: DayDetailsProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const tasks = ctx.getStore(TasksStore);
  const projects = ctx.getStore(ProjectsStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const $latestDate = $(props.$selectedDate, sticky());

  const $tasks = $(auth.$me, tasks.$cache, props.$selectedDate, (me, tasks, date) => {
    let matches: Task[] = [];

    if (date != null && me != null) {
      for (const task of tasks.values()) {
        if (task.assignedUserId !== me.id) continue;

        if (task.dueDate != null && isSameDay(parseDueDate(task.dueDate), date)) {
          matches.push(task);
        } else if (task.completedAt != null && isSameDay(task.completedAt, date)) {
          matches.push(task);
        }
      }
    }

    return matches;
  });

  const $activeTasks = $($tasks, (tasks) => tasks.filter((t) => t.completedAt == null));
  const $completedTasks = $($tasks, (tasks) => tasks.filter((t) => t.completedAt != null));

  const $completedGroups = $($completedTasks, $currentLanguage, (tasks, lang) => groupByHour(tasks, lang!));

  return (
    <div class={styles.layout}>
      <CardContent>
        <div class={styles.content}>
          <h1>
            {$($currentLanguage, $latestDate, (lang, date) => {
              const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              });
              return formatter.format(date);
            })}
          </h1>

          <CollapsibleListSection
            title={translate("workspace.project.tasks.calendar.dayView.due.title")}
            itemCount={$($activeTasks, (x) => x.length)}
          >
            {cond(
              $($activeTasks, (x) => x.length === 0),
              <p class={styles.emptyListMessage}>
                {translate("workspace.project.tasks.calendar.dayView.due.emptyListMessage")}
              </p>,
              <ul class={styles.taskList}>
                {repeat(
                  $activeTasks,
                  (t) => t.id,
                  ($task) => {
                    const $project = $($task, projects.$cache, (task, projects) =>
                      projects.find((p) => p.id === task.projectId),
                    );
                    return (
                      <li style={theme.getThemeVariables$($($project, (p) => p?.color))}>
                        <TaskListItem
                          $task={$task}
                          $charms={$($task, (t) => {
                            return <ProjectCharm project={$project} />;
                          })}
                        />
                      </li>
                    );
                  },
                )}
              </ul>,
            )}
          </CollapsibleListSection>

          <CollapsibleListSection
            title={translate("workspace.project.tasks.calendar.dayView.done.title")}
            itemCount={$($completedTasks, (x) => x.length)}
          >
            {cond(
              $($completedTasks, (x) => x.length === 0),
              <p class={styles.emptyListMessage}>
                {translate("workspace.project.tasks.calendar.dayView.done.emptyListMessage")}
              </p>,
              <ul class={styles.taskList}>
                {repeat(
                  $completedGroups,
                  (g) => g.hour,
                  ($group, $index) => {
                    return (
                      <li class={styles.taskListHour}>
                        <section
                          class={[
                            styles.hourGroup,
                            {
                              [styles.first]: $($index, (i) => i === 0),
                              [styles.last]: $($completedGroups, $index, (g, i) => g.length - 1 === i),
                            },
                          ]}
                        >
                          <header>
                            <div class={styles.hourGroupIcon}>
                              <Time />
                            </div>
                            <h4>{$($group, (g) => g.label)}</h4>
                          </header>
                          <ul class={styles.taskList}>
                            {repeat(
                              $($group, (g) => g.items),
                              (t) => t.id,
                              ($task) => {
                                const $project = $($task, projects.$cache, (task, projects) =>
                                  projects.find((p) => p.id === task.projectId),
                                );

                                return (
                                  <TaskListItem
                                    $task={$task}
                                    $charms={$(<ProjectCharm project={$project} />)}
                                    $theme={theme.getThemeVariables$($($project, (p) => p?.color))}
                                  />
                                );
                              },
                            )}
                          </ul>
                        </section>
                      </li>
                    );
                  },
                )}
              </ul>,
            )}
          </CollapsibleListSection>
        </div>
      </CardContent>

      <CardToolbar>
        <ToolBar>
          <BackButton
            onClick={() => {
              props.onBackButtonClicked();
            }}
          >
            {translate("workspace.project.tasks.calendar.dayView.backButtonText")}
          </BackButton>
        </ToolBar>
      </CardToolbar>
    </div>
  );
}

function toDateString(date: Date): string {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  return `${y}-${m.toString().padStart(2, "0")}-${d.toString().padStart(2, "0")}`;
}

interface HourGroup<T> {
  hour: number;
  label: string;
  items: T[];
}

function groupByHour(items: Task[], locale: string): HourGroup<Task>[] {
  const groups = new Map<string, HourGroup<Task>>();

  const sortedItems = items.sort((a, b) => {
    if (a.completedAt! < b.completedAt!) {
      return -1;
    } else if (a.completedAt! > b.completedAt!) {
      return 1;
    } else {
      return 0;
    }
  });

  for (const item of sortedItems) {
    const date = new Date(item.completedAt!);

    const hourLabel = new Intl.DateTimeFormat([locale, "en-US"], {
      hour: "numeric",
    }).format(date);

    if (groups.has(hourLabel)) {
      const items = groups.get(hourLabel)!.items;
      items.push(item);
    } else {
      groups.set(hourLabel, {
        hour: getHours(date),
        label: hourLabel,
        items: [item],
      });
    }
  }

  return [...groups.values()];
}
