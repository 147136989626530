import { makeDebouncer } from "@helpers/makeDebouncer";
import { sortTasks } from "@helpers/sortTasks";
import {
  $,
  $$,
  LanguageStore,
  RouterStore,
  Writable,
  cond,
  repeat,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { Button } from "@views/Button";
import { CardContent, CardToolbar } from "@views/Card";
import { TagFilter } from "@views/TagFilter";
import { TaskListItem } from "@views/TaskListItem";
import { TextInput } from "@views/TextInput";
import { ToolBar } from "@views/ToolBar";
import styles from "./Active.module.css";
import { set } from "date-fns";

interface ActiveProps {
  $projectId: Readable<number>;
  $userRole: Readable<"viewer" | "collaborator" | "admin">;
  $projectColor: Readable<string | undefined>;
  $$filterTags: Writable<string[]>;
}

export function Active(props: ActiveProps, ctx: ViewContext) {
  ctx.name = "ProjectTasks/Active";

  const tasks = ctx.getStore(TasksStore);
  const projects = ctx.getStore(ProjectsStore);
  const router = ctx.getStore(RouterStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $$scrollElement = $$<HTMLElement>();
  const scrollDebouncer = makeDebouncer(100);

  function onScroll(e: Event) {
    // const el = $$scrollElement.get()!;
    // scrollDebouncer.queue(() => {
    //   thisCard.updateState({
    //     listScrollPosition: el.scrollTop,
    //   });
    // });
  }

  const $tags = $(props.$projectId, tasks.$cache, (projectId, tasks) => {
    const tags: { [tag: string]: number } = {};

    if (projectId != null) {
      for (const task of tasks.values()) {
        if (task.projectId == projectId && task.completedAt == null) {
          for (const tag of task.tags) {
            tags[tag] = (tags[tag] ?? 0) + 1;
          }
        }
      }
    }

    return tags;
  });

  ctx.onConnected(() => {
    const el = $$scrollElement.get()!;
    el.addEventListener("scroll", onScroll);

    ctx.observe(router.$$query, async (query) => {
      if (query.task) {
        await tasks.ensureTaskIsLoaded(Number(query.task));
        setTimeout(() => {
          scrollToTask(Number(query.task));
          tasks.$$highlightTaskId.set(Number(query.task));
        }, 50);
      }
    });
  });

  ctx.beforeDisconnect(() => {
    const el = $$scrollElement.get()!;
    el.removeEventListener("scroll", onScroll);
    scrollDebouncer.cancel();
    // thisCard.updateState({
    //   listScrollPosition: el.scrollTop,
    // });
  });

  const $$inputText = $$("");

  const $tasks = $(
    props.$projectId,
    tasks.$cache,
    $$inputText,
    props.$$filterTags,
    (projectId, tasks, inputText, tags) => {
      if (projectId == null) return [];

      const searchTerm = inputText.trim() === "" ? null : inputText.trim().toLowerCase();
      let results = [...tasks.values()].filter(
        (t) => t.projectId === projectId && !t.deletedAt && tags.every((tag) => t.tags.includes(tag)),
      );

      if (searchTerm) {
        results = results.filter((t) => t.title.toLowerCase().includes(searchTerm));
      }

      return results;
    },
  );

  const $canInteract = $(props.$userRole, (role) => role !== "viewer");

  /**
   * Gets the task that is being dragged. If a `list` argument is passed, that list will be searched for a task with a matching ID.
   */
  const getDraggedTask = (e: DragEvent, list = tasks.$cache.get()) => {
    const data = e.dataTransfer!.getData("data/task-id");
    if (data) {
      const taskId = Number(data);
      return list.get(taskId);
    }
  };

  const scrollToTask = (taskId: number) => {
    ctx.info("scrolling to task", taskId);

    const scrollEl = $$scrollElement.get()!;
    const taskEl = scrollEl.querySelector(`[data-task-id='${taskId}']`);

    ctx.info({ scrollEl, taskEl, tasks: scrollEl.querySelectorAll("[data-task-id]") });

    if (taskEl) {
      // Take measurements
      const scrollRect = scrollEl.getBoundingClientRect();
      const taskRect = taskEl?.getBoundingClientRect();

      // Calculate required movement to place task within viewport
      const boundsTop = scrollRect.top + 48;
      const boundsBottom = scrollRect.top + scrollRect.height - 8;

      // Perform movement
      if (taskRect.top < boundsTop) {
        // Scroll up
        ctx.log("MUST SCROLL UP BY", boundsTop - taskRect.top);
        scrollEl.scrollTo({
          top: scrollEl.scrollTop - (boundsTop - taskRect.top),
          behavior: "smooth",
        });
      } else if (taskRect.bottom > boundsBottom) {
        // Scroll down
        ctx.log("MUST SCROLL DOWN BY", taskRect.bottom - boundsBottom);
        scrollEl.scrollTo({
          top: scrollEl.scrollTop + (taskRect.bottom - boundsBottom),
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <div class={styles.container}>
      <CardContent ref={$$scrollElement}>
        <div class={styles.tagFilter}>
          <TagFilter
            $$tags={props.$$filterTags}
            $color={$(
              props.$projectId,
              projects.$cache,
              (id, projects) => projects.find((p) => p.id === id)!.color,
            )}
            $suggestions={$tags}
          />
        </div>

        <div class={styles.content}>
          {cond(
            $($tasks, (tasks) => tasks.filter((t) => t.completedAt == null).length === 0),
            <p class={styles.emptyListMessage}>
              {translate("workspace.project.tasks.active.emptyListMessage")}
            </p>,
          )}

          <ul class={styles.taskList}>
            {repeat(
              $($tasks, (tasks) => tasks.filter((t) => t.completedAt == null).sort(sortTasks)),
              (task) => task.id,
              ($task) => {
                return <TaskListItem $task={$task} scrollToTask={scrollToTask} />;
              },
            )}
          </ul>
        </div>
      </CardContent>

      <CardToolbar>
        <ToolBar>
          <form
            class={styles.searchOrCreate}
            onSubmit={(e) => {
              e.preventDefault();

              const projectId = props.$projectId.get();

              tasks
                .createTask({
                  projectId: projectId,
                  delta: [
                    { insert: $$inputText.get() },
                    { insert: "\n", attributes: { header: 1 } },
                    { insert: "\n" },
                  ],
                  tags: props.$$filterTags.get(),
                })
                .then((task) => {
                  $$inputText.set("");
                  setTimeout(() => {
                    scrollToTask(task.id);
                    tasks.$$highlightTaskId.set(task.id);
                  }, 20);
                });
            }}
          >
            <TextInput
              $$value={$$inputText}
              name="taskTitle"
              placeholder={translate("workspace.project.tasks.searchOrCreate.inputPlaceholder")}
            />
            <Button type="submit" disabled={$($$inputText, (text) => text.trim().length === 0)}>
              {translate("workspace.project.tasks.searchOrCreate.buttonText")}
            </Button>
          </form>
        </ToolBar>
      </CardToolbar>
    </div>
  );
}
