import { $, cond, type Readable, type ViewContext } from "@manyducks.co/dolla";
import styles from "./UserAvatar.module.css";

interface UserAvatarProps {
  src?: string | Readable<string> | Readable<string | undefined>;
  scale?: number;
}

export function UserAvatar(props: UserAvatarProps, ctx: ViewContext) {
  const $src = $(props.src);
  const $scale = $(props.scale ?? 1);
  const $size = $($scale, (scale) => 32 * scale);

  return (
    <div class={styles.container} style={{ width: $size, height: $size }}>
      {cond(
        $src,
        <img class={styles.avatar} width={$size} height={$size} src={$($src, (src) => src ?? "")} alt={""} />,
      )}
    </div>
  );
}
