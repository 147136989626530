import { $$, $, type InputType, type Readable, type ViewContext, type Writable } from "@manyducks.co/dolla";
import styles from "./NumericInput.module.css";

interface NumericInputProps {
  ref?: Writable<HTMLInputElement | undefined>;
  value?: number | Writable<number>;
  class?: any | Readable<any>;
  autofocus?: boolean;
  id?: string;
  type?: InputType;
  name?: string;
  placeholder?: string | Readable<string>;
  onchange?: (value: Event) => void;
  onkeydown?: (value: KeyboardEvent) => void;
}

export function NumericInput(props: NumericInputProps, ctx: ViewContext) {
  const $$value = $$(props.value ?? undefined);
  const $class = $(props.class);
  const $placeholder = $(props.placeholder);

  const { autofocus, onchange, onkeydown, id, type, name } = props;

  return (
    <input
      ref={props.ref}
      class={[styles.input, $class]}
      id={id}
      type="number"
      pattern="\d*"
      name={name}
      placeholder={$placeholder}
      autofocus={autofocus}
      value={$($$value, (n) => String(n))}
      onkeydown={(e) => {
        if (onkeydown) {
          onkeydown(e);
        }
      }}
      oninput={(e) => {
        const value = Number((e.currentTarget as HTMLInputElement).value);
        $$value.set(value);

        if (onchange) {
          onchange(e);
        }
      }}
    />
  );
}
