import { type StoreContext } from "@manyducks.co/dolla";

const labels = ["LOADING", "tawa", "読み込み中"];

/**
 * Manipulates the loading screen included in `index.html`.
 * That screen is displayed until scripts are active and content is ready to show.
 * This store lets the app control when it's shown or hidden.
 *
 * The CSS classes used here are defined in the `<style>` tag in the header of `index.html`.
 */
export function LoaderStore(ctx: StoreContext) {
  const render = ctx.getStore("render");
  const loader = document.getElementById("loader")!;

  if (!loader) {
    ctx.crash(new Error(`Loader element was not found on the page.`));
  }

  const label = loader.querySelector("#loaderLabel")!;

  if (!label) {
    ctx.crash(new Error(`Loader label element was not found on the page.`));
  }

  return {
    async hideAppLoader() {
      return new Promise<void>((resolve) => {
        if (loader.classList.contains("hidden")) {
          return resolve();
        }

        // Add 'animateOut' to trigger transition, then add 'hidden' when done.
        function done() {
          render.update(() => {
            loader.classList.add("hidden");
            loader.classList.remove("animateOut");
            resolve();
          });
          loader.removeEventListener("transitionend", done);
        }

        loader.addEventListener("transitionend", done);
        render.update(() => {
          loader.classList.remove("hidden");
          loader.classList.add("animateOut");
        });
      });
    },

    async showAppLoader() {
      return new Promise<void>((resolve) => {
        if (!loader.classList.contains("hidden")) {
          return resolve();
        }

        // Add 'animateIn' to trigger transition.
        function done() {
          render.update(() => {
            loader.classList.remove("animateIn");
            resolve();
          });
          loader.removeEventListener("transitionend", done);
        }

        const text = labels[Math.round(Math.random() * (labels.length - 1))];
        label.textContent = text;

        loader.addEventListener("transitionend", done);
        render.update(() => {
          loader.classList.remove("hidden");
          loader.classList.add("animateIn");
        });
      });
    },
  };
}
