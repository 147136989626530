export default {
  "workspace": "Workspace_workspace_3c9f5",
  "searchBarContainer": "Workspace_searchBarContainer_3c9f5",
  "searchBarIcon": "Workspace_searchBarIcon_3c9f5",
  "searchBarLabel": "Workspace_searchBarLabel_3c9f5",
  "searchBar": "Workspace_searchBar_3c9f5",
  "grabber": "Workspace_grabber_3c9f5",
  "layout": "Workspace_layout_3c9f5",
  "layoutContent": "Workspace_layoutContent_3c9f5",
  "main": "Workspace_main_3c9f5",
  "nav": "Workspace_nav_3c9f5",
  "navToolbar": "Workspace_navToolbar_3c9f5",
  "chat": "Workspace_chat_3c9f5",
  "infoBar": "Workspace_infoBar_3c9f5",
  "mobileNavBar": "Workspace_mobileNavBar_3c9f5",
  "mobileNavHighlight": "Workspace_mobileNavHighlight_3c9f5",
  "mobileNavButton": "Workspace_mobileNavButton_3c9f5",
  "mobileNavButtonIcon": "Workspace_mobileNavButtonIcon_3c9f5",
  "mobileNavButtonLabel": "Workspace_mobileNavButtonLabel_3c9f5",
  "noSelection": "Workspace_noSelection_3c9f5",
  "active": "Workspace_active_3c9f5"
};import "ni:sha-256;USKIm3txJggDc1PtCwG2LWlsinYGGgl9hxoTEy1ZoWU";