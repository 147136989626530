import { $, $$, LanguageStore, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { Project } from "schemas";
import { ThemeStore } from "@stores/ThemeStore";
import { Button } from "@views/Button";
import { ColorInput } from "@views/ColorInput";
import { TextInput } from "@views/TextInput";
import styles from "./EditProject.module.css";

interface EditProjectProps {
  project?: Project | Readable<Project>;
  saveLabel?: string | Readable<string>;
  onSave: (project: Pick<Project, "name" | "color">) => void;
  onCancel: () => void;
  autofocus?: boolean;
}

export function EditProject(props: EditProjectProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  const $project = $(props.project);
  const $$nameEditValue = $$($project.get()?.name ?? "");
  const $$colorEditValue = $$($project.get()?.color ?? "#ff0088");
  const $$textInput = $$<HTMLInputElement>();

  const $theme = ctx.getStore(ThemeStore).getThemeVariables$($$colorEditValue);

  const $saveLabel = $($(props.saveLabel), translate("common.save"), (label, fallback) => label ?? fallback);

  ctx.onConnected(() => {
    if (props.autofocus) {
      $$textInput.get()?.focus();
    }
  });

  return (
    <form
      class={styles.editForm}
      style={$theme}
      onSubmit={(e) => {
        e.preventDefault();
        props.onSave({
          name: $$nameEditValue.get(),
          color: $$colorEditValue.get(),
        });
      }}
    >
      <div class={styles.editFormGroup}>
        <ColorInput value={$$colorEditValue} />

        <TextInput
          value={$$nameEditValue}
          ref={$$textInput}
          placeholder={translate("workspace.nav.projects.createProjectDialog.nameInputPlaceholder")}
        />
      </div>

      <div class={[styles.editFormGroup, styles.justifyEnd]}>
        <Button
          type="button"
          onClick={() => {
            props.onCancel();
          }}
        >
          {translate("common.cancel")}
        </Button>
        <Button type="submit" disabled={$($$nameEditValue, (v) => v.trim().length === 0)}>
          {$saveLabel}
        </Button>
      </div>
    </form>
  );
}
