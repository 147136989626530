import { $, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { type makeThemeVariables } from "@helpers/makeTheme";
import { Point } from "@views/HoverMenu";
import styles from "./DocMetaMenu.module.css";

interface DocMetaMenuProps {
  $anchorPoint: Readable<Point>;
  $open: Readable<boolean>;
  $theme?: Readable<ReturnType<typeof makeThemeVariables>>;
  onClose: () => void;
}

export function DocMetaMenu(props: DocMetaMenuProps, ctx: ViewContext) {
  function close() {
    if (props.$open.get()) {
      props.onClose();
    }
  }

  return (
    <div
      class={[styles.layout, { [styles.open]: props.$open }]}
      style={$(props.$theme, props.$anchorPoint, (theme, { x }) => {
        return {
          ...theme,
          transformOrigin: `${x - 8}px -6px`,
        };
      })}
      onClick={close}
    >
      <div
        class={styles.notch}
        style={{
          transform: $(props.$anchorPoint, ({ x }) => `translateX(${x}px)`),
        }}
      >
        <div class={styles.shape} />
      </div>
      <div
        class={[styles.menu]}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div class={styles.content}>{ctx.outlet()}</div>
      </div>
    </div>
  );
}
