export default {
  "layout": "DayDetails_layout_69ef6",
  "content": "DayDetails_content_69ef6",
  "taskList": "DayDetails_taskList_69ef6",
  "emptyListMessage": "DayDetails_emptyListMessage_69ef6",
  "toolbar": "DayDetails_toolbar_69ef6",
  "searchOrCreate": "DayDetails_searchOrCreate_69ef6",
  "hourGroup": "DayDetails_hourGroup_69ef6",
  "first": "DayDetails_first_69ef6",
  "last": "DayDetails_last_69ef6",
  "hourGroupIcon": "DayDetails_hourGroupIcon_69ef6"
};import "ni:sha-256;FUT1dcBXKVqXrXuBrODj1Dzjyb1Menu9cOCdktNdEAs";