export default {
  "container": "TextEditor_container_7707b",
  "toolbarContainer": "TextEditor_toolbarContainer_7707b",
  "hidden": "TextEditor_hidden_7707b",
  "toolbar": "TextEditor_toolbar_7707b",
  "toolbarGroup": "TextEditor_toolbarGroup_7707b",
  "toolbarButton": "TextEditor_toolbarButton_7707b",
  "active": "TextEditor_active_7707b",
  "toolbarDivider": "TextEditor_toolbarDivider_7707b",
  "editor": "TextEditor_editor_7707b",
  "searchMenu": "TextEditor_searchMenu_7707b",
  "linkBlot": "TextEditor_linkBlot_7707b",
  "linkBlotIcon": "TextEditor_linkBlotIcon_7707b",
  "linkBlotTaskStage": "TextEditor_linkBlotTaskStage_7707b",
  "linkBlotTitle": "TextEditor_linkBlotTitle_7707b"
};import "ni:sha-256;I8qDPyKLmgT7QYEsf46JpWYrue6i0QugzBhJjY5bBw0";