import { sortTasks } from "@helpers/sortTasks";
import { $, LanguageStore, cond, repeat, type ViewContext } from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import { CardContent } from "@views/Card";
import { ProjectCharm } from "@views/Charms";
import { TaskListItem } from "@views/TaskListItem";
import styles from "./List.module.css";

interface ListProps {}

export function List(props: ListProps, ctx: ViewContext) {
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $tasksToDo = $(tasks.$myTasks, (tasks) => tasks.filter((t) => t.completedAt == null).sort(sortTasks));

  return (
    <div class={styles.layout}>
      <CardContent>
        <div class={styles.content}>
          <ul class={styles.list}>
            {cond(
              $($tasksToDo, (tasks) => tasks.length === 0),

              <li class={styles.emptyMessage}>{translate("workspace.me.tasks.todo.emptyListMessage")}</li>,

              repeat(
                $tasksToDo,
                (task) => task.id,
                ($task) => {
                  const $project = $(projects.$cache, $task, (projects, task) => {
                    return projects.find((p) => p.id === task.projectId)!;
                  });

                  return (
                    <TaskListItem
                      interactive={true}
                      $theme={theme.getThemeVariables$($($project, (p) => p.color))}
                      $charms={$(<ProjectCharm project={$project} />)}
                      $task={$task}
                    />
                  );
                },
              ),
            )}
          </ul>
        </div>
      </CardContent>
    </div>
  );
}
