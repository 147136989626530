import { $$, $, type StoreContext } from "@manyducks.co/dolla";

/**
 * Tracks the current time. Good for synchronizing time-sensitive events.
 */
export function ClockStore(ctx: StoreContext) {
  const $$second = $$<number>(0);
  const $$minute = $$<number>(0);
  const $$hour = $$<number>(0);

  function tick() {
    const now = new Date();
    const offset = 1000 - now.getMilliseconds();
    $$second.set(now.getSeconds());
    $$minute.set(now.getMinutes());
    $$hour.set(now.getHours());

    setTimeout(tick, offset);
  }

  ctx.onConnected(tick);

  return {
    $second: $($$second),
    $minute: $($$minute),
    $hour: $($$hour),
  };
}
