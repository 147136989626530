export default {
  "button": "IconButton_button_b09b8",
  "icon": "IconButton_icon_b09b8",
  "numberBadge": "IconButton_numberBadge_b09b8",
  "small": "IconButton_small_b09b8",
  "normal": "IconButton_normal_b09b8",
  "card": "IconButton_card_b09b8",
  "destructive": "IconButton_destructive_b09b8",
  "transparent": "IconButton_transparent_b09b8",
  "withLabel": "IconButton_withLabel_b09b8",
  "label": "IconButton_label_b09b8"
};import "ni:sha-256;qAEXWcjyvjIq3VglXrzsHxYxAfl_EpmseIwMFLTAji0";