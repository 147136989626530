import { $, $$, LanguageStore, type ViewContext } from "@manyducks.co/dolla";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import { SegmentButton } from "@views/SegmentButton";
// import { History } from "./History/History";
import { Calendar } from "./Calendar/Calendar";
import { List } from "./List/List";
import styles from "./MyTasks.module.css";

interface MyTasksProps {}

export function MyTasks(props: MyTasksProps, ctx: ViewContext) {
  const tasks = ctx.getStore(TasksStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);

  ctx.beforeConnect(() => {
    tasks.fetchToDos();
  });

  const $color = $(theme.$userTheme, (t) => t.accent[0]);
  const $taskCount = $(tasks.$myTasks, (tasks) => {
    let count = 0;
    for (let i = 0; i < tasks.length; i++) {
      if (tasks[i].completedAt == null) {
        count += 1;
      }
    }
    return count;
  });

  const $$selectedTab = $$<"todo" | "calendar">("todo");

  return (
    <div class={styles.layout}>
      <div class={styles.segmentButtons}>
        <SegmentButton
          value={$$selectedTab}
          onChange={$$selectedTab.set}
          segments={[
            {
              value: "todo",
              content: (
                <div class={styles.segment}>
                  {translate("workspace.me.tasks.todo.tabName")}
                  <span class={styles.segmentCountBadge}>({$taskCount})</span>
                </div>
              ),
            },
            {
              value: "calendar",
              content: (
                <div class={styles.segment}>{translate("workspace.project.tasks.calendar.tabName")}</div>
              ),
            },
          ]}
        />
      </div>

      <div class={styles.tabContent}>
        {$($$selectedTab, (tab) => {
          if (tab === "todo") {
            return <List />;
          }

          if (tab === "calendar") {
            return <Calendar />;
          }
        })}
      </div>
    </div>
  );
}
