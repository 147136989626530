import { type Writable, type ViewContext } from "@manyducks.co/dolla";
import styles from "./ToolBar.module.css";

interface ToolBarAttrs {
  ref?: Writable<HTMLElement> | Writable<HTMLElement | undefined>;
}

/**
 * A row for grouped controls that goes at the top of a ContentCard.
 */
export function ToolBar({ ref }: ToolBarAttrs, ctx: ViewContext) {
  return (
    <div ref={ref} class={styles.toolbar}>
      {ctx.outlet()}
    </div>
  );
}
