interface FilePromptOptions {
  accept: string;
  multiple?: boolean;
}

/**
 * Asks the user to pick a file. Returns a promise that resolves to an array of the chosen files.
 */
export async function promptForFiles(options?: FilePromptOptions): Promise<File[]> {
  return new Promise((resolve) => {
    const el = document.createElement("input");

    el.type = "file";
    el.accept = options?.accept || "*";

    if (options?.multiple) {
      el.multiple = true;
    }

    el.style.position = "absolute";
    el.style.opacity = "0";
    el.style.pointerEvents = "none";

    el.addEventListener("change", (e) => {
      const target = e.target as HTMLInputElement;
      const files: File[] = [];

      if (target.files) {
        for (let i = 0; i < target.files.length; i++) {
          files.push(target.files[i]);
        }
      }

      resolve(files);

      document.body.removeChild(el);
    });

    document.body.appendChild(el);
    setTimeout(() => {
      el.click();
    });
  });
}
