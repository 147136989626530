export default {
  "taskListItem": "TaskListItem_taskListItem_a129e",
  "itemDivider": "TaskListItem_itemDivider_a129e",
  "task": "TaskListItem_task_a129e",
  "focused": "TaskListItem_focused_a129e",
  "highlighted": "TaskListItem_highlighted_a129e",
  "highlightTask": "TaskListItem_highlightTask_a129e",
  "taskTitle": "TaskListItem_taskTitle_a129e",
  "charmsBar": "TaskListItem_charmsBar_a129e",
  "taskLayout": "TaskListItem_taskLayout_a129e",
  "taskContent": "TaskListItem_taskContent_a129e",
  "taskCheckbox": "TaskListItem_taskCheckbox_a129e",
  "taskDescription": "TaskListItem_taskDescription_a129e",
  "untitled": "TaskListItem_untitled_a129e",
  "taskTitleText": "TaskListItem_taskTitleText_a129e",
  "priorityBadge": "TaskListItem_priorityBadge_a129e",
  "taskTagList": "TaskListItem_taskTagList_a129e",
  "taskTag": "TaskListItem_taskTag_a129e",
  "taskQuickOptions": "TaskListItem_taskQuickOptions_a129e",
  "taskAssignedUser": "TaskListItem_taskAssignedUser_a129e",
  "interactive": "TaskListItem_interactive_a129e",
  "menuOpen": "TaskListItem_menuOpen_a129e",
  "taskDueDate": "TaskListItem_taskDueDate_a129e",
  "inPast": "TaskListItem_inPast_a129e",
  "taskAttachments": "TaskListItem_taskAttachments_a129e",
  "metaSection": "TaskListItem_metaSection_a129e",
  "timestampSection": "TaskListItem_timestampSection_a129e",
  "metaIcon": "TaskListItem_metaIcon_a129e",
  "taskStatus": "TaskListItem_taskStatus_a129e",
  "taskTimestamp": "TaskListItem_taskTimestamp_a129e",
  "assignMenu": "TaskListItem_assignMenu_a129e",
  "editButtonBar": "TaskListItem_editButtonBar_a129e",
  "taskMenu": "TaskListItem_taskMenu_a129e"
};import "ni:sha-256;T3TatjL0PpuwQI8cpB0JYvIdcK_Q_iFtZlEubtrK6ew";