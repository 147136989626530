import { relativePastTime } from "@helpers/relativePastTime";
import { sticky } from "@helpers/sticky";
import { Synchronizer } from "@helpers/Synchronizer";
import {
  $,
  $$,
  cond,
  DialogStore,
  HTTPStore,
  LanguageStore,
  Readable,
  repeat,
  RouterStore,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { IOStore } from "@stores/IOStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ConfirmDelete } from "@views/@dialogs";
import EditIcon from "@views/@icons/Edit";
import PlusIcon from "@views/@icons/Plus";
import TagIcon from "@views/@icons/Tag";
import TimeIcon from "@views/@icons/Time";
import TrashIcon from "@views/@icons/Trash";
import { BackButton } from "@views/BackButton";
import { CardToolbar } from "@views/Card";
import { Checkbox } from "@views/Checkbox";
import { DocMetaMenu } from "@views/DocMeta";
import { Point } from "@views/HoverMenu";
import { MoreMenu } from "@views/MoreMenu";
import { Switch } from "@views/Switch";
import { TagInput } from "@views/TagInput";
import { TextEditor } from "@views/TextEditor";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import { Project } from "schemas";
import { Awareness } from "y-protocols/awareness";
import * as Y from "yjs";
import styles from "./Details.module.css";
import { DuckLoader, makeLoaderState } from "@views/DuckLoader";
import Paperclip from "@views/@icons/Paperclip";
import { FileAttachment } from "@views/FileAttachment";
import { FilesStore } from "@stores/FilesStore";
import { InputFile } from "Workspace/Chat/ChatEditor/ChatEditor";
import { produce } from "immer";

type MetaSection = "attachments" | "tags" | "timestamps";

interface DetailsProps {
  $project: Readable<Project | undefined>;
  $noteId: Readable<number | null>;
}

export function Details(props: DetailsProps, ctx: ViewContext) {
  ctx.name = "ProjectNotes/Details";

  const auth = ctx.getStore(AuthStore);
  const router = ctx.getStore(RouterStore);
  const notes = ctx.getStore(NotesStore);
  const dialog = ctx.getStore(DialogStore);
  const projects = ctx.getStore(ProjectsStore);
  const io = ctx.getStore(IOStore);
  const http = ctx.getStore(HTTPStore);
  const { uploadFile } = ctx.getStore(FilesStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $currentNote = $(props.$noteId, notes.$cache, (id, notes) => (id ? notes.get(id) : null));
  const $latestNote = $($currentNote, sticky());

  const $projectId = $(props.$project, (n) => n?.id);
  const $tags = $($latestNote, (n) => (n ? [...n.tags].sort() : []));
  const $tagCount = $($tags, (t) => t.length);
  const $attachments = $($latestNote, (n) => n?.attachments ?? []);
  const $attachmentCount = $($attachments, (a) => a.length);

  const $$metaContainer = $$<HTMLElement>();
  const $$openMetaSection = $$<MetaSection>();
  const $$menuAnchor = $$<Point>({ x: 0, y: 0 });
  const $$fileUploads = $$<InputFile[]>([]);

  const $userRole = $($projectId, projects.$cache, auth.$me, (projectId, projectsCache, me) => {
    const project = projectsCache.find((p) => p.id === projectId);
    if (!project || !me) return null;
    const user = project.users.find((u) => u.id === me.id);
    return user?.role ?? null;
  });

  const $isViewer = $($userRole, (role) => role === "viewer");
  const $$inEditMode = $$(false);

  function setAnchor(e: Event) {
    const containerRect = $$metaContainer.get()!.getBoundingClientRect();
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();

    $$menuAnchor.set({
      x: rect.left + rect.width / 2 - containerRect.left,
      y: rect.top + rect.height / 2 - containerRect.top,
    });
  }

  function addFiles(files: FileList | File[]) {
    for (const file of Array.from(files)) {
      const events = uploadFile(file);
      let uploadId: string;

      events.on("started", (e) => {
        ctx.log("upload started", e);
        uploadId = e.data.uploadId;
        const inputFile: InputFile = {
          file,
          uploadId,
          progress: 0,
          complete: false,
          events,
        };
        $$fileUploads.update(
          produce((current) => {
            current.push(inputFile);
          }),
        );
      });
      events.on("progress", (e) => {
        ctx.log("upload progress", e);
        $$fileUploads.update(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.progress = e.data.percent;
            }
          }),
        );
      });
      events.on("error", (e) => {
        ctx.log("upload error", e);
        $$fileUploads.update(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.error = e.data.error;
            }
          }),
        );
      });
      events.on("complete", (e) => {
        ctx.log("upload complete", e);

        const note = $latestNote.get()!;
        http
          .post(`/api/notes/${note.id}/attachments`, { body: { uuids: [uploadId] } })
          .then((res) => {
            ctx.info("Attachment added", res);
            $$fileUploads.update(
              produce((current) => {
                return current.filter((f) => f.uploadId !== uploadId);
              }),
            );
          })
          .catch((err) => {
            ctx.error(err);
          });
      });
    }
  }

  const $$menuIsOpen = $$(false);

  const loader = makeLoaderState(false);

  // Stores the current connection info for the selected doc.
  const $$noteConnectionDetails = $$<{
    id: number;
    ydoc: Y.Doc;
    awareness: Awareness;
    sync: Synchronizer;
  }>();

  // Keep synchronizer edit mode setting up to date with card state
  ctx.observe($$noteConnectionDetails, $$inEditMode, $userRole, (details, inEditMode, role) => {
    if (details) {
      details.sync.setEditMode(role === "viewer" ? false : inEditMode);
    }
  });

  // Keep awareness user up to date with user settings
  ctx.observe($$noteConnectionDetails, auth.$me, (details, me) => {
    if (details && me) {
      details.awareness.setLocalStateField("user", {
        name: me.name,
        color: me.color,
      });
    }
  });

  // Update local doc metadata when the document changes.
  function updateLocalData() {
    const details = $$noteConnectionDetails.get();
    if (details) {
      notes.updatePage(details.id, details.ydoc.getText("content"));
    }
  }

  // Configure the document and socket connection before opening details view.
  async function prepareNote(id: number) {
    const { exists } = await notes.ensureNoteIsLoaded(id);

    ctx.log(`Note ${id} exists: ${exists}`);

    if (!exists) {
      ctx.error("Note not found");
      return router.navigate(`/projects/${$projectId.get()}/notes`);
    }

    const currentDetails = $$noteConnectionDetails.get();

    if (currentDetails) {
      if (currentDetails.id === id) {
        return; // We are already connected
      } else {
        // Clean up existing connection before opening a new one.
        currentDetails.ydoc.off("update", updateLocalData);
        currentDetails.sync.disconnect();
      }
    }

    const ydoc = new Y.Doc();
    const awareness = new Awareness(ydoc);
    const socket = io.socket(`/notes/${id}`);
    const sync = new Synchronizer(socket, ydoc, awareness);

    await sync.connect();

    ydoc.on("update", updateLocalData);

    $$noteConnectionDetails.set({ id, ydoc, awareness, sync });
  }

  ctx.observe(props.$noteId, $$noteConnectionDetails, async (noteId, details) => {
    if (noteId != details?.id) {
      await loader.show();
    }

    if (noteId != null) {
      await prepareNote(noteId);
    }

    await loader.hide();
  });

  return (
    <div class={styles.container}>
      {cond(
        $latestNote,
        <div class={styles.meta} ref={$$metaContainer}>
          {/* Pinned */}

          <div class={styles.metaCheckbox}>
            <Checkbox
              id={`pinned_checkbox_${ctx.uniqueId}`}
              $value={$($latestNote, (t) => t?.isPinned ?? false)}
              onChange={(value) => {
                const note = $latestNote.get()!;
                notes.setPinned(note.id, value);
              }}
              disabled={$isViewer}
            />
            <label class={styles.metaCheckboxLabel} for={`pinned_checkbox_${ctx.uniqueId}`}>
              {translate("workspace.project.notes.details.meta.pinnedLabel")}
            </label>
          </div>

          {/* Attachments */}

          <button
            class={[
              styles.metaButton,
              {
                [styles.active]: $attachmentCount,
                [styles.open]: $($$openMetaSection, (s) => s === "attachments"),
              },
            ]}
            title={$($attachments, (attachments) => {
              if (attachments.length > 0) {
                return translate("workspace.project.notes.details.meta.attachmentsLabel_value", {
                  value: attachments.length,
                });
              } else {
                return translate("workspace.project.notes.details.meta.attachmentsLabel_empty");
              }
            })}
            disabled={$isViewer}
            onClick={(e) => {
              setAnchor(e);
              $$openMetaSection.update((current) => (current === "attachments" ? undefined : "attachments"));
            }}
          >
            <div class={styles.metaButtonIcon}>
              <Paperclip />
            </div>
            {cond($attachmentCount, <span class={styles.metaButtonLabel}>{$attachmentCount}</span>)}
          </button>

          {/* Tags */}

          <button
            class={[
              styles.metaButton,
              {
                [styles.active]: $tagCount,
                [styles.open]: $($$openMetaSection, (s) => s === "tags"),
              },
            ]}
            title={$($tags, (tags) => {
              if (tags.length > 0) {
                return translate("workspace.project.notes.details.meta.tagsLabel_value", {
                  value: tags.join(", "),
                });
              } else {
                return translate("workspace.project.notes.details.meta.tagsLabel_empty");
              }
            })}
            disabled={$isViewer}
            onClick={(e) => {
              setAnchor(e);
              $$openMetaSection.update((current) => (current === "tags" ? undefined : "tags"));
            }}
          >
            <div class={styles.metaButtonIcon}>
              <TagIcon />
            </div>
            {cond($tagCount, <span class={styles.metaButtonLabel}>{$tagCount}</span>)}
          </button>

          {/* Timestamps */}

          <button
            class={[styles.metaButton, { [styles.open]: $($$openMetaSection, (s) => s === "timestamps") }]}
            title={translate("workspace.project.notes.details.meta.timestampsLabel")}
            onClick={(e) => {
              setAnchor(e);
              $$openMetaSection.update((current) => (current === "timestamps" ? undefined : "timestamps"));
            }}
          >
            <div class={styles.metaButtonIcon}>
              <TimeIcon />
            </div>
          </button>
        </div>,
      )}

      {/* Menu: Attachments */}

      {cond(
        $latestNote,
        <DocMetaMenu
          $anchorPoint={$$menuAnchor}
          $open={$($$openMetaSection, (s) => s === "attachments")}
          onClose={() => {
            $$openMetaSection.set(undefined);
          }}
        >
          <section class={[styles.metaSection, styles.metaAttachmentsSection]}>
            <label>{translate("workspace.project.notes.details.meta.attachmentsTitle")}</label>
            <ul class={styles.metaAttachmentsList}>
              {repeat(
                $attachments,
                (a) => a.id,
                ($a) => (
                  <li>
                    <FileAttachment
                      $attachment={$a}
                      onDelete={(file) => {
                        const note = $latestNote.get()!;
                        http.delete(`/api/notes/${note.id}/attachments/${file.id}`);
                      }}
                    />
                  </li>
                ),
              )}
            </ul>
            <div class={styles.metaAttachmentUpload}>
              <input
                id="attachmentUploadInput"
                class={styles.metaAttachmentUploadInput}
                type="file"
                multiple
                onChange={(e) => {
                  const target = e.currentTarget as HTMLInputElement;
                  const files = Array.from(target.files ?? []);

                  addFiles(files);
                }}
              />
              <label for="attachmentUploadInput" class={styles.metaAttachmentUploadLabel}>
                {translate("workspace.project.notes.details.meta.attachmentsUploadLabel")}
              </label>
            </div>
          </section>
        </DocMetaMenu>,
      )}

      {/* Menu: Tags */}

      {cond(
        $latestNote,
        <DocMetaMenu
          $anchorPoint={$$menuAnchor}
          $open={$($$openMetaSection, (s) => s === "tags")}
          onClose={() => {
            $$openMetaSection.set(undefined);
          }}
        >
          <section class={styles.metaSection}>
            <label>{translate("workspace.project.notes.details.meta.tagsTitle")}</label>
            <TagInput
              $value={$tags}
              onChange={({ tags }) => {
                const note = $latestNote.get()!;
                notes.setTags(note.id, tags);
              }}
            />
          </section>
        </DocMetaMenu>,
      )}

      {/* Menu: Timestamps */}

      {cond(
        $latestNote,
        <DocMetaMenu
          $anchorPoint={$$menuAnchor}
          $open={$($$openMetaSection, (s) => s === "timestamps")}
          onClose={() => {
            $$openMetaSection.set(undefined);
          }}
        >
          <section class={styles.metaSection}>
            <ul class={styles.timestampList}>
              <li class={styles.timestampListItem}>
                <div class={styles.timestampListItemMain}>
                  <PlusIcon />
                  <span>
                    {$($latestNote, $currentLanguage, (note, lang) => {
                      const timestamp = relativePastTime(lang!, note!.createdAt);
                      if (timestamp) {
                        return translate("relativeTime.createdAtTimestamp", { timestamp }).get();
                      } else {
                        return translate("relativeTime.createdNow").get();
                      }
                    })}
                  </span>
                </div>
                <span class={styles.timestampListItemSecondary}>
                  {$($latestNote, $currentLanguage, (note, lang) => {
                    return formatTimestamp(note!.createdAt, lang!);
                  })}
                </span>
              </li>

              <li class={[styles.timestampListItem]}>
                <div class={styles.timestampListItemMain}>
                  <EditIcon />
                  <span>
                    {$($latestNote, $currentLanguage, (note, lang) => {
                      const timestamp = relativePastTime(lang!, note!.updatedAt);
                      if (timestamp) {
                        return translate("relativeTime.updatedAtTimestamp", { timestamp }).get();
                      } else {
                        return translate("relativeTime.updatedNow").get();
                      }
                    })}
                  </span>
                </div>
                <span class={styles.timestampListItemSecondary}>
                  {$($latestNote, $currentLanguage, (note, lang) => {
                    return formatTimestamp(note!.updatedAt, lang!);
                  })}
                </span>
              </li>
            </ul>
          </section>
        </DocMetaMenu>,
      )}

      <div class={styles.content}>
        {$($$noteConnectionDetails, (details) => {
          if (details) {
            return (
              <TextEditor
                content={details.ydoc.getText("content")}
                accentColor={$(props.$project, (p) => p?.color ?? "#888")}
                awareness={details.awareness}
                readOnly={$($$inEditMode, (x) => !x)}
                scrollable
              />
            );
          }
        })}
      </div>

      <CardToolbar>
        <ToolBar>
          <ToolBarGroup>
            <BackButton
              onClick={() => {
                router.navigate(`/projects/${$projectId.get()}/notes`);
              }}
            >
              {translate("workspace.project.notes.details.toListButtonText")}
            </BackButton>
          </ToolBarGroup>

          <ToolBarGroup>
            {cond(
              $isViewer,
              null,
              <div class={styles.editToggleGroup}>
                <label for={ctx.uniqueId + "_editToggle"}>
                  {translate("workspace.project.notes.details.editableLabel")}
                </label>
                <Switch
                  id={ctx.uniqueId + "_editToggle"}
                  $value={$$inEditMode}
                  onChange={(newValue) => {
                    $$inEditMode.set(newValue);
                  }}
                />
              </div>,
            )}

            {cond(
              $($isViewer, $latestNote, (isViewer, note) => !isViewer && note != null),
              <MoreMenu
                $$open={$$menuIsOpen}
                preferVerticalAlignment="above"
                color={$(props.$project, (p) => p?.color)}
                options={[
                  {
                    label: translate("common.delete"),
                    variant: "destructive",
                    icon: <TrashIcon />,
                    callback: () => {
                      const note = $latestNote.get()!;

                      dialog.open(ConfirmDelete, {
                        message: translate("workspace.project.notes.deleteDialog.message"),
                        itemName: note.title,
                        onConfirm: function () {
                          notes
                            .deletePage(note.id)
                            .catch((err) => ctx.error(err))
                            .then(() => {
                              router.navigate(`/projects/${note.projectId}/notes`);
                            });
                        },
                      });
                    },
                  },
                ]}
              />,
            )}
          </ToolBarGroup>
        </ToolBar>
      </CardToolbar>

      <DuckLoader state={loader} />
    </div>
  );
}

function formatTimestamp(date: Date | string, lang: string) {
  return new Intl.DateTimeFormat([lang, "en-US"], { dateStyle: "medium", timeStyle: "short" }).format(
    new Date(date),
  );
}
