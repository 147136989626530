export default {
  "container": "PixelEditor_container_ad092",
  "editor": "PixelEditor_editor_ad092",
  "canvasContainer": "PixelEditor_canvasContainer_ad092",
  "canvas": "PixelEditor_canvas_ad092",
  "previewCanvas": "PixelEditor_previewCanvas_ad092",
  "dropperHover": "PixelEditor_dropperHover_ad092",
  "grid": "PixelEditor_grid_ad092",
  "gridX": "PixelEditor_gridX_ad092",
  "gridY": "PixelEditor_gridY_ad092",
  "gridLine": "PixelEditor_gridLine_ad092",
  "colors": "PixelEditor_colors_ad092",
  "colorInput": "PixelEditor_colorInput_ad092",
  "squareButton": "PixelEditor_squareButton_ad092",
  "on": "PixelEditor_on_ad092",
  "gridButton": "PixelEditor_gridButton_ad092",
  "colorHistory": "PixelEditor_colorHistory_ad092",
  "historicalColor": "PixelEditor_historicalColor_ad092",
  "historicalColorButton": "PixelEditor_historicalColorButton_ad092"
};import "ni:sha-256;ed1sBXTbFpEMRHmGLVInzr_iyMuZy2NJIBKCTnmsR_w";