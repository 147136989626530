import { $, $$, LanguageStore, repeat, type ViewContext } from "@manyducks.co/dolla";
import { IOStore } from "@stores/IOStore";
import { CardContent } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import prettyBytes from "pretty-bytes";
import { type QuackStats } from "schemas";
import { BreakdownBar } from "./BreakdownBar/BreakdownBar";
import styles from "./ServerStats.module.css";

interface ServerStatsProps {}

export function ServerStats(props: ServerStatsProps, ctx: ViewContext) {
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const io = ctx.getStore(IOStore);

  const $$stats = $$<QuackStats>();

  const socket = io.socket();

  function statsUpdateReceived(stats: QuackStats) {
    ctx.log(stats);
    $$stats.set(stats);
  }

  function onSocketConnected() {
    socket.emit("quackStats:join", (result: any) => {
      ctx.log("join", result);
    });
  }

  ctx.onConnected(() => {
    ctx.log("connected", socket.connected);
    if (socket.connected) {
      onSocketConnected();
    }

    socket.on("connect", onSocketConnected);
    socket.on("quackStats:update", statsUpdateReceived);
  });

  ctx.onDisconnected(() => {
    ctx.log("disconnected");
    socket.emit("quackStats:leave", () => {
      ctx.log("leave");
    });

    socket.off("quackStats:update", statsUpdateReceived);
    socket.off("connect", onSocketConnected);
  });

  return (
    <div class={styles.layout}>
      <CardContent>
        <div class={styles.content}>
          <CollapsibleListSection title={translate("workspace.admin.serverStats.cpu.title")}>
            <div class={styles.sectionContent}>
              <div class={styles.cpuMeters}>
                <div class={[styles.cpuMeterBox, styles.oneQuarter]}>
                  <div class={styles.cpuMeterGroup}>
                    <span class={styles.cpuMeterValue}>{$($$stats, (s) => s?.system.cpu.count)}</span>
                    <span class={styles.cpuMeterLabel}>
                      {$($$stats, (s) => (s?.system.cpu.count === 1 ? "Core" : "Cores"))}
                    </span>
                  </div>
                </div>
                <div class={[styles.cpuMeterBox, styles.threeQuarters]}>
                  <div class={styles.cpuMeterGroup}>
                    <span class={styles.cpuMeterValue}>
                      {$($$stats, (s) => s?.system.cpu.loadAverage[0].toFixed(2))}
                    </span>
                    <span class={styles.cpuMeterLabel}>1m</span>
                  </div>
                  <div class={styles.cpuMeterGroup}>
                    <span class={styles.cpuMeterValue}>
                      {$($$stats, (s) => s?.system.cpu.loadAverage[1].toFixed(2))}
                    </span>
                    <span class={styles.cpuMeterLabel}>5m</span>
                  </div>
                  <div class={styles.cpuMeterGroup}>
                    <span class={styles.cpuMeterValue}>
                      {$($$stats, (s) => s?.system.cpu.loadAverage[2].toFixed(2))}
                    </span>
                    <span class={styles.cpuMeterLabel}>15m</span>
                  </div>
                </div>
              </div>
            </div>
          </CollapsibleListSection>

          <CollapsibleListSection title={translate("workspace.admin.serverStats.memory.title")}>
            <div class={styles.sectionContent}>
              <ul class={styles.meterList}>
                <li>
                  <div class={styles.meterMeta}>
                    <span class={styles.meterTitle}>RAM</span>
                    <span class={styles.meterValue}>
                      {$($$stats, (s) => {
                        if (!s) return "?? / ?? (??%)";
                        const { total, free } = s.system.memory;
                        const percent = ((total - free) / total) * 100;

                        return `${prettyBytes(total - free)} / ${prettyBytes(total)} (${percent.toFixed(2)}%)`;
                      })}
                    </span>
                  </div>
                  <div class={styles.meter}>
                    <div
                      class={styles.fill}
                      style={{
                        width: $($$stats, (s) =>
                          s
                            ? ((s.system.memory.total - s.system.memory.free) / s.system.memory.total) * 100 +
                              "%"
                            : "0%",
                        ),
                      }}
                    />
                  </div>
                </li>
                <li>
                  <div class={styles.meterMeta}>
                    <span class={styles.meterTitle}>Swap</span>
                    <span class={styles.meterValue}>
                      {$($$stats, (s) => {
                        if (!s) return "?? / ?? (??%)";
                        const { total, free } = s.system.swap;
                        const percent = ((total - free) / total) * 100;

                        return `${prettyBytes(total - free)} / ${prettyBytes(total)} (${percent.toFixed(2)}%)`;
                      })}
                    </span>
                  </div>
                  <div class={styles.meter}>
                    <div
                      class={styles.fill}
                      style={{
                        width: $($$stats, (s) =>
                          s
                            ? ((s.system.swap.total - s.system.swap.free) / s.system.swap.total) * 100 + "%"
                            : "0%",
                        ),
                      }}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </CollapsibleListSection>

          <CollapsibleListSection title={translate("workspace.admin.serverStats.storage.title")}>
            <ul class={styles.sectionList}>
              <li>
                <div class={styles.sectionRow}>
                  <label class={styles.sectionLabel}>
                    {translate("workspace.admin.serverStats.storage.databaseSizeLabel")}
                  </label>
                  <span class={styles.sectionValue}>
                    {$($$stats, (s) => prettyBytes(s?.system.database.size ?? 0))}
                  </span>
                </div>
              </li>
              <li>
                <header class={styles.sectionHeader}>
                  {translate("workspace.admin.serverStats.storage.disksLabel")}
                </header>
                <ul class={styles.meterList}>
                  {repeat(
                    $($$stats, (s) => s?.system.disks ?? []),
                    (disk) => disk.mount,
                    ($disk) => {
                      return (
                        <li>
                          <div class={styles.meterMeta}>
                            <span class={styles.meterTitle}>{$($disk, (d) => d.mount)}</span>
                            <span class={styles.meterValue}>
                              {$(
                                $disk,
                                (d) =>
                                  `${prettyBytes(d.total - d.free)} / ${prettyBytes(d.total)} (${d.usedPercent}%)`,
                              )}
                            </span>
                          </div>
                          <div class={styles.meter}>
                            <div
                              class={styles.fill}
                              style={{ width: $($disk, (d) => ((d.total - d.free) / d.total) * 100 + "%") }}
                            />
                          </div>
                        </li>
                      );
                    },
                  )}
                </ul>
              </li>
            </ul>
          </CollapsibleListSection>
        </div>
      </CardContent>
    </div>
  );
}
