export default {
  "container": "Card_container_beaab",
  "outOfViewport": "Card_outOfViewport_beaab",
  "lifted": "Card_lifted_beaab",
  "card": "Card_card_beaab",
  "scope": "Card_scope_beaab",
  "scopeIcon": "Card_scopeIcon_beaab",
  "scopeLabel": "Card_scopeLabel_beaab",
  "scopeRole": "Card_scopeRole_beaab",
  "roleIcon": "Card_roleIcon_beaab",
  "roleLabel": "Card_roleLabel_beaab",
  "header": "Card_header_beaab",
  "contacted": "Card_contacted_beaab",
  "headerContent": "Card_headerContent_beaab",
  "headerGroup": "Card_headerGroup_beaab",
  "titleGroup": "Card_titleGroup_beaab",
  "icon": "Card_icon_beaab",
  "title": "Card_title_beaab",
  "mainTitle": "Card_mainTitle_beaab",
  "subtitle": "Card_subtitle_beaab",
  "showSubtitle": "Card_showSubtitle_beaab",
  "outlet": "Card_outlet_beaab",
  "menuPixelIcon": "Card_menuPixelIcon_beaab",
  "cardContent": "Card_cardContent_beaab",
  "cardToolbar": "Card_cardToolbar_beaab",
  "cardWidget": "Card_cardWidget_beaab"
};import "ni:sha-256;jgSZTy8cAzaDVLIDbNFlBdD5tXqTKwiteGFEjCAhKYI";