import {
  $$,
  LanguageStore,
  unwrap,
  type DialogProps,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import styles from "./ConfirmDeclineDialog.module.css";

interface ConfirmDeclineDialogProps extends DialogProps {
  accentColor: string | Readable<string>;

  /**
   * Called when confirmation is successful
   */
  onConfirm: () => void;
}

/**
 * A dialog to confirm permanent deletion of some data.
 */
export function ConfirmDeclineDialog(props: ConfirmDeclineDialogProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  const $$open = $$(props.$$open);
  const onConfirm = unwrap(props.onConfirm);

  return (
    <Dialog
      accentColor={props.accentColor}
      onsubmit={() => {
        onConfirm();
        $$open.set(false);
      }}
      transitionIn={props.transitionIn}
      transitionOut={props.transitionOut}
    >
      <DialogHeader $$open={$$open} title={translate("workspace.nav.projectInvites.declineDialogTitle")} />

      <DialogContent>
        <div class={styles.content}>
          <p class={styles.instructions}>{translate("workspace.nav.projectInvites.declineDialogMessage")}</p>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button type="submit">{translate("workspace.nav.projectInvites.declineButtonText")}</Button>
      </DialogFooter>
    </Dialog>
  );
}
