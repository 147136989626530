export default {
  "titleAndDescription": "TaskEditDialog_titleAndDescription_d5f21",
  "titleContainer": "TaskEditDialog_titleContainer_d5f21",
  "titleInput": "TaskEditDialog_titleInput_d5f21",
  "titlePlaceholder": "TaskEditDialog_titlePlaceholder_d5f21",
  "descriptionContainer": "TaskEditDialog_descriptionContainer_d5f21",
  "descriptionInput": "TaskEditDialog_descriptionInput_d5f21",
  "descriptionPlaceholder": "TaskEditDialog_descriptionPlaceholder_d5f21",
  "formGroup": "TaskEditDialog_formGroup_d5f21",
  "visible": "TaskEditDialog_visible_d5f21",
  "formGroupContent": "TaskEditDialog_formGroupContent_d5f21",
  "attachmentsList": "TaskEditDialog_attachmentsList_d5f21",
  "clearButton": "TaskEditDialog_clearButton_d5f21",
  "metaPriorityItem": "TaskEditDialog_metaPriorityItem_d5f21",
  "metaButtons": "TaskEditDialog_metaButtons_d5f21",
  "metaButton": "TaskEditDialog_metaButton_d5f21",
  "hidden": "TaskEditDialog_hidden_d5f21",
  "metaButtonIcon": "TaskEditDialog_metaButtonIcon_d5f21",
  "favoriteButton": "TaskEditDialog_favoriteButton_d5f21",
  "active": "TaskEditDialog_active_d5f21"
};import "ni:sha-256;XSuHU4tcEpyajU4C2MA1iCBNhb68HFilHMRQFSojvj4";