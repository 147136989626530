import { $$, LanguageStore, RouterStore, cond, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { LoaderStore } from "@stores/LoaderStore";
import { ThemeStore } from "@stores/ThemeStore";
import { TextInput } from "@views/TextInput";
import styles from "./Login.module.css";

export function Login(_: {}, ctx: ViewContext) {
  const $$email = $$("");
  const $$password = $$("");
  const $$error = $$<string>();

  const auth = ctx.getStore(AuthStore);
  const router = ctx.getStore(RouterStore);
  const loader = ctx.getStore(LoaderStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();

    $$error.set(undefined);

    const email = $$email.get();
    const password = $$password.get();

    auth
      .logIn(email, password)
      .then(() => loader.showAppLoader())
      .then(() => auth.getUsers())
      .then(() => {
        router.navigate("/");
      })
      .catch((err) => {
        $$error.set(err.message);
        ctx.error(err);
      });
  }

  ctx.onConnected(() => {
    loader.hideAppLoader();

    if (auth.$isLoggedIn.get()) {
      router.navigate("/");
    }
  });

  return (
    <div class={styles.layout}>
      <div class={styles.container} style={theme.$cssVars}>
        {cond($$error, <span class={styles.errorMessage}>{$$error}</span>)}

        <form class={styles.form} onsubmit={onSubmit}>
          <TextInput autofocus $$value={$$email} placeholder={translate("login.emailPlaceholder")} />
          <TextInput
            type="password"
            $$value={$$password}
            placeholder={translate("login.passwordPlaceholder")}
          />

          <button class={styles.button}>{translate("login.buttonPlaceholder")}</button>
        </form>
      </div>
    </div>
  );
}
