import { $, $$, cond, LanguageStore, Readable, repeat, ViewContext, Writable } from "@manyducks.co/dolla";
import styles from "./CalendarView.module.css";
import { Project, Task } from "schemas";
import { isSunday, startOfMonth, isSameDay, subDays, addDays } from "date-fns";
import { TasksStore } from "@stores/TasksStore";
import { parseDueDate } from "../parseDueDate";
import Check from "@views/@icons/Check";
import NoteIcon from "@views/@icons/Note";
import Calendar from "@views/@icons/Calendar";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";

interface CalendarViewProps {
  $year: Readable<number>;
  $month: Readable<number>;
  $selectedDate: Readable<Date | undefined>;
  onDaySelected: (date: Date) => void;
}

export function CalendarView(props: CalendarViewProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const tasks = ctx.getStore(TasksStore);
  const projects = ctx.getStore(ProjectsStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $days = $(
    auth.$me,
    props.$year,
    props.$month,
    props.$selectedDate,
    tasks.$cache,
    (me, year, month, selectedDate, tasks) => {
      return createDays(
        year,
        month,
        selectedDate,
        [...tasks.values()].filter((t) => t.assignedUserId === me?.id),
      );
    },
  );

  const $$containerElement = $$<HTMLButtonElement>();

  // Calculate how much vertical space in pixels is available for day items.
  // If not enough is available for the number of items, we display a "+X more" placeholder.
  const $$dayItemsHeight = $$(0);

  function onResize() {
    const headerHeight = 24;
    const dayHeight = $$containerElement.get()!.clientHeight / 6 - 2;
    $$dayItemsHeight.set(dayHeight - headerHeight);
  }

  ctx.onConnected(() => {
    window.addEventListener("resize", onResize);
    onResize();
  });

  ctx.onDisconnected(() => {
    window.removeEventListener("resize", onResize);
  });

  return (
    <section class={styles.calendar}>
      <header class={styles.header}>
        <ul>
          {repeat(
            $($days, (d) => d.slice(0, 7)),
            (d) => d.date.getDay(),
            ($day) => (
              <li>
                {$($day, $currentLanguage, (day, lang) => {
                  const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
                    weekday: "short",
                  });
                  return formatter.format(day.date);
                })}
              </li>
            ),
          )}
        </ul>
      </header>
      <ul class={styles.days} ref={$$containerElement}>
        {repeat(
          $days,
          (d) => d.date.toISOString(),
          ($day, $index, ctx) => {
            const $activeItems = $($day, (x) => x.items.filter((t) => t.completedAt == null));
            const $completedItems = $($day, (x) => x.items.filter((t) => t.completedAt != null));

            const $activeItemCount = $($activeItems, (x) => x.length);
            const $completedItemCount = $($completedItems, (x) => x.length);

            const $remainingHeight = $(
              $$dayItemsHeight,
              $activeItemCount,
              $completedItemCount,
              (availableHeight, activeItemCount, completedItemCount) => {
                let remainingHeight = availableHeight;
                if (activeItemCount > 0) {
                  remainingHeight -= 16; // Due header
                }
                if (completedItemCount > 0) {
                  remainingHeight -= 16; // Done header
                }
                return remainingHeight;
              },
            );

            const $visibleActiveItems = $($remainingHeight, $activeItems, (remainingHeight, activeItems) => {
              let availableHeight = remainingHeight;
              let availableItems = [...activeItems];
              const items: Task[] = [];

              while (availableHeight > 18 && availableItems.length > 0) {
                if (availableItems.length === 1 || availableHeight > 34) {
                  items.push(availableItems.shift()!);
                  availableHeight -= 18;
                } else {
                  break;
                }
              }

              return items;
            });
            const $hiddenActiveItemCount = $(
              $activeItems,
              $visibleActiveItems,
              (items, visibleItems) => items.length - visibleItems.length,
            );
            const $visibleCompletedItems = $(
              $remainingHeight,
              $activeItems,
              $completedItems,
              (remainingHeight, activeItems, completedItems) => {
                let availableHeight = remainingHeight - 18 * activeItems.length;
                let availableItems = [...completedItems];
                const items: Task[] = [];

                while (availableHeight > 18 && availableItems.length > 0) {
                  if (availableItems.length === 1 || availableHeight > 34) {
                    items.push(availableItems.shift()!);
                    availableHeight -= 18;
                  } else {
                    break;
                  }
                }

                return items;
              },
            );
            const $hiddenCompletedItemCount = $(
              $completedItems,
              $visibleCompletedItems,
              (items, visibleItems) => items.length - visibleItems.length,
            );

            return (
              <li
                class={[
                  styles.day,
                  {
                    [styles.notInMonth]: $($day, (d) => !d.inCurrentMonth),
                    [styles.isToday]: $($day, (d) => d.isToday),
                    [styles.isSelected]: $($day, (d) => d.isSelected),
                  },
                ]}
              >
                <button
                  class={styles.dayButton}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onDaySelected($day.get().date);
                  }}
                >
                  <div class={styles.dayHeader}>
                    {cond(
                      $($day, (d) => d.isStartOfMonth),
                      <span class={styles.dayMonthLabel}>
                        {$($day, $currentLanguage, (day, lang) => {
                          const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
                            month: "short",
                          });
                          return formatter.format(day.date);
                        })}
                      </span>,
                    )}
                    <span class={styles.dayDateLabel}>
                      {$($day, $currentLanguage, (day, lang) => {
                        const formatter = new Intl.DateTimeFormat([lang!, "en-US"], {
                          day: "numeric",
                        });
                        return formatter.format(day.date);
                      })}
                    </span>
                  </div>

                  {cond(
                    $($activeItemCount, (x) => x > 0),
                    <section class={styles.daySection}>
                      <header class={{ [styles.empty]: $($activeItemCount, (x) => x === 0) }}>
                        <Calendar />
                        <span>
                          {translate("workspace.project.tasks.calendar.monthView.dueHeader_value", {
                            value: $activeItemCount,
                          })}
                        </span>
                      </header>
                      <ul class={styles.dayItems}>
                        {repeat(
                          $visibleActiveItems,
                          (item) => item.id,
                          ($item) => {
                            const $project = $($item, projects.$cache, (task, projects) =>
                              projects.find((p) => p.id === task.projectId),
                            );
                            return (
                              <li
                                class={styles.dayItem}
                                style={theme.getThemeVariables$($($project, (p) => p?.color))}
                              >
                                <span>{$($item, (x) => x.title)}</span>
                              </li>
                            );
                          },
                        )}
                        {cond(
                          $($hiddenActiveItemCount, $activeItems, (hiddenCount, items) => {
                            return items.length > 0 && hiddenCount > 0 && hiddenCount < items.length;
                          }),
                          <li class={styles.hiddenMessage}>
                            {translate("workspace.project.tasks.calendar.monthView.overflowLabel_value", {
                              value: $hiddenActiveItemCount,
                            })}
                          </li>,
                        )}
                      </ul>
                    </section>,
                  )}

                  {cond(
                    $($completedItemCount, (x) => x > 0),
                    <section class={styles.daySection}>
                      <header class={{ [styles.empty]: $($completedItemCount, (x) => x === 0) }}>
                        <Check />
                        <span>
                          {translate("workspace.project.tasks.calendar.monthView.doneHeader_value", {
                            value: $completedItemCount,
                          })}
                        </span>
                      </header>
                      <ul class={styles.dayItems}>
                        {repeat(
                          $visibleCompletedItems,
                          (item) => item.id,
                          ($item) => {
                            const $project = $($item, projects.$cache, (task, projects) =>
                              projects.find((p) => p.id === task.projectId),
                            );
                            return (
                              <li
                                class={[styles.dayItem, styles.completed]}
                                style={theme.getThemeVariables$($($project, (p) => p?.color))}
                              >
                                <span>{$($item, (x) => x.title)}</span>
                              </li>
                            );
                          },
                        )}
                      </ul>
                      {cond(
                        $($hiddenCompletedItemCount, $completedItems, (hiddenCount, items) => {
                          return items.length > 0 && hiddenCount > 0 && hiddenCount < items.length;
                        }),
                        <li class={styles.hiddenMessage}>
                          {translate("workspace.project.tasks.calendar.monthView.overflowLabel_value", {
                            value: $hiddenCompletedItemCount,
                          })}
                        </li>,
                      )}
                    </section>,
                  )}
                </button>
              </li>
            );
          },
        )}
      </ul>
    </section>
  );
}

interface DayData {
  inCurrentMonth: boolean;
  isStartOfMonth: boolean;
  isSelected: boolean;
  isToday: boolean;
  date: Date;
  items: Task[];
}

function createDays(year: number, month: number, selectedDate: Date | undefined, items: Task[]): DayData[] {
  const days: DayData[] = [];

  // Find first Sunday to start off the grid.
  let firstDay = new Date(year, month - 1, 1);
  while (!isSunday(firstDay)) {
    firstDay = subDays(firstDay, 1);
  }

  const now = new Date();

  let cursor = firstDay;
  while (days.length < 42) {
    const inCurrentMonth = cursor.getFullYear() === year && cursor.getMonth() + 1 === month;
    const data: DayData = {
      inCurrentMonth,
      isStartOfMonth: cursor.getDate() === 1,
      isSelected: selectedDate != null && isSameDay(selectedDate, cursor),
      isToday: isSameDay(now, cursor),
      date: cursor,
      items: items.filter(
        (x) =>
          (x.dueDate != null && isSameDay(parseDueDate(x.dueDate), cursor)) ||
          (x.completedAt != null && isSameDay(x.completedAt, cursor)),
      ),
    };
    days.push(data);
    cursor = addDays(cursor, 1);
  }

  return days;
}
