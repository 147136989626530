import { $, cond, repeat, LanguageStore, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { isSameMinute } from "date-fns";
import { ClockStore } from "@stores/ClockStore";
import { type ChatMessage } from "schemas";
import { AuthStore } from "@stores/AuthStore";
import { ThemeStore } from "@stores/ThemeStore";
import { UserAvatar } from "@views/UserAvatar";
import { ChatMessageContent } from "../ChatMessageContent/ChatMessageContent";
import { formatTimestamp } from "../formatTimestamp";
import styles from "./ChatMessageList.module.css";
import { ChatMessageAttachments } from "../ChatMessageAttachments/ChatMessageAttachments";

interface ChatMessageListProps {
  $messages: Readable<ChatMessage[]>;
  onMessageAdded?: () => void;
}

export function ChatMessageList(props: ChatMessageListProps, ctx: ViewContext) {
  const $messages = $(props.$messages, computeMessageMeta);

  return (
    <ul class={styles.messageList}>
      {repeat(
        $messages,
        (meta) => meta.message.id,
        ($meta) => {
          return <ChatMessage $meta={$meta} onMessageAdded={props.onMessageAdded} />;
        },
      )}
    </ul>
  );
}

interface ChatMessageProps {
  $meta: Readable<MessageMetadata>;
  onMessageAdded?: () => void;
}

function ChatMessage(props: ChatMessageProps, ctx: ViewContext) {
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const clock = ctx.getStore(ClockStore);
  const auth = ctx.getStore(AuthStore);
  const theme = ctx.getStore(ThemeStore);

  const $message = $(props.$meta, (m) => m.message);
  const $showAvatar = $(props.$meta, (m) => m.showAvatar);
  const $showTimestamp = $(props.$meta, (m) => m.showTimestamp);

  const $showMessageInfo = $(
    $showAvatar,
    $showTimestamp,
    (showAvatar, showTimestamp) => showAvatar || showTimestamp,
  );

  const $attachments = $($message, (m) => m.attachments);
  const $hasText = $($message, (m) => m.delta.length > 0);
  const $timestamp = $($currentLanguage, $message, clock.$hour, (lang, message) => {
    const { key, timestamp } = formatTimestamp(lang!, message.createdAt);
    return translate(`workspace.chat.${key}`, { timestamp }).get();
  });

  const $user = $(auth.$users, $message, (users, message) => users.find((u) => u.id === message.userId));
  const $theme = theme.getThemeVariables$($($user, (u) => u?.color));
  const $isMine = $(auth.$me, $message, (me, message) => message.userId === me?.id);

  ctx.onConnected(() => {
    props.onMessageAdded?.();
  });

  return (
    <li class={[styles.message, { [styles.mine]: $isMine }]} style={$theme}>
      {cond(
        $showAvatar,
        <div class={styles.authorAvatar}>
          <UserAvatar src={$($user, (u) => u?.avatar ?? "")} />
        </div>,
        <div class={styles.authorAvatarSpacer} />,
      )}

      <div class={styles.messageContent}>
        <div class={styles.messageBubble}>
          {cond(
            $($attachments, (a) => a.length > 0),
            <ChatMessageAttachments $attachments={$attachments} />,
          )}

          {cond(
            $hasText,
            <ChatMessageContent delta={$($message, (m) => m.delta)} />,
            <div class={styles.noTextSpacer} />,
          )}
        </div>

        {cond(
          $showMessageInfo,
          <div class={styles.messageInfo}>
            {cond(
              $showAvatar,
              <span class={styles.authorName}>
                {translate("workspace.chat.authorNameLabel", { authorName: $($user, (u) => u?.name) })}
              </span>,
            )}

            {cond(
              $showTimestamp,
              <time
                datetime={$($message, (m) => new Date(m.createdAt).toISOString())}
                class={styles.messageTimestamp}
              >
                {$timestamp}
              </time>,
            )}
          </div>,
        )}
      </div>
    </li>
  );
}

interface MessageMetadata {
  message: ChatMessage;
  showTimestamp: boolean;
  showAvatar: boolean;
}

function computeMessageMeta(messages: ChatMessage[]): MessageMetadata[] {
  const wrapped: MessageMetadata[] = [];

  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const nextMessage = messages[i + 1];
    const isLastInGroup = nextMessage == null || message.userId !== nextMessage.userId;

    wrapped.push({
      message,
      showTimestamp: isLastInGroup || !isSameMinute(message.createdAt, nextMessage.createdAt),
      showAvatar: isLastInGroup,
    });
  }

  return wrapped;
}
