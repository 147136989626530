import { type ViewContext } from "@manyducks.co/dolla";
import { List } from "./List/List";
import styles from "./ProjectTasks.module.css";

export interface ProjectTasksProps {}

export function ProjectTasks(props: ProjectTasksProps, ctx: ViewContext) {
  ctx.name = "ProjectTasks";

  return (
    <div class={styles.layout}>
      <List />
    </div>
  );
}
