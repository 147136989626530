import { type ViewContext, type Readable } from "@manyducks.co/dolla";
import styles from "./Charm.module.css";
import { Renderable } from "@manyducks.co/dolla/lib/types";

interface CharmProps {
  icon: Renderable;
  label: Renderable | Readable<Renderable>;
}

export function Charm(props: CharmProps, ctx: ViewContext) {
  return (
    <div class={styles.charm}>
      <div class={styles.charmIcon}>{props.icon}</div>
      <span>{props.label}</span>
    </div>
  );
}
