import { $, cond, LanguageStore, RouterStore, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ThemeStore } from "@stores/ThemeStore";
import FolderIcon from "@views/@icons/Folder";
import FolderOpenIcon from "@views/@icons/FolderOpen";
import ProjectAdmin from "@views/@icons/ProjectAdmin";
import ProjectCollaborator from "@views/@icons/ProjectCollaborator";
import ProjectOwner from "@views/@icons/ProjectOwner";
import ProjectViewer from "@views/@icons/ProjectViewer";
import { type Project } from "schemas";
import styles from "./ProjectListItem.module.css";

interface ProjectListItemProps {
  $project: Readable<Project>;
  onClick?: () => void;
}

export function ProjectListItem(props: ProjectListItemProps, ctx: ViewContext) {
  ctx.name = "ProjectListItem";

  const router = ctx.getStore(RouterStore);
  const auth = ctx.getStore(AuthStore);
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);
  const $color = $(props.$project, (p) => p.color);
  const $project = $(props.$project);
  const $projectId = $($project, (p) => p.id);

  const $role = $(auth.$me, $project, (me, project) => {
    if (project.ownerId === me!.id) {
      return "owner";
    } else {
      return project.users.find((u) => u.id === me!.id)?.role;
    }
  });

  const $active = $(router.$params, $projectId, (params, id) => params.projectId == id);

  // Keep the same tab active when switching projects.
  const $href = $(router.$pattern, props.$project, (pattern, project) => {
    if (
      project.tasksEnabled &&
      (pattern?.startsWith(`/projects/{#projectId}/tasks`) || pattern?.startsWith(`/me/tasks`))
    ) {
      return `/projects/${project.id}/tasks`;
    } else if (project.notesEnabled && pattern?.startsWith(`/projects/{#projectId}/notes`)) {
      return `/projects/${project.id}/notes`;
    } else {
      return `/projects/${project.id}`;
    }
  });

  return (
    <li
      class={[styles.projectListItem, { [styles.active]: $active }]}
      style={theme.getThemeVariables$($color)}
    >
      <a
        class={styles.project}
        href={$href}
        onClick={(e) => {
          props.onClick?.();
        }}
      >
        <div class={styles.icon}>{cond($active, <FolderOpenIcon />, <FolderIcon />)}</div>
        <span class={styles.name}>{$($project, (x) => x.name)}</span>
        <div
          class={styles.roleIcon}
          title={$($role, (role) => {
            if (role) {
              return translate(`projects.roles.${role}.label`);
            }
          })}
        >
          {$(auth.$me, $project, (me, project) => {
            if (project.ownerId === me!.id) {
              return <ProjectOwner />;
            }

            const role = project.users.find((u) => u.id === me!.id)?.role;

            switch (role) {
              case "admin":
                return <ProjectAdmin />;
              case "collaborator":
                return <ProjectCollaborator />;
              case "viewer":
                return <ProjectViewer />;
            }
          })}
        </div>
      </a>
    </li>
  );
}
