import {
  $,
  $$,
  DialogStore,
  HTTPStore,
  LanguageStore,
  RouterStore,
  cond,
  repeat,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import { ConfirmDelete } from "@views/@dialogs";
import FolderIcon from "@views/@icons/Folder";
import LogOutIcon from "@views/@icons/LogOut";
import Mail from "@views/@icons/Mail";
import Plus from "@views/@icons/Plus";
import ProjectAdmin from "@views/@icons/ProjectAdmin";
import ProjectCollaborator from "@views/@icons/ProjectCollaborator";
import ProjectOwner from "@views/@icons/ProjectOwner";
import ProjectViewer from "@views/@icons/ProjectViewer";
import Time from "@views/@icons/Time";
import Trash from "@views/@icons/Trash";
import { Badge } from "@views/Badge";
import { Button } from "@views/Button";
import { CardContent, CardToolbar } from "@views/Card";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { MoreMenu } from "@views/MoreMenu";
import { Switch } from "@views/Switch";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import { UserAvatar } from "@views/UserAvatar";
import { Project, User } from "schemas";
import { EditProject } from "../EditProject/EditProject";
import { LeaveProjectDialog } from "../LeaveProjectDialog/LeaveProjectDialog";
import styles from "./Details.module.css";
import { InviteDialog } from "./InviteDialog/InviteDialog";

interface DetailsProps {
  $project: Readable<Project>;
}

interface ProjectUser extends User {
  role: Project["users"][0]["role"];
  joinedAt: string;
}

export function Details(props: DetailsProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const dialog = ctx.getStore(DialogStore);
  const http = ctx.getStore(HTTPStore);
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $$moreMenuIsOpen = $$(false);
  const $$isEditing = $$(false);

  const $project = props.$project;
  const $userRole = $(auth.$me, $project, (me, project) => {
    if (!me || !project) return null;
    return project.users.find((u) => u.id === me.id)?.role ?? null;
  });

  const $isOwner = $($project, auth.$me, (project, me) => project?.ownerId === me?.id);
  const $isAdmin = $(
    $project,
    auth.$me,
    (project, me) =>
      project != null && me != null && !!project.users.find((u) => u.id === me.id && u.role === "admin"),
  );

  ctx.observe($isOwner, $isAdmin, $userRole, (isOwner, isAdmin, userRole) => {
    ctx.log({ isOwner, isAdmin, userRole });
  });

  const $users: Readable<(ProjectUser & User)[]> = $($project, auth.$users, (project, users) => {
    if (!project || !users) return [];

    return project.users.map((u) => {
      const user = users.find((user) => user.id === u.id)!;
      return { ...user, ...u };
    });
  });
  const $invites = $($project, (project) => {
    return project?.invites ?? [];
  });

  async function sendInvite(email: string, role: "viewer" | "collaborator" | "admin") {
    const projectId = $project.get().id;
    try {
      await http.post(`/api/projects/${projectId}/invites`, {
        body: { email, role },
      });
    } catch (err: any) {
      if (err.response) {
        const res = err.response;
        ctx.error(res);
      } else {
        throw err;
      }
    }
  }

  return (
    <>
      <CardContent>
        <div class={styles.content}>
          <header class={styles.projectHeader}>
            <div class={styles.projectHeaderIcon}>
              <FolderIcon />
            </div>
            {cond(
              $$isEditing,
              <div class={styles.projectEdit}>
                <EditProject
                  project={$project}
                  onSave={(updated) => {
                    const project = $project.get();
                    projects.updateProject(project.id, updated);
                  }}
                  onCancel={() => {
                    $$isEditing.set(false);
                  }}
                />
              </div>,
              <>
                <h2 class={styles.projectHeaderText}>{$($project, (p) => p?.name)}</h2>
                {cond(
                  $isAdmin,
                  <div class={styles.projectEditButton}>
                    <Button
                      onClick={() => {
                        $$isEditing.set(true);
                      }}
                    >
                      {translate("common.edit")}
                    </Button>
                  </div>,
                )}
              </>,
            )}
          </header>

          <CollapsibleListSection title={translate("workspace.project.overview.features.title")}>
            <ul class={styles.cardSwitchList}>
              {cond(
                $(
                  $isAdmin,
                  $($project, (p) => p.chatEnabled),
                  (admin, enabled) => admin || enabled,
                ),
                <li class={styles.cardSwitchListItem}>
                  <label>
                    <div class={styles.cardSwitchIcon}>
                      <img src="/icons/chat.12.trans.png" alt="" />
                    </div>

                    <span class={styles.cardSwitchLabel}>
                      {translate("workspace.project.overview.features.chatLabel")}
                    </span>

                    {cond(
                      $isAdmin,
                      <div class={styles.cardSwitch}>
                        <Switch
                          $value={$($project, (p) => p.chatEnabled)}
                          onChange={(chatEnabled) => {
                            const project = $project.get();
                            projects.updateProject(project.id, { chatEnabled });
                          }}
                        />
                      </div>,
                    )}
                  </label>
                </li>,
              )}

              {cond(
                $(
                  $isAdmin,
                  $($project, (p) => p.notesEnabled),
                  (admin, enabled) => admin || enabled,
                ),
                <li class={styles.cardSwitchListItem}>
                  <label>
                    <div class={styles.cardSwitchIcon}>
                      <img src="/icons/notes.12.trans.png" alt="" />
                    </div>

                    <span class={styles.cardSwitchLabel}>
                      {translate("workspace.project.overview.features.notesLabel")}
                    </span>

                    {cond(
                      $isAdmin,
                      <div class={styles.cardSwitch}>
                        <Switch
                          $value={$($project, (p) => p.notesEnabled)}
                          onChange={(notesEnabled) => {
                            const project = $project.get();
                            projects.updateProject(project.id, { notesEnabled });
                          }}
                        />
                      </div>,
                    )}
                  </label>
                </li>,
              )}

              {cond(
                $(
                  $isAdmin,
                  $($project, (p) => p.tasksEnabled),
                  (admin, enabled) => admin || enabled,
                ),
                <li class={styles.cardSwitchListItem}>
                  <label>
                    <div class={styles.cardSwitchIcon}>
                      <img src="/icons/tasks.12.trans.png" alt="" />
                    </div>

                    <span class={styles.cardSwitchLabel}>
                      {translate("workspace.project.overview.features.tasksLabel")}
                    </span>

                    {cond(
                      $isAdmin,
                      <div class={styles.cardSwitch}>
                        <Switch
                          $value={$($project, (p) => p.tasksEnabled)}
                          onChange={(tasksEnabled) => {
                            const project = $project.get();
                            projects.updateProject(project.id, { tasksEnabled });
                          }}
                        />
                      </div>,
                    )}
                  </label>
                </li>,
              )}
            </ul>
          </CollapsibleListSection>

          <CollapsibleListSection
            title={translate("workspace.project.overview.people.title")}
            itemCount={$($users, (x) => x.length)}
            controls={[
              {
                id: "add-user",
                title: "Add",
                icon: <Plus />,
                variant: "accent",
                hidden: $($isAdmin, (isAdmin) => !isAdmin),
                onClick: (e) => {
                  const project = $project.get();

                  dialog.open(InviteDialog, {
                    project,
                    onSubmit(data) {
                      return sendInvite(data.email, data.role);
                    },
                    onCancel() {},
                  });
                },
              },
            ]}
          >
            <ul class={styles.userList}>
              {repeat(
                $users,
                (u) => u.id,
                ($user, $index, ctx) => {
                  const theme = ctx.getStore(ThemeStore);

                  const $name = $($user, (x) => x.name);
                  const $color = $($user, (x) => x.color);
                  const $themeVars = theme.getThemeVariables$($color);

                  const $role = $($user, $project, (user, project) => {
                    if (project.ownerId === user.id) {
                      return "owner";
                    } else {
                      return project.users.find((u) => u.id === user.id)?.role ?? null;
                    }
                  });

                  const $$menuIsOpen = $$(false);

                  return (
                    <li class={styles.userListItem}>
                      <div class={styles.userLayout} style={$themeVars}>
                        <div class={styles.userInfo}>
                          <div class={styles.userIcon}>
                            <UserAvatar src={$($user, (u) => u.avatar ?? "")} />
                          </div>

                          <div class={styles.userText}>
                            <span class={styles.userName}>
                              {$name}
                              <div class={styles.roleBadge}>
                                {$($role, (role) => {
                                  switch (role) {
                                    case "owner":
                                      return (
                                        <Badge
                                          icon={<ProjectOwner />}
                                          label={translate("workspace.project.roles.owner.title")}
                                        />
                                      );
                                    case "admin":
                                      return (
                                        <Badge
                                          icon={<ProjectAdmin />}
                                          label={translate("workspace.project.roles.admin.title")}
                                        />
                                      );
                                    case "collaborator":
                                      return (
                                        <Badge
                                          icon={<ProjectCollaborator />}
                                          label={translate("workspace.project.roles.collaborator.title")}
                                        />
                                      );
                                    case "viewer":
                                      return (
                                        <Badge
                                          icon={<ProjectViewer />}
                                          label={translate("workspace.project.roles.viewer.title")}
                                        />
                                      );
                                  }
                                })}
                              </div>
                            </span>

                            <ul class={styles.userMeta}>
                              <li>
                                <Mail /> {$($user, (u) => u.email)}
                              </li>
                              <li>
                                <Time />{" "}
                                {$($user, $currentLanguage, (u, lang) => formatJoinDate(u.joinedAt, lang!))}
                              </li>
                            </ul>
                          </div>
                        </div>

                        {/* {cond(
                          $($userRole, (role) => role === "admin"), // Includes owner
                          <div class={styles.userActions}>
                            <MoreMenu
                              $$open={$$menuIsOpen}
                              size="small"
                              options={[
                                // {
                                //   label: translate("common.edit"),
                                //   icon: <EditIcon />,
                                //   callback: () => {
                                //     $$editIsOpen.set(true);
                                //   },
                                // },
                                {
                                  label: translate("common.delete"),
                                  icon: <Trash />,
                                  variant: "destructive",
                                  callback: async () => {
                                    // await projects.deleteProject($project.get().id);
                                  },
                                },
                              ]}
                            />
                          </div>,
                        )} */}
                      </div>

                      <div class={styles.projectDivider} />
                    </li>
                  );
                },
              )}
            </ul>
          </CollapsibleListSection>

          {cond(
            $($invites, (invites) => invites.length > 0),
            <CollapsibleListSection
              title={translate("workspace.project.overview.invites.title")}
              itemCount={$($invites, (invites) => invites.length)}
            >
              <ul class={styles.userList}>
                {repeat(
                  $invites,
                  (invite) => invite.email,
                  ($invite) => {
                    const $email = $($invite, (i) => i.email);
                    const $role = $($invite, (i) => i.role);
                    const $timestamp = $($invite, (i) => i.createdAt);

                    return (
                      <li class={styles.inviteListItem}>
                        <span class={styles.inviteListItemName}>
                          {$email}{" "}
                          <div class={styles.roleBadge}>
                            {$($role, (role) => {
                              switch (role) {
                                case "owner":
                                  return (
                                    <Badge
                                      icon={<ProjectOwner />}
                                      label={translate("workspace.project.roles.owner.title")}
                                    />
                                  );
                                case "admin":
                                  return (
                                    <Badge
                                      icon={<ProjectAdmin />}
                                      label={translate("workspace.project.roles.admin.title")}
                                    />
                                  );
                                case "collaborator":
                                  return (
                                    <Badge
                                      icon={<ProjectCollaborator />}
                                      label={translate("workspace.project.roles.collaborator.title")}
                                    />
                                  );
                                case "viewer":
                                  return (
                                    <Badge
                                      icon={<ProjectViewer />}
                                      label={translate("workspace.project.roles.viewer.title")}
                                    />
                                  );
                              }
                            })}
                          </div>
                        </span>

                        <ul class={styles.userMeta}>
                          <li>
                            <Time />{" "}
                            {$($timestamp, $currentLanguage, (ts, lang) => formatInviteDate(ts, lang!))}
                          </li>
                        </ul>
                      </li>
                    );
                  },
                )}
              </ul>
            </CollapsibleListSection>,
          )}
        </div>
      </CardContent>

      <CardToolbar>
        <ToolBar>
          <ToolBarGroup />
          <ToolBarGroup>
            <MoreMenu
              $$open={$$moreMenuIsOpen}
              color={$($project, (p) => p?.color)}
              preferHorizontalAlignment="left"
              preferVerticalAlignment="above"
              options={[
                {
                  label: translate("workspace.project.overview.actions.leaveProject.label"),
                  icon: <LogOutIcon />,
                  disabled: $isOwner,
                  variant: "destructive",
                  callback: () => {
                    const project = $project.get()!;
                    dialog.open(LeaveProjectDialog, {
                      project,
                      onConfirm: () => {
                        const project = $project.get()!;
                        projects
                          .leaveProject(project.id)
                          .catch((err) => ctx.error(err))
                          .then(() => {
                            router.navigate(`/`);
                          });
                      },
                    });
                  },
                },
                {
                  label: translate("workspace.project.overview.actions.deleteProject.label"),
                  icon: <Trash />,
                  hidden: $($isOwner, $isAdmin, (isOwner, isAdmin) => !isOwner && !isAdmin),
                  variant: "destructive",
                  callback: () => {
                    const project = $project.get()!;
                    dialog.open(ConfirmDelete, {
                      title: translate("workspace.project.overview.actions.deleteProject.dialogTitle"),
                      itemName: project.name,
                      message: translate("workspace.project.overview.actions.deleteProject.dialogMessage"),
                      onConfirm: () => {
                        const project = $project.get()!;
                        projects
                          .deleteProject(project.id)
                          .catch((err) => ctx.error(err))
                          .then(() => {
                            router.navigate(`/`);
                          });
                      },
                    });
                  },
                },
              ]}
            />
          </ToolBarGroup>
        </ToolBar>
      </CardToolbar>
    </>
  );
}

function formatJoinDate(date: string | Date, lang: string) {
  return new Intl.DateTimeFormat([lang], {
    year: "numeric",
    month: "long",
  }).format(new Date(date));
}

function formatInviteDate(date: string | Date, lang: string) {
  return new Intl.DateTimeFormat([lang], {
    dateStyle: "long",
  }).format(new Date(date));
}
