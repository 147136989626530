import { sticky } from "@helpers/sticky";
import { $, $$, cond, LanguageStore, repeat, RouterStore, type ViewContext } from "@manyducks.co/dolla";
import { BadgeStore } from "@stores/BadgeStore";
import { ChatStore } from "@stores/ChatStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { PushStore } from "@stores/PushStore";
import { ThemeStore } from "@stores/ThemeStore";
import ChevronLeft from "@views/@icons/ChevronLeft";
import Folder from "@views/@icons/Folder";
import { Button } from "@views/Button";
import { CardContent, CardToolbar } from "@views/Card";
import { CardDetailStack } from "@views/CardDetailStack";
// import { makeLoaderState } from "@views/CardLoader";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import { IconButton } from "@views/IconButton";
import styles from "./Chat.module.css";
import { ChatEditor } from "./ChatEditor/ChatEditor";
import { ChatMessageList } from "./ChatMessageList/ChatMessageList";

interface ChatCardProps {
  onShowSettings?: () => void;
}

export function Chat(props: ChatCardProps, ctx: ViewContext) {
  ctx.name = "Chat";

  const { translate } = ctx.getStore(LanguageStore);
  const projects = ctx.getStore(ProjectsStore);
  const badge = ctx.getStore(BadgeStore);
  const push = ctx.getStore(PushStore);
  const chat = ctx.getStore(ChatStore);
  const router = ctx.getStore(RouterStore);
  const theme = ctx.getStore(ThemeStore);

  // NOTE: Add SELECT with projects in list
  // move projectId into store and export with setProjectId
  // see Settings.tsx; search "$currentLanguage"
  const $project = $(chat.$$projectId, projects.$cache, (id, projects) => projects.find((p) => p.id === id));
  const $latestProject = $($project, sticky());

  const $projectsWithChat = $(projects.$cache, (projects) =>
    projects.filter((p) => p.chatEnabled && p.users.length > 1).sort((a, b) => a.name.localeCompare(b.name)),
  );

  // const $$projectId = $$<number>();
  // const loader = makeLoaderState();
  // loader.hideLoader();

  const $messages = $(chat.$$projectId, chat.$messages, (projectId, messages) =>
    messages
      .filter((m) => m.projectId === projectId)
      .sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        } else if (a.createdAt > b.createdAt) {
          return 1;
        } else {
          return 0;
        }
      }),
  );

  const $$scrollContainer = $$<HTMLElement>();

  function scrollToBottom(animate = false) {
    const el = $$scrollContainer.get()!;

    // Reset unread message count.
    badge.clear();

    el.scrollTo({
      top: el.scrollHeight,
      behavior: animate ? "smooth" : "instant",
    });
  }

  return (
    <div class={styles.chat}>
      <CardDetailStack
        showDetail={$(chat.$$projectId, (projectId) => projectId != null)}
        listContent={
          <div class={styles.listContent}>
            <CollapsibleListSection
              title={translate("workspace.chat.list.projectRooms.title")}
              itemCount={$($projectsWithChat, (projects) => projects.length)}
            >
              <ul class={styles.chatList}>
                {repeat(
                  $projectsWithChat,
                  (p) => p.id,
                  ($project) => (
                    <li style={theme.getThemeVariables$($($project, (p) => p.color))}>
                      <button
                        class={[
                          styles.chatListItem,
                          {
                            [styles.active]: $(
                              $project,
                              chat.$$projectId,
                              (project, id) => project?.id === id,
                            ),
                          },
                        ]}
                        onClick={() => {
                          chat.$$projectId.set($project.get().id);
                        }}
                      >
                        <div class={styles.chatListItemIcon}>
                          <Folder />
                        </div>
                        {$($project, (p) => p.name)}
                      </button>
                    </li>
                  ),
                )}
              </ul>
            </CollapsibleListSection>
          </div>
        }
        detailContent={
          <div class={styles.layout} style={theme.getThemeVariables$($($latestProject, (p) => p?.color))}>
            <div
              class={styles.chatHeader}
              style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
            >
              <IconButton onClick={() => chat.$$projectId.set(undefined)}>
                <ChevronLeft />
              </IconButton>

              <span class={styles.chatTitle}>{$($project, (p) => p?.name)}</span>
            </div>

            {cond(
              push.$displayChatBanner,

              <div class={styles.pushDisabledBanner}>
                <p>{translate("workspace.chat.pushNotificationsOffBanner.message")}</p>
                <div class={styles.pushDisabledBannerControls}>
                  <Button onClick={push.dismissChatBanner}>
                    {translate("workspace.chat.pushNotificationsOffBanner.dismissButtonText")}
                  </Button>
                  <Button
                    onClick={() => {
                      router.navigate("/me/settings");
                      props.onShowSettings?.();
                    }}
                  >
                    {translate("workspace.chat.pushNotificationsOffBanner.showSettingsButtonText")}
                  </Button>
                </div>
              </div>,
            )}

            <CardContent ref={$$scrollContainer}>
              <div class={styles.endOfMessages}>
                <span class={styles.dividerLine} />
                <span class={styles.dividerLabel}>{translate("workspace.chat.endOfMessages")}</span>
                <span class={styles.dividerLine} />
              </div>

              <ChatMessageList
                $messages={$messages}
                onMessageAdded={() => {
                  scrollToBottom(true);
                }}
              />
            </CardContent>

            <CardToolbar>
              <ChatEditor
                onSubmit={(delta, attachments) => {
                  const projectId = chat.$$projectId.get()!; // Is defined if $canSend is true
                  // const attachments = $$inputFiles.get().map((f) => f.uploadId);
                  chat.sendMessage(projectId, delta, attachments);
                }}
              />
            </CardToolbar>
          </div>
        }
      />
    </div>
  );
}
