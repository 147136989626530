import { adjustAccentColor } from "@helpers/makeTheme";
import { $, $$, cond, LanguageStore, repeat, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { ThemeStore } from "@stores/ThemeStore";
import UserIcon from "@views/@icons/User";
import { TextInput } from "@views/TextInput";
import { UserAvatar } from "@views/UserAvatar";
import { type User } from "schemas";
import styles from "./AssignMenu.module.css";

interface AssignMenuProps {
  $projectId: Readable<number>;
  $value?: Readable<number | null>;
  onChange: (user: User | null) => void;
}

export function AssignMenu(props: AssignMenuProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const { translate } = ctx.getStore(LanguageStore);

  const $project = $(projects.$cache, props.$projectId, (projects, id) => projects.find((p) => p.id === id));
  const $value = $(props.$value);

  const $$inputValue = $$("");

  const $users = $(auth.$users, auth.$me, $project, $$inputValue, (users, me, project, inputValue) => {
    const results = [
      me!,
      ...users
        .filter(
          (u) => u.id !== me!.id && !!project?.users.find((pu) => pu.id === u.id && pu.role !== "viewer"),
        )
        .sort((a, b) => {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();
          if (aName < bName) {
            return -1;
          } else if (aName > bName) {
            return +1;
          } else {
            return 0;
          }
        }),
    ];
    const term = inputValue.toLowerCase();
    return results.filter((user) => {
      return user.name.toLowerCase().startsWith(term);
    });
  });

  return (
    <div class={styles.container}>
      <div class={styles.input}>
        <TextInput
          $$value={$$inputValue}
          placeholder={translate("views.taskListItem.assignMenu.searchPlaceholder")}
        />
      </div>

      <ul class={styles.results}>
        {cond(
          $($value, $$inputValue, (v, i) => v != null && i.trim().length === 0),
          <li class={styles.resultItem}>
            <button
              class={styles.resultItemButton}
              onClick={() => {
                props.onChange(null);
              }}
            >
              <div class={styles.resultItemIcon}>
                <div class={styles.unassignedIcon}>
                  <UserIcon />
                </div>
                {/* <UserAvatar src={computed(props.$user, (u) => u.avatar ?? "")} /> */}
              </div>
              <span
                class={styles.resultItemLabel}
                style={{
                  color: "var(--color-text-secondary)",
                }}
              >
                {translate("views.taskListItem.assignMenu.unassignLabel")}
              </span>
            </button>
          </li>,
        )}

        {repeat(
          $($users, $value, (users, selectedId) => users.filter((u) => u.id !== selectedId)),
          (u) => u.id,
          ($user) => {
            return <ResultItem $user={$user} onClick={props.onChange} />;
          },
        )}
      </ul>
    </div>
  );
}

interface ResultItemProps {
  $user: Readable<User>;
  onClick: (user: User) => void;
}

function ResultItem(props: ResultItemProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);

  return (
    <li class={styles.resultItem}>
      <button
        class={styles.resultItemButton}
        onClick={() => {
          props.onClick(props.$user.get());
        }}
      >
        <div class={styles.resultItemIcon}>
          <UserAvatar src={$(props.$user, (u) => u.avatar ?? "")} />
        </div>
        <span
          class={styles.resultItemLabel}
          style={{
            color: $(props.$user, theme.$isDark, (user, dark) => {
              return adjustAccentColor(user.color, dark);
            }),
          }}
        >
          {$(props.$user, (u) => u.name)}
        </span>
      </button>
    </li>
  );
}
