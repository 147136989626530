import { ViewContext } from "@manyducks.co/dolla";
import { PushStore } from "@stores/PushStore";

/**
 * Returns a function that toggles push notifications on or off.
 *
 * @param ctx - Component context object.
 */
export function makeTogglePush(ctx: ViewContext) {
  const { $isPushEnabled, enablePushNotifications, disablePushNotifications } = ctx.getStore(PushStore);

  return async function togglePush() {
    if ($isPushEnabled.get()) {
      disablePushNotifications().then(() => {
        ctx.log("push disabled");
      });
    } else {
      enablePushNotifications()
        .then(() => {
          ctx.log("push enabled");
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };
}
