export function sticky<T>(): (value: T) => T;
export function sticky<I, O>(callback: (value: I) => O): (value: I) => O;

/**
 * Takes a callback and returns a function that will call `callback` each time the function is called,
 * but will return the previous value if `callback` returns undefined.
 *
 * Essentially, it keeps the last defined value.
 */
export function sticky(callback: (value: any) => any = (x) => x) {
  let lastReturned: any;
  return function (value: any) {
    const returned = callback(value);
    if (returned === undefined) {
      return lastReturned;
    } else {
      lastReturned = returned;
      return returned;
    }
  };
}
