import { isSameDay, subDays } from "date-fns";

export function formatTimestamp(lang: string, date: string | Date) {
  date = new Date(date);
  const now = new Date();

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: lang === "tok" ? false : undefined,
  };
  const timestamp = new Intl.DateTimeFormat(lang, options).format(date);

  if (isSameDay(date, now)) {
    return { key: "timestampSameDayLabel", timestamp };
  } else if (isSameDay(date, subDays(now, 1))) {
    return { key: "timestampPreviousDayLabel", timestamp };
  } else {
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "2-digit",
      hour12: lang === "tok" ? false : undefined,
    };
    const timestamp = new Intl.DateTimeFormat(lang, options).format(date);

    return { key: "timestampDateLabel", timestamp };
  }
}
