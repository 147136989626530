export default {
  "calendarBlockView": "CalendarInput_calendarBlockView_1fb6a",
  "calendarBlockWeekView": "CalendarInput_calendarBlockWeekView_1fb6a",
  "calendarBlockControls": "CalendarInput_calendarBlockControls_1fb6a",
  "calendarStripView": "CalendarInput_calendarStripView_1fb6a",
  "calendarStripContent": "CalendarInput_calendarStripContent_1fb6a",
  "calendarStripControls": "CalendarInput_calendarStripControls_1fb6a",
  "buttonIcon": "CalendarInput_buttonIcon_1fb6a",
  "calendarStripButton": "CalendarInput_calendarStripButton_1fb6a",
  "calendarStripButtonIcon": "CalendarInput_calendarStripButtonIcon_1fb6a",
  "calendarStripWeekView": "CalendarInput_calendarStripWeekView_1fb6a",
  "weekView": "CalendarInput_weekView_1fb6a",
  "weekViewMonths": "CalendarInput_weekViewMonths_1fb6a",
  "weekViewMonth": "CalendarInput_weekViewMonth_1fb6a",
  "starts": "CalendarInput_starts_1fb6a",
  "ends": "CalendarInput_ends_1fb6a",
  "weekViewDays": "CalendarInput_weekViewDays_1fb6a",
  "dayView": "CalendarInput_dayView_1fb6a",
  "weekend": "CalendarInput_weekend_1fb6a",
  "selected": "CalendarInput_selected_1fb6a",
  "today": "CalendarInput_today_1fb6a",
  "dayLabel": "CalendarInput_dayLabel_1fb6a",
  "dayLabelWeekday": "CalendarInput_dayLabelWeekday_1fb6a",
  "dayLabelDate": "CalendarInput_dayLabelDate_1fb6a",
  "dayContent": "CalendarInput_dayContent_1fb6a",
  "dayItemCount": "CalendarInput_dayItemCount_1fb6a"
};import "ni:sha-256;44mXXwmbR9xufPtLahMu1G-Z5Ec7CYZfm8xFfKKC_C8";