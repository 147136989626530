export default {
  "layout": "SearchMenu_layout_6507a",
  "textInput": "SearchMenu_textInput_6507a",
  "formatOptions": "SearchMenu_formatOptions_6507a",
  "formatOption": "SearchMenu_formatOption_6507a",
  "formatIcon": "SearchMenu_formatIcon_6507a",
  "results": "SearchMenu_results_6507a",
  "resultContent": "SearchMenu_resultContent_6507a",
  "noResults": "SearchMenu_noResults_6507a",
  "userScopeCharm": "SearchMenu_userScopeCharm_6507a"
};import "ni:sha-256;LX1jzE63e7GbNxs-jzVuoT_EOu8XGT3BSln7PlDQ4ag";