import {
  $,
  $$,
  HTTPStore,
  RouterStore,
  cond,
  type Readable,
  type ViewContext,
  type Writable,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { LoaderStore } from "@stores/LoaderStore";
import { ThemeStore } from "@stores/ThemeStore";
import ArrowRight from "@views/@icons/ArrowRight";
import Check from "@views/@icons/Check";
import { Button } from "@views/Button";
import { ColorInput } from "@views/ColorInput";
import { TextInput } from "@views/TextInput";
import chroma from "chroma-js";
import { User } from "schemas";
import styles from "./Join.module.css";

interface JoinProps {}

export function Join(props: JoinProps, ctx: ViewContext) {
  const auth = ctx.getStore(AuthStore);
  const loader = ctx.getStore(LoaderStore);
  const router = ctx.getStore(RouterStore);
  const http = ctx.getStore(HTTPStore);

  const $$step = $$(0);
  const $$email = $$<string>();
  const $$color = $$<string>();
  const $$token = $$<string>("");

  ctx.onConnected(async () => {
    const query = router.$$query.get();
    const token = query.token as string;

    if (token) {
      // Confirm email and continue process
      // proceed based on which info is filled in on API userSignup response data (we will save with every step)
      // TODO: Token expired?
      // TODO: Pull current signup data
      try {
        await auth.logOut(); // Make sure we're logged out of any other account first.
        const res = await http.get<{ step: number; email: string; color?: string }>("/api/join", {
          query: { token },
        });
        if (res.status === 200) {
          const { step, email, color } = res.body;
          $$step.set(step);
          $$email.set(email);
          if (color) $$color.set(color);
          $$token.set(token);
        } else {
          // TODO: Handle error
          ctx.error(res);
        }
      } catch (err: any) {
        if (err.response) {
          if (err.response.status === 404) {
            // No signup record.
            ctx.warn("NO SIGNUP RECORD FOUND");
          }
        } else {
        }
      }
    } else {
      router.navigate("/");
    }

    loader.hideAppLoader();
  });

  const $token = $($$token);

  const $theme = ctx.getStore(ThemeStore).getThemeVariables$($$color);

  return (
    <div class={styles.layout} style={$theme}>
      <div class={styles.content}>
        <h1>Welcome to Quack</h1>
        <ol class={styles.stepList}>
          <li>
            <ProgressDot step={0} $current={$$step} />
            <div class={styles.stepListText}>
              <span>Confirm email</span>
              <span class={styles.secondary}>{$$email}</span>
            </div>
          </li>
          <li>
            <ProgressDot step={1} $current={$$step} />
            <div class={styles.stepListText}>
              <span>Add password</span>
            </div>
          </li>
          <li>
            <ProgressDot step={2} $current={$$step} />
            <div class={styles.stepListText}>
              <span>Choose name and color</span>
            </div>
          </li>
          {/* <li>
            <ProgressDot step={3} $current={$$step} />
            Authenticator (optional)
          </li> */}
          {/* <li>(Later?) Choose/draw avatar</li>
          <li>(Later) Offer supporter subscription</li> */}
          <li>
            <ProgressDot step={3} $current={$$step} />
            <div class={styles.stepListText}>
              <span>Done!</span>
            </div>
          </li>
        </ol>
        {$($$step, (step) => {
          switch (step) {
            case 0:
              return <p>Verifying email address...</p>;
            case 1:
              return (
                <Step1_Password
                  $token={$token}
                  onNext={() => {
                    $$step.update((step) => step + 1);
                  }}
                />
              );
            case 2:
              return (
                <Step2_User
                  $token={$token}
                  onColorChange={$$color.set}
                  onResponse={({ user, token, expiresAt }) => {
                    auth.setAuth(token, new Date(expiresAt));
                  }}
                  onNext={() => {
                    $$step.update((step) => step + 1);
                  }}
                />
              );
            // case 3:
            //   return (
            //     <Step3_2FA
            //       $token={$token}
            //       onNext={() => {
            //         $$step.update((step) => step + 1);
            //       }}
            //     />
            //   );

            default:
              return <Done />;
          }
        })}
      </div>
    </div>
  );
}

interface ProgressDotProps {
  step: number;
  $current: Readable<number>;
}

function ProgressDot(props: ProgressDotProps) {
  const { step, $current } = props;

  const $isCurrent = $($current, (current) => current === step);
  const $isDone = $($current, (current) => current > step);

  return (
    <div
      class={[
        styles.progressDot,
        {
          [styles.current]: $isCurrent,
          [styles.done]: $isDone,
        },
      ]}
    >
      {cond($isCurrent, <ArrowRight />)}
      {cond($isDone, <Check />)}
    </div>
  );
}

interface StepProps {
  $token: Readable<string>;
  onNext: () => void;
}

function Step1_Password(props: StepProps, ctx: ViewContext) {
  const http = ctx.getStore(HTTPStore);

  let isSubmitting = false;
  const $$errorMessage = $$<string>();

  const $$password = $$("");
  const $$verify = $$("");

  return (
    <form
      class={styles.form}
      onSubmit={(e) => {
        e.preventDefault();

        if (isSubmitting) return;
        isSubmitting = true;

        const token = props.$token.get();

        const form = e.currentTarget as HTMLFormElement;
        const password: string = form.password.value;
        const verify: string = form.verify.value;

        $$errorMessage.set(undefined);

        http
          .post("/api/join/1", { body: { password, verify }, query: { token } })
          .then((res) => {
            props.onNext();
          })
          .catch((err) => {
            if (err.response) {
              const res = err.response;
              $$errorMessage.set(res.body.message);
            } else {
              ctx.error(err);
            }
          })
          .finally(() => {
            isSubmitting = false;
          });
      }}
    >
      <div class={styles.formGroup}>
        <p class={styles.formGroupDescription}>
          Password must be at least 16 characters. See{" "}
          <a href="https://xkcd.com/936/" target="_blank" rel="noreferrer noopener">
            relevant xkcd.
          </a>
        </p>
      </div>
      <div class={styles.formGroup}>
        <label for="password">Password</label>
        <TextInput
          id="password"
          type="password"
          name="password"
          $$value={$$password}
          minlength={16}
          placeholder="************"
          autocomplete={false}
        />
      </div>
      <div class={styles.formGroup}>
        <label for="verify">Password Again</label>
        <TextInput
          id="verify"
          type="password"
          name="verify"
          $$value={$$verify}
          minlength={16}
          placeholder="************"
          autocomplete={false}
        />
      </div>

      {cond($$errorMessage, <p style={{ color: "red" }}>{$$errorMessage}</p>)}

      <div class={styles.formControls}>
        <Button
          type="submit"
          disabled={$($$password, $$verify, (pass, verify) => {
            return pass !== verify || pass.length < 16;
          })}
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

interface Step2Response {
  user: User;
  token: string;
  expiresAt: string;
}

interface Step2Props extends StepProps {
  onResponse: (data: Step2Response) => void;
  onColorChange: (color: string) => void;
}

function Step2_User(props: Step2Props, ctx: ViewContext) {
  const http = ctx.getStore(HTTPStore);

  const $$name = $$<string>("");
  const $$color = $$<string>(chroma.random().hex());

  ctx.observe($$color, props.onColorChange);

  const $$errorMessage = $$<string>();

  let isSubmitting = false;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (isSubmitting) return;
        isSubmitting = true;

        const token = props.$token.get();

        const name = $$name.get();
        const color = $$color.get();

        http
          .post<Step2Response>("/api/join/2", { body: { name, color }, query: { token } })
          .then((res) => {
            props.onResponse(res.body);
            props.onNext();
          })
          .catch((err) => {
            if (err.response) {
              const res = err.response;
              $$errorMessage.set(res.body.message);
            } else {
              ctx.error(err);
            }
          })
          .finally(() => {
            isSubmitting = false;
          });
      }}
    >
      <div class={styles.formGroup}>
        <label>Name</label>
        <TextInput $$value={$$name} name="name" />
      </div>
      <div class={styles.formGroup}>
        <label>Color</label>
        <ColorInput value={$$color} name="color" />
      </div>

      <div class={styles.formControls}>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
}

function Step3_2FA(props: StepProps, ctx: ViewContext) {
  const http = ctx.getStore(HTTPStore);

  const $$uri = $$<string>();
  const $$qrCode = $$<string>();
  const $$burnCode = $$<string>();
  const $$errorMessage = $$<string>();

  ctx.onConnected(() => {
    const token = props.$token.get();
    http.post<{ uri: string; qr: string }>("/api/join/3a", { query: { token } }).then((res) => {
      $$uri.set(res.body.uri);
      $$qrCode.set(res.body.qr);
    });
  });

  let isSubmitting = false;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (isSubmitting) return;
        isSubmitting = true;

        const token = props.$token.get();

        const authCode: string = (e.currentTarget as HTMLFormElement).authCode.value;
        ctx.log(authCode);

        $$errorMessage.set(undefined);

        http
          .post<{ burnCode: string }>("/api/join/3b", { body: { authCode }, query: { token } })
          .then((res) => {
            $$burnCode.set(res.body.burnCode);
          })
          .catch((err) => {
            if (err.response != null) {
              const res = err.response;
              $$errorMessage.set(res.body.message);
            } else {
              ctx.error(err);
            }
          })
          .finally(() => {
            isSubmitting = false;
          });
      }}
    >
      {cond(
        $$burnCode,
        <div>
          <p>{$$burnCode}</p>
          <Button
            onClick={() => {
              props.onNext();
            }}
          >
            I Saved It
          </Button>
        </div>,
        <div>
          {cond($$qrCode, <img style={{ width: "250px" }} src={$$qrCode as Writable<string>} alt="" />)}

          <label>Two Factor Code</label>
          <input type="text" name="authCode" />
          {cond($$errorMessage, <p style={{ color: "red" }}>{$$errorMessage}</p>)}

          <Button type="submit">Submit</Button>
        </div>,
      )}
    </form>
  );
}

function Done(props: {}, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        router.navigate("/");
      }}
    >
      <div class={styles.formGroup}>
        <p>You're all set.</p>
      </div>

      <div class={styles.formButtons}>
        <Button type="submit">Get Quacking</Button>
      </div>
    </form>
  );
}
