import {
  $,
  $$,
  LanguageStore,
  repeat,
  type Readable,
  type ViewContext,
  type Writable,
  cond,
} from "@manyducks.co/dolla";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  getMonth,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import ChevronLeft from "@views/@icons/ChevronLeft";
import ChevronRight from "@views/@icons/ChevronRight";
import { HoverMenu, Point } from "@views/HoverMenu";
import { IconButton } from "@views/IconButton";
import styles from "./CalendarInput.module.css";
import { Button } from "@views/Button";
import { ClockStore } from "@stores/ClockStore";

interface DayMeta {
  date: Date;
  label?: string;
}

interface CalendarInputProps {
  /**
   * Date value in 'YYYY-MM-DD' format.
   */
  $value: Readable<string>;

  onChange: (newValue: string) => void;
}

export function CalendarInput(props: CalendarInputProps, ctx: ViewContext) {
  const inputId = `input_${ctx.uniqueId}`;

  const $$anchor = $$<HTMLElement>();
  const $$open = $$(false);

  return (
    <div class={styles.container}>
      <button
        ref={$$anchor}
        class={styles.inputButton}
        onClick={(e) => {
          $$open.set(true);
        }}
      >
        DATE
      </button>

      <HoverMenu
        $$open={$$open}
        $anchorRef={$$anchor}
        preferHorizontalAlignment={"center"}
        preferVerticalAlignment={"below"}
      >
        {/* <CalendarBlockView /> */}
      </HoverMenu>
    </div>
  );
}

interface CalendarBlockViewProps {
  $$date: Writable<Date | undefined>;
  $max?: Readable<Date> | Readable<Date | undefined>;
  $min?: Readable<Date> | Readable<Date | undefined>;
  $dayMeta?: Readable<DayMeta[]>;
}

/**
 * Displays a calendar one month at a time with weeks stacked vertically.
 */
export function CalendarBlockView(props: CalendarBlockViewProps, ctx: ViewContext) {
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const { $$date } = props;
  const $$startDate = $$(startOfWeek($$date.get() ?? new Date()));

  return (
    <div class={styles.calendarBlockView}>
      <div class={styles.calendarBlockContent}>
        <div class={styles.calendarBlockControls}>
          <IconButton
            onClick={() => {
              $$startDate.update((current) => subWeeks(current, 4));
            }}
          >
            <ChevronLeft />
          </IconButton>

          <span>
            {$($$date, $currentLanguage, (date, lang) => {
              if (date == null) {
                return "---";
              }

              return new Intl.DateTimeFormat([lang!, "en-US"], {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              }).format(date);
            })}
          </span>

          <IconButton
            onClick={() => {
              $$startDate.update((current) => addWeeks(current, 4));
            }}
          >
            <ChevronRight />
          </IconButton>
        </div>

        <div class={styles.calendarBlockWeekView}>
          <WeekView
            $weekOf={$$startDate}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>
        <div class={styles.calendarBlockWeekView}>
          <WeekView
            $weekOf={$($$startDate, (date) => addWeeks(date, 1))}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>
        <div class={styles.calendarBlockWeekView}>
          <WeekView
            $weekOf={$($$startDate, (date) => addWeeks(date, 2))}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>
        <div class={styles.calendarBlockWeekView}>
          <WeekView
            $weekOf={$($$startDate, (date) => addWeeks(date, 3))}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>
      </div>
    </div>
  );
}

interface CalendarStripViewProps {
  $$date: Writable<Date | undefined>;
  $max?: Readable<Date> | Readable<Date | undefined>;
  $min?: Readable<Date> | Readable<Date | undefined>;
  $dayMeta?: Readable<DayMeta[]>;
  onRangeUpdate?: (start: Date, end: Date) => void;
}

/**
 * Displays a calendar one week at a time.
 */
export function CalendarStripView(props: CalendarStripViewProps, ctx: ViewContext) {
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const { $$date } = props;

  const $$weekOf = $$(startOfWeek($$date.get() ?? new Date()));

  function onRangeUpdate() {
    if (props.onRangeUpdate) {
      const weekOf = $$weekOf.get();
      const end = endOfWeek(weekOf);
      props.onRangeUpdate(weekOf, end);
    }
  }

  ctx.onConnected(() => {
    onRangeUpdate();
  });

  return (
    <div class={styles.calendarStripView}>
      <div class={styles.calendarStripContent}>
        <div class={styles.calendarStripWeekView}>
          <WeekView
            $weekOf={$$weekOf}
            $$selectedDate={$$date}
            $max={props.$max}
            $min={props.$min}
            $dayMeta={props.$dayMeta}
          />
        </div>

        <div class={styles.calendarStripControls}>
          <IconButton
            onClick={() => {
              $$weekOf.update((current) => subWeeks(current, 1));
              onRangeUpdate();
            }}
          >
            <ChevronLeft />
          </IconButton>

          <span>
            {$($$date, $currentLanguage, (date, lang) => {
              return new Intl.DateTimeFormat([lang!, "en-US"], {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              }).format(date);
            })}
          </span>

          <IconButton
            onClick={() => {
              $$weekOf.update((current) => addWeeks(current, 1));
              onRangeUpdate();
            }}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

interface WeekViewProps {
  $weekOf: Readable<Date>;
  $$selectedDate: Writable<Date | undefined>;
  $max?: Readable<Date> | Readable<Date | undefined>;
  $min?: Readable<Date> | Readable<Date | undefined>;
  $dayMeta?: Readable<DayMeta[]>;
}

function WeekView(props: WeekViewProps, ctx: ViewContext) {
  const clock = ctx.getStore(ClockStore);
  const { $currentLanguage } = ctx.getStore(LanguageStore);
  const { $weekOf, $$selectedDate, $max, $min } = props;

  const $days = $($weekOf, $max, $min, props.$dayMeta, (date, max, min, dayMeta) => {
    let cursor = new Date(date);
    const end = endOfWeek(date);
    const days = [];

    while (cursor < end) {
      const meta = (dayMeta ?? []).find((m) => isSameDay(m.date, cursor));
      days.push({
        date: cursor,
        selectable: (!max || isAfter(max, cursor)) && (!min || isBefore(min, cursor)),
        label: meta?.label,
      });
      cursor = addDays(cursor, 1);
    }

    return days;
  });

  const $months = $($days, (days) => {
    const firstDate = days[0].date;
    const lastDate = days[days.length - 1].date;
    if (isSameMonth(firstDate, lastDate)) {
      return [{ date: firstDate }];
    } else {
      return [
        { date: firstDate, endsAtIndex: days.findIndex((d) => !isSameMonth(firstDate, d.date)) },
        { date: lastDate },
      ];
    }
  });

  return (
    <div class={styles.weekView}>
      <div class={styles.weekViewMonths}>
        {repeat(
          $months,
          (month) => getMonth(month.date),
          ($month, $index, ctx) => {
            const $style = $($month, $index, (month, index) => {
              if (month.endsAtIndex) {
                return { gridColumnStart: 1, gridColumnEnd: month.endsAtIndex + 1 };
              } else if (index > 0) {
                const startIndex = $months.get()[index - 1].endsAtIndex! + 1;
                return { gridColumnStart: startIndex, gridColumnEnd: 8 };
              } else {
                return { gridColumnStart: 1, gridColumnEnd: 8 };
              }
            });
            const $label = $($month, $currentLanguage, (month, lang) => {
              return new Intl.DateTimeFormat([lang!, "en-US"], { month: "short" }).format(month.date);
            });

            return (
              <div
                class={[
                  styles.weekViewMonth,
                  {
                    [styles.starts]: $($style, (s) => s.gridColumnStart > 1),
                    [styles.ends]: $($style, (s) => s.gridColumnEnd < 8),
                  },
                ]}
                style={$style}
              >
                <span class={styles.weekViewMonthLabel}>{$label}</span>
              </div>
            );
          },
        )}
      </div>
      <div class={styles.weekViewDays}>
        {repeat(
          $days,
          (day) => day.date.toISOString(),
          ($day) => {
            const $date = $($day, (day) => day.date);
            const $disabled = $($day, (day) => !day.selectable);
            const $label = $($day, (day) => day.label);
            const $selected = $($date, $$selectedDate, (date, selected) =>
              selected ? isSameDay(date, selected) : false,
            );
            const $today = $($date, clock.$hour, (date) => isSameDay(new Date(), date));

            return (
              <button
                class={[
                  styles.dayView,
                  {
                    [styles.weekend]: $($date, isWeekend),
                    [styles.selected]: $selected,
                    [styles.today]: $today,
                  },
                ]}
                disabled={$disabled}
                onClick={(e) => {
                  e.preventDefault();
                  $$selectedDate.set($date.get());
                }}
              >
                <div class={styles.dayLabel}>
                  <span class={styles.dayLabelWeekday}>
                    {$($date, $currentLanguage, (date, lang) => {
                      return new Intl.DateTimeFormat([lang!, "en-US"], { weekday: "short" }).format(date);
                    })}
                  </span>
                  <span class={styles.dayLabelDate}>{$($date, (date) => date.getDate())}</span>
                </div>
                {cond(
                  props.$dayMeta,
                  <div class={styles.dayContent}>
                    {cond($label, <span class={styles.dayItemCount}>{$label}</span>)}
                  </div>,
                )}
              </button>
            );
          },
        )}
      </div>
    </div>
  );
}
