import { $, cond, Readable, RouterStore, ViewContext } from "@manyducks.co/dolla";
import { Details } from "./Details/Details";
import { ProjectsStore } from "@stores/ProjectsStore";
import { Project } from "schemas";
import { sticky } from "@helpers/sticky";

export function ProjectOverview(props: {}, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const projects = ctx.getStore(ProjectsStore);
  const $projectId = $(router.$params, (params) => params.projectId);
  const $currentProject = $($projectId, projects.$cache, (id, projects) => projects.find((p) => p.id === id));
  const $latestProject = $($currentProject, sticky());

  return cond($latestProject, <Details $project={$latestProject as Readable<Project>} />);
}
