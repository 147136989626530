import { Task } from "schemas";

// TODO: Add sorting by scheduled date once we add that.
export function sortTasks(a: Task, b: Task) {
  if (a.dueDate && !b.dueDate) {
    return -1;
  } else if (!a.dueDate && b.dueDate) {
    return 1;
  } else if (a.dueDate && b.dueDate) {
    if (a.dueDate < b.dueDate) {
      return -1;
    } else if (a.dueDate > b.dueDate) {
      return 1;
    } else {
      return sortByPriority(a, b);
    }
  } else {
    return sortByPriority(a, b);
  }
}

function sortByPriority(a: Task, b: Task) {
  const aP = a.priority ?? 0;
  const bP = b.priority ?? 0;
  if (aP > bP) {
    return -1;
  } else if (aP < bP) {
    return +1;
  } else {
    return sortByCreatedAt(a, b);
  }
}

function sortByCreatedAt(a: Task, b: Task) {
  if (a.createdAt < b.createdAt) {
    return -1;
  } else if (a.createdAt > b.createdAt) {
    return +1;
  } else {
    return 0;
  }
}
