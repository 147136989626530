import { adjustAccentColor } from "@helpers/makeTheme";
import { $, $$, DialogStore, LanguageStore, RouterStore, cond, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { BreakpointStore } from "@stores/BreakpointStore";
import { ChatStore } from "@stores/ChatStore";
import { IOStore } from "@stores/IOStore";
import { LoaderStore } from "@stores/LoaderStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import Search from "@views/@icons/Search";
import { Chat } from "./Chat";
import { NavMenu } from "./NavMenu/NavMenu";
import styles from "./Workspace.module.css";
import { SearchMenu } from "@views/SearchMenu";
import { SearchDialog } from "@views/@dialogs";
import { NavStore } from "@stores/NavStore";
import { FileViewer } from "@views/FileViewer";

/**
 * Top level layout for the authenticated portions of the app.
 */
export function Workspace(_: {}, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);
  const io = ctx.getStore(IOStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const loader = ctx.getStore(LoaderStore);
  const notes = ctx.getStore(NotesStore);
  const tasks = ctx.getStore(TasksStore);
  const chat = ctx.getStore(ChatStore);
  const breakpoint = ctx.getStore(BreakpointStore);
  const theme = ctx.getStore(ThemeStore);
  const dialog = ctx.getStore(DialogStore);
  const nav = ctx.getStore(NavStore);

  // 0 = menu, 1 = main, 2 = chat
  const $$screenPosition = $$(1);

  const $$menuWidth = $$(Number(localStorage.getItem("ui.menuWidth") ?? 300));
  const $$chatWidth = $$(Number(localStorage.getItem("ui.chatWidth") ?? 300));

  const $$resizeTarget = $$<"menu" | "chat" | null>(null);

  ctx.observe($$menuWidth, (menuWidth) => {
    localStorage.setItem("ui.menuWidth", String(menuWidth));
  });

  ctx.observe($$chatWidth, (chatWidth) => {
    localStorage.setItem("ui.chatWidth", String(chatWidth));
  });

  const $currentLocationInfo = $(
    router.$pattern,
    router.$params,
    projects.$cache,
    notes.$cache,
    auth.$me,
    $currentLanguage,
    (pattern, params, projects, notes, me, _) => {
      interface Selection {
        selected: boolean;
        name: string;
        icon: string | undefined;
      }

      // A project or section in the nav menu.
      let location: Selection = {
        selected: false,
        name: translate("workspace.nav.mobile.noLocationLabel").get(),
        icon: undefined,
      };

      // A tab within that project or section.
      let tab: Selection = {
        selected: false,
        name: translate("workspace.nav.mobile.noTabLabel").get(),
        icon: undefined,
      };

      let color: string | undefined;

      if (pattern?.startsWith("/projects")) {
        const project = params.projectId
          ? projects.find((p) => p.id === Number(params.projectId))
          : undefined;

        location.selected = true;
        location.name = project ? project.name : "...";
        location.icon = "/icons/projects.12.trans.png";

        color = project?.color ?? "#888";

        if (pattern.startsWith("/projects/{#projectId}/tasks")) {
          tab.selected = true;
          tab.name = translate("workspace.project.tasks.tabName").get();
          tab.icon = "/icons/tasks.12.trans.png";
        } else if (pattern.startsWith("/projects/{#projectId}/notes")) {
          tab.selected = true;
          tab.icon = "/icons/notes.12.trans.png";
          if (params.noteId) {
            const note = notes.get(Number(params.noteId));
            tab.name = note ? note.title : translate("workspace.project.notes.tabName").get();
          } else {
            tab.name = translate("workspace.project.notes.tabName").get();
          }
        } else if (pattern.startsWith("/projects/{#projectId}")) {
          tab.selected = true;
          tab.name = translate("workspace.project.overview.tabName").get();
          tab.icon = "/icons/projects.12.trans.png";
        }
      } else if (pattern?.startsWith("/me")) {
        location.selected = true;
        location.name = translate("workspace.nav.me.label").get();
        location.icon = "/icons/quackstats.12.png";
        color = me?.color ?? "#888";

        if (pattern.startsWith("/me/tasks")) {
          tab.selected = true;
          tab.name = translate("workspace.me.tasks.tabName").get();
          tab.icon = "/icons/tasks.12.trans.png";
        } else if (pattern.startsWith("/me/settings")) {
          tab.selected = true;
          tab.name = translate("workspace.me.settings.tabName").get();
          tab.icon = "/icons/settings.12.trans.png";
        }
      } else if (pattern?.startsWith("/admin")) {
        location.selected = true;
        location.name = translate("workspace.nav.admin.label").get();
        location.icon = "/icons/quackstats.12.png";
        color = me?.color ?? "#888";

        if (pattern.startsWith("/admin/server-stats")) {
          tab.selected = true;
          tab.name = translate("workspace.admin.serverStats.tabName").get();
          tab.icon = "/icons/rooms.12.trans.png";
        }
      } else {
      }

      return { location, tab, color };
    },
  );
  const $chatProject = $(chat.$$projectId, projects.$cache, (projectId, projects) => {
    return projects.find((p) => p.id === projectId);
  });

  ctx.observe($currentLanguage, (language) => {
    ctx.log({ language });
    if (language) {
      localStorage.setItem("quack-current-language", language);
    }
  });

  const $$socketConnected = $$(false);
  const $$isLoaded = $$(false);

  ctx.beforeConnect(async () => {
    if (!auth.$isLoggedIn.get()) {
      router.navigate("/login");
      loader.hideAppLoader();
      return;
    }

    // Once user becomes logged out, drop them back at the landing page.
    ctx.observe(auth.$isLoggedIn, (isLoggedIn) => {
      if (!isLoggedIn) {
        router.navigate("/login");
      }
    });

    await auth.getUsers();

    const socket = io.socket();

    socket.on("connect", () => {
      auth.getUpdateStatus();
      $$socketConnected.set(true);
    });

    socket.on("disconnect", () => {
      $$socketConnected.set(false);
    });

    socket.on("task:update", tasks.taskUpdateReceived);
    socket.on("task:delete", tasks.taskDeleteReceived);

    socket.on("note:update", notes.noteUpdateReceived);
    socket.on("note:delete", notes.noteDeleteReceived);

    // socket.on("settings:update", auth.settingsUpdateReceived);

    socket.on("project:invited", projects.projectInviteReceived); // When a project invite is received
    socket.on("project:added", (projectId) => {}); // When user joins a project
    socket.on("project:update", projects.projectUpdateReceived);
    socket.on("project:delete", (projectId) => {
      projects.projectDeleteReceived(projectId);
      socket.emit("projects:refresh", () => {
        ctx.log("Projects refreshed");
      });
    });

    socket.on("user:update", auth.userUpdateReceived);

    socket.on("chat:message", chat.chatMessageReceived);

    await Promise.all([projects.refreshList(), chat.fetchMessages(), nav.fetchFavorites()]).then(() => {
      loader.hideAppLoader();
      $$isLoaded.set(true);
    });

    ctx.onDisconnected(() => {
      socket.close();
    });
  });

  ctx.observe($$socketConnected, async (socketConnected) => {
    if (!socketConnected || !$$isLoaded.get()) return;

    const promises: Promise<any>[] = [
      auth.getUsers(),
      projects.refreshList(),
      chat.fetchMessages(),
      nav.fetchFavorites(),
    ];

    // remove deleted resources
    const caches: {
      users: number[];
      projects: number[];
      notes: number[];
      tasks: number[];
    } = {
      users: auth.$users.get().map((x) => x.id),
      projects: projects.$cache.get().map((x) => x.id),
      notes: [],
      tasks: [],
    };

    const pattern = router.$pattern.get();
    const params = router.$params.get();

    const inNotes = pattern?.startsWith("/projects/{#projectId}/notes");
    const inTasks = pattern?.startsWith("/projects/{#projectId}/tasks");

    if (inNotes) {
      caches.notes = [...notes.$cache.get().keys()];
    } else if (inTasks) {
      caches.tasks = [...tasks.$cache.get().keys()];
    }

    const deletedCaches = await auth.validateCache(caches);

    auth.updateCache(deletedCaches.users);
    projects.updateCache(deletedCaches.projects);
    notes.updateCache(deletedCaches.notes);
    tasks.updateCache(deletedCaches.tasks);

    // load latest resources
    if (inNotes) {
      promises.push(notes.fetchIndexFor(Number(params.projectId), true));
    } else if (inTasks) {
      promises.push(tasks.fetchIndexFor(Number(params.projectId), true));
    } else if (pattern?.startsWith("/me/tasks")) {
      promises.push(tasks.fetchToDos());
    }

    await Promise.all(promises);

    ctx.log("Socket reconnected: data refreshed");
  });

  // check for updates
  const onVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      // clear notifications
      navigator.serviceWorker.ready.then((reg) => {
        reg.getNotifications().then((notifications) => {
          for (let i = 0; i < notifications.length; i += 1) {
            notifications[i].close();
          }
        });
      });

      // check for update
      const updateAvailable = await auth.getUpdateStatus();
      console.log(`UPDATE AVAILABLE: ${updateAvailable}`);
    }
  };

  const $showNavBar = $(breakpoint.$lg, (lg) => !lg);

  ctx.onConnected(() => {
    window.addEventListener("visibilitychange", onVisibilityChange);
  });

  ctx.onDisconnected(() => {
    window.removeEventListener("visibilitychange", onVisibilityChange);
  });

  return cond(
    $$isLoaded,
    <div
      class={styles.workspace}
      // style={{
      //   // bottom: $($$bottomOffset, (offset) => `${offset}px`),
      //   height: $($$viewportHeight, (height) => `${height}px`),
      // }}
      onPointerMove={(e) => {
        const target = $$resizeTarget.get();
        if (!target) return;

        e.preventDefault();
        if (target === "menu") {
          $$menuWidth.set(Math.min(500, Math.max(240, e.clientX)));
        } else if (target === "chat") {
          $$chatWidth.set(Math.min(500, Math.max(300, window.innerWidth - e.clientX)));
        }
      }}
      onPointerUp={() => {
        $$resizeTarget.set(null);
      }}
    >
      <div class={styles.layout}>
        <div
          class={styles.layoutContent}
          style={{
            transform: $($$screenPosition, breakpoint.$lg, (pos, lg) =>
              lg ? "translateX(0)" : `translateX(-${pos * 33.3333}%)`,
            ),
          }}
        >
          <div
            class={styles.nav}
            style={{
              flexBasis: $(breakpoint.$lg, $$menuWidth, (lg, width) => (lg ? `${width}px` : "100vw")),
            }}
          >
            <div
              class={styles.navToolbar}
              style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
            >
              <button
                class={styles.searchBarContainer}
                onClick={() => {
                  dialog.open(SearchDialog);
                }}
              >
                <div class={styles.searchBarIcon}>
                  <Search />
                </div>
                <span class={styles.searchBarLabel}>
                  {translate("workspace.nav.search.inputPlaceholder")}
                </span>
                {/* <input
                  type="text"
                  class={styles.searchBar}
                  placeholder={translate("workspace.nav.search.inputPlaceholder")}
                ></input> */}
              </button>
            </div>
            <NavMenu
              onNavigate={() => {
                ctx.log("navigate");
                $$screenPosition.set(1);
              }}
            />
          </div>

          <div class={styles.main}>{ctx.outlet()}</div>

          <div
            class={styles.chat}
            style={{
              flexBasis: $(breakpoint.$lg, $$chatWidth, (lg, width) => (lg ? `${width}px` : "100vw")),
            }}
          >
            <Chat
              onShowSettings={() => {
                $$screenPosition.set(1);
              }}
            />
          </div>
        </div>
      </div>

      <nav
        class={styles.mobileNavBar}
        aria-hidden={$($showNavBar, (show) => !show)}
        style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}
      >
        <div
          class={styles.mobileNavHighlight}
          style={$(
            $$screenPosition,
            $chatProject,
            $currentLocationInfo,
            theme.$isDark,
            breakpoint.$width,
            (position, project, info, dark, width) => {
              let highlightColor = "#888";

              if (position === 0) {
                highlightColor = adjustAccentColor(info?.color ?? "#888", dark);
              } else if (position === 1) {
                highlightColor = adjustAccentColor(info?.color ?? "#888", dark);
              } else if (position === 2) {
                highlightColor = adjustAccentColor(project?.color ?? "#888", dark);
              }

              return {
                transform: `translateX(${position * (width * 0.3333)}px)`,
                "--color-highlight": highlightColor,
              };
            },
          )}
        />
        <ul>
          <li style={theme.getThemeVariables$($($currentLocationInfo, (l) => l.color))}>
            <button
              class={{
                [styles.mobileNavButton]: true,
                [styles.active]: $($$screenPosition, (p) => p === 0),
              }}
              onClick={() => {
                $$screenPosition.set(0);
              }}
            >
              <div class={styles.mobileNavButtonIcon}>
                <img
                  src={$($currentLocationInfo, (l) => l.location.icon ?? "/icons/projects.12.trans.png")}
                  alt=""
                />
              </div>
              <span
                class={{
                  [styles.mobileNavButtonLabel]: true,
                  [styles.noSelection]: $($currentLocationInfo, (l) => !l.location.selected),
                }}
              >
                {$($currentLocationInfo, (l) => l.location.name)}
              </span>
            </button>
          </li>
          <li style={theme.getThemeVariables$($($currentLocationInfo, (l) => l.color))}>
            <button
              class={{
                [styles.mobileNavButton]: true,
                [styles.active]: $($$screenPosition, (p) => p === 1),
              }}
              onClick={() => {
                $$screenPosition.set(1);
              }}
            >
              <div class={styles.mobileNavButtonIcon}>
                <img
                  src={$($currentLocationInfo, (l) => l.tab.icon ?? "/icons/projects.12.trans.png")}
                  alt=""
                />
              </div>
              <span
                class={{
                  [styles.mobileNavButtonLabel]: true,
                  [styles.noSelection]: $($currentLocationInfo, (l) => !l.tab.selected),
                }}
              >
                {$($currentLocationInfo, (l) => l.tab.name)}
              </span>
            </button>
          </li>
          <li style={theme.getThemeVariables$($($chatProject, (p) => p?.color))}>
            <button
              class={{
                [styles.mobileNavButton]: true,
                [styles.active]: $($$screenPosition, (p) => p === 2),
              }}
              onClick={() => {
                $$screenPosition.set(2);
              }}
            >
              <div class={styles.mobileNavButtonIcon}>
                <img src="/icons/chat.12.trans.png" alt="" />
              </div>
              <span
                class={{
                  [styles.mobileNavButtonLabel]: true,
                  [styles.noSelection]: $($chatProject, (p) => p == null),
                }}
              >
                {$(
                  $chatProject,
                  translate("workspace.nav.mobile.noChatSelectedLabel"),
                  (p, defaultLabel) => p?.name ?? defaultLabel,
                )}
              </span>
            </button>
          </li>
        </ul>
      </nav>

      {cond(nav.$fileViewerIsOpen, <FileViewer />)}

      <div
        class={[styles.grabber]}
        style={{ left: $(breakpoint.$lg, $$menuWidth, (lg, width) => (lg ? `${width - 5}px` : "100vw")) }}
        onPointerDown={(e) => {
          const target = $$resizeTarget.get();
          if (target == null) {
            e.preventDefault();
            $$resizeTarget.set("menu");
          }
        }}
      />

      <div
        class={[styles.grabber]}
        style={{ right: $(breakpoint.$lg, $$chatWidth, (lg, width) => (lg ? `${width - 5}px` : "100vw")) }}
        onPointerDown={(e) => {
          const target = $$resizeTarget.get();
          if (target == null) {
            e.preventDefault();
            $$resizeTarget.set("chat");
          }
        }}
      />
    </div>,
  );
}
