import { sticky } from "@helpers/sticky";
import { $, $$, LanguageStore, Readable, ViewContext } from "@manyducks.co/dolla";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import ChevronLeft from "@views/@icons/ChevronLeft";
import ChevronRight from "@views/@icons/ChevronRight";
import { Button } from "@views/Button";
import { IconButton } from "@views/IconButton";
import { Select } from "@views/Select";
import { ToolBar, ToolBarGroup } from "@views/ToolBar";
import styles from "./Calendar.module.css";
import { CalendarView } from "./CalendarView/CalendarView";
import { CardDetailStack } from "@views/CardDetailStack";
import { DayDetails } from "./DayDetails/DayDetails";
import { AuthStore } from "@stores/AuthStore";

interface CalendarProps {}

export function Calendar(props: CalendarProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);

  const $$year = $$(new Date().getFullYear());
  const $$month = $$(new Date().getMonth() + 1);

  const $$selectedDate = $$<Date>();

  const $selectableYears = $(projects.$cache, (projects) => {
    const years = [];
    let cursor = new Date(
      projects.reduce(
        (earliest, p) => (p.createdAt < earliest ? p.createdAt : earliest),
        new Date().toISOString(),
      ),
    ).getFullYear();
    let max = new Date().getFullYear() + 2;
    // From the year the project was created to 2 years in the future.
    while (cursor <= max) {
      years.push(cursor);
      cursor += 1;
    }
    return years;
  });

  ctx.observe(
    $(auth.$me, $$month, $$year, (me, month, year) => ({ me, month, year })),
    ({ me, month, year }) => {
      tasks.fetchCalendar(year, month, {
        assignedUserId: me!.id,
        withAdjacent: true,
      });
    },
  );

  return (
    <div class={styles.layout}>
      <CardDetailStack
        showDetail={$($$selectedDate, (x) => x != null)}
        listContent={
          <div class={styles.listContent}>
            <div class={styles.topControls}>
              <ToolBar>
                <ToolBarGroup>
                  <Select
                    value={$$month}
                    options={[
                      { label: getMonthName$(ctx, 1), value: 1 },
                      { label: getMonthName$(ctx, 2), value: 2 },
                      { label: getMonthName$(ctx, 3), value: 3 },
                      { label: getMonthName$(ctx, 4), value: 4 },
                      { label: getMonthName$(ctx, 5), value: 5 },
                      { label: getMonthName$(ctx, 6), value: 6 },
                      { label: getMonthName$(ctx, 7), value: 7 },
                      { label: getMonthName$(ctx, 8), value: 8 },
                      { label: getMonthName$(ctx, 9), value: 9 },
                      { label: getMonthName$(ctx, 10), value: 10 },
                      { label: getMonthName$(ctx, 11), value: 11 },
                      { label: getMonthName$(ctx, 12), value: 12 },
                    ]}
                    onChange={(value) => {
                      $$month.set(value);
                    }}
                  />
                  {$($selectableYears, (years) => {
                    return (
                      <Select
                        value={$$year}
                        options={years.map((y) => ({ label: y.toString(), value: y }))}
                        onChange={(value) => {
                          $$year.set(value);
                        }}
                      />
                    );
                  })}
                </ToolBarGroup>

                <ToolBarGroup>
                  <IconButton
                    onClick={() => {
                      if ($$month.get() == 1) {
                        $$month.set(12);
                        $$year.update((y) => y - 1);
                      } else {
                        $$month.update((m) => m - 1);
                      }
                    }}
                  >
                    <ChevronLeft />
                  </IconButton>
                  <Button
                    onClick={() => {
                      const now = new Date();
                      $$year.set(now.getFullYear());
                      $$month.set(now.getMonth() + 1);
                    }}
                  >
                    {translate("workspace.project.tasks.calendar.todayButtonText")}
                  </Button>
                  <IconButton
                    onClick={() => {
                      if ($$month.get() == 12) {
                        $$month.set(1);
                        $$year.update((y) => y + 1);
                      } else {
                        $$month.update((m) => m + 1);
                      }
                    }}
                  >
                    <ChevronRight />
                  </IconButton>
                </ToolBarGroup>
              </ToolBar>
            </div>

            <div class={styles.calendar}>
              <CalendarView
                $month={$$month}
                $year={$$year}
                $selectedDate={$$selectedDate}
                onDaySelected={(date) => {
                  $$selectedDate.set(date);
                }}
              />
            </div>
          </div>
        }
        detailContent={
          <DayDetails
            $selectedDate={$$selectedDate}
            onBackButtonClicked={() => {
              $$selectedDate.set(undefined);
            }}
          />
        }
      />
    </div>
  );
}

function getMonthName$(ctx: ViewContext, month: number): Readable<string> {
  const { $currentLanguage } = ctx.getStore(LanguageStore);
  return $($currentLanguage, (lang) => {
    const formatter = new Intl.DateTimeFormat([lang!, "en-US"], { month: "short" });
    const date = new Date(2024, month - 1, 1);
    return formatter.format(date);
  });
}
