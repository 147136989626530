import { $$, $, HTTPStore, type StoreContext, type Readable } from "@manyducks.co/dolla";
import { differenceInMinutes, subMinutes, isBefore } from "date-fns";
import { extractTitleFromDelta } from "@helpers/extractTitleFromDelta";
import { produce } from "immer";
import { DeltaOperation } from "quill";
import { type Note } from "schemas";
import * as Y from "yjs";

export interface ProjectTagMap {
  [projectId: number]: {
    lastFetchedAt: Date;
    tags: {
      [tag: string]: number;
    };
  };
}

interface CreateNoteOptions {
  projectId: number;
  delta?: DeltaOperation[];
  tags?: string[];
}

export function NotesStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);

  const $$cache = $$(new Map<number, Note>());

  // Keys are project IDs
  const indexCacheMap = new Map<number | null, { lastFetchedAt: Date; resultIds: number[] }>();

  /**
   * Returns the wiki index for a given project.
   */
  async function fetchIndexFor(projectId: number | null, fresh = false) {
    const now = new Date();
    const mapped = indexCacheMap.get(projectId);

    if (!fresh && mapped && differenceInMinutes(now, mapped.lastFetchedAt) < 5) {
      const cache = $$cache.get();
      const results: Note[] = [];
      for (const id of mapped.resultIds) {
        results.push(cache.get(id)!);
      }
      return results;
    }

    return http
      .get<Note[]>(projectId == null ? `/api/notes/activity` : `/api/notes?projectId=${projectId}`)
      .then(async (res) => {
        $$cache.update(
          produce((notes) => {
            for (const note of res.body) {
              const current = notes.get(note.id);
              if (current) {
                Object.assign(current, note);
              } else {
                notes.set(note.id, note);
              }
            }
          }),
        );

        indexCacheMap.set(projectId, {
          lastFetchedAt: now,
          resultIds: res.body.map((note) => note.id),
        });

        return res.body;
      });
  }

  async function createNote(options: CreateNoteOptions) {
    return http.post<Note>(`/api/notes`, { body: options }).then(async (res) => {
      ctx.log(res);

      // Received from socket with note:update event

      // $$cache.update(
      //   produce((pages) => {
      //     pages.push(res.body);
      //   })
      // );

      return res.body;
    });
  }

  async function ensureNoteIsLoaded(noteId: number) {
    const found = $$cache.get().get(noteId);
    if (!found) {
      try {
        const res = await http.get<Note>(`/api/notes/${noteId}`);
        $$cache.update(
          produce((notes) => {
            notes.set(res.body.id, res.body);
          }),
        );
        return { exists: true };
      } catch (err) {
        ctx.error(err);
      }
    }
    return { exists: found != null };
  }

  /**
   * Update locally cached pages. Actual changes to the page have already been
   * sent to the server over sockets by the time this has been called.
   */
  const updatePage = async (pageId: number, ytext: Y.Text) => {
    $$cache.update(
      produce((pages) => {
        const page = pages.get(pageId);
        if (page) {
          page.title = extractTitleFromDelta(ytext.toDelta());
          // page.wordCount = ytext.toString().split(/[\s\t\n]+/g).length;
        }
      }),
    );

    ctx.log("updated local cache for page", pageId);
  };

  const deletePage = async (pageId: number) => {
    $$cache.update(
      produce((pages) => {
        pages.delete(pageId);
      }),
    );

    return http.delete(`/api/notes/${pageId}`).then(async (res) => {
      ctx.log(res);
    });
  };

  async function setPinned(noteId: number, pinned: boolean) {
    $$cache.update(
      produce((notes) => {
        const note = notes.get(noteId);
        if (note) {
          note.isPinned = pinned;
        }
      }),
    );

    if (pinned) {
      await http.put(`/api/notes/${noteId}/pin`);
    } else {
      await http.delete(`/api/notes/${noteId}/pin`);
    }
  }

  async function setTags(noteId: number, tags: string[]) {
    $$cache.update(
      produce((notes) => {
        const found = notes.get(noteId);
        if (found) {
          found.tags = tags;
        }
      }),
    );

    await http.put(`/api/notes/${noteId}/tags`, {
      body: {
        tags,
      },
    });
  }

  function updateCache(cache: Array<number>) {
    if (cache.length > 0) {
      $$cache.update(
        produce((notes) => {
          for (const id of cache) {
            notes.delete(id);
          }
        }),
      );
    }
  }

  return {
    $cache: $($$cache),

    fetchIndexFor,
    ensureNoteIsLoaded,
    createNote,
    updatePage,
    deletePage,
    setPinned,
    setTags,
    updateCache,

    noteUpdateReceived: (note: Note) => {
      $$cache.update(
        produce((cache) => {
          const current = cache.get(note.id);

          if (current) {
            Object.assign(current, note);
          } else {
            cache.set(note.id, note);
          }
        }),
      );
    },

    noteDeleteReceived: (id: number) => {
      $$cache.update(
        produce((cache) => {
          cache.delete(id);
        }),
      );
    },
  };
}
