export default {
  "container": "Details_container_e83cb",
  "loader": "Details_loader_e83cb",
  "show": "Details_show_e83cb",
  "meta": "Details_meta_e83cb",
  "metaButton": "Details_metaButton_e83cb",
  "active": "Details_active_e83cb",
  "open": "Details_open_e83cb",
  "metaButtonIcon": "Details_metaButtonIcon_e83cb",
  "metaButtonLabel": "Details_metaButtonLabel_e83cb",
  "metaContent": "Details_metaContent_e83cb",
  "metaSection": "Details_metaSection_e83cb",
  "metaSubSection": "Details_metaSubSection_e83cb",
  "metaAttachmentsList": "Details_metaAttachmentsList_e83cb",
  "metaAttachmentUpload": "Details_metaAttachmentUpload_e83cb",
  "metaAttachmentUploadInput": "Details_metaAttachmentUploadInput_e83cb",
  "metaAttachmentUploadLabel": "Details_metaAttachmentUploadLabel_e83cb",
  "metaCheckbox": "Details_metaCheckbox_e83cb",
  "metaCheckboxLabel": "Details_metaCheckboxLabel_e83cb",
  "timestampList": "Details_timestampList_e83cb",
  "timestampListItem": "Details_timestampListItem_e83cb",
  "timestampListItemMain": "Details_timestampListItemMain_e83cb",
  "timestampListItemSecondary": "Details_timestampListItemSecondary_e83cb",
  "content": "Details_content_e83cb",
  "editToggleGroup": "Details_editToggleGroup_e83cb"
};import "ni:sha-256;JXmQgyCkPMyofXhFX5R3MVee9GEuEAqofeQ92sQRevQ";