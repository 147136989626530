import { $, $$, HTTPStore, StoreContext } from "@manyducks.co/dolla";
import { Favorite, File } from "schemas";

export function NavStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);

  /*=================================*\
  |*            Favorites            *|
  \*=================================*/

  const $$favorites = $$<Favorite[]>([]);

  async function fetchFavorites() {
    const res = await http.get<Favorite[]>("/api/favorites");
    $$favorites.set(res.body);
  }

  async function addFavorite(path: string) {
    let highestOrder = 0;
    for (const favorite of $$favorites.get()) {
      if (favorite.order > highestOrder) {
        highestOrder = favorite.order;
      }
    }
    const res = await http.post<Favorite>("/api/favorites", { body: { path } });
    $$favorites.update((current) => [...current, res.body]);

    ctx.log("addFavorite", path, $$favorites.get());
  }

  async function removeFavorite(id: number) {
    $$favorites.update((current) => current.filter((f) => f.id !== id));

    await http.delete(`/api/favorites/${id}`);
  }

  async function reorderFavorites(favorites: Favorite[]) {
    localStorage.setItem("favorites", JSON.stringify($$favorites.get()));
  }

  /*=================================*\
  |*           File Viewer           *|
  \*=================================*/

  const $$fileViewerIsOpen = $$(false);
  const $$fileViewerContent = $$<File>();

  function openFileViewer(file: File) {
    $$fileViewerContent.set(file);
    $$fileViewerIsOpen.set(true);
  }

  function closeFileViewer() {
    $$fileViewerIsOpen.set(false);
  }

  return {
    $favorites: $($$favorites),
    fetchFavorites,
    addFavorite,
    removeFavorite,
    reorderFavorites,

    $fileViewerIsOpen: $($$fileViewerIsOpen, $$fileViewerContent, (open, content) => open && content != null),
    $fileViewerContent: $($$fileViewerContent),
    openFileViewer,
    closeFileViewer,
  };
}
