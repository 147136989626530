import {
  $,
  $$,
  LanguageStore,
  unwrap,
  type DialogProps,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import styles from "./CreateProjectDialog.module.css";
import { ColorInput } from "@views/ColorInput";
import { TextInput } from "@views/TextInput";

interface CreateProjectInfo {
  color: string;
  name: string;
}

interface CreateProjectDialogProps extends DialogProps {
  accentColor: string | Readable<string>;

  /**
   * Called when confirmation is successful
   */
  onConfirm: (info: CreateProjectInfo) => Promise<void>;
}

/**
 * A dialog to confirm permanent deletion of some data.
 */
export function CreateProjectDialog(props: CreateProjectDialogProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  const $$open = $$(props.$$open);
  const onConfirm = unwrap(props.onConfirm);

  const $$color = $$("#ff0088");
  const $$name = $$("");

  const $$isWaiting = $$(false);

  return (
    <Dialog
      accentColor={$(props.accentColor, $$color, (defaultColor, chosenColor) => chosenColor || defaultColor)}
      onsubmit={async () => {
        $$isWaiting.set(true);
        onConfirm({
          color: $$color.get(),
          name: $$name.get(),
        })
          .then(() => {
            $$open.set(false);
          })
          .finally(() => {
            $$isWaiting.set(false);
          });
      }}
      transitionIn={props.transitionIn}
      transitionOut={props.transitionOut}
    >
      <DialogHeader $$open={$$open} title={translate("workspace.nav.projects.createProjectDialog.title")} />

      <DialogContent>
        <div class={styles.content}>
          <div class={styles.formGroup}>
            <label for="project-name">
              {translate("workspace.nav.projects.createProjectDialog.nameLabel")}
            </label>
            <TextInput
              id="project-name"
              value={$$name}
              // ref={$$textInput}
              placeholder={translate("workspace.nav.projects.createProjectDialog.nameInputPlaceholder")}
            />
          </div>

          <div class={styles.formGroup}>
            <label for="project-color">
              {translate("workspace.nav.projects.createProjectDialog.colorLabel")}
            </label>
            <ColorInput id="project-color" value={$$color} />
          </div>
        </div>
      </DialogContent>

      <DialogFooter>
        <Button type="submit" disabled={$($$name, (v) => v.trim().length === 0)}>
          {translate("workspace.nav.projects.createProjectDialog.submitButtonText")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
