import { $, type Readable, type Renderable, type ViewContext } from "@manyducks.co/dolla";
import { adjustAccentColor, getTextColorForBackground } from "@helpers/makeTheme";
import { ThemeStore } from "@stores/ThemeStore";
import styles from "./Badge.module.css";

interface BadgeProps {
  label: string | Readable<string>;
  icon: Renderable;
  color?: string;
}

export function Badge(props: BadgeProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);
  const $color = $(props.color);

  const $style = $($color, theme.$isDark, (color, dark) => {
    const styles: any = {};

    if (color) {
      const adjusted = adjustAccentColor(color, dark);
      styles["--color-badge"] = adjusted;
      styles["--color-badge-text"] = getTextColorForBackground(adjusted);
    }

    return styles;
  });

  return (
    <div class={styles.badge} title={props.label} style={$style}>
      {props.icon}
      <span>{props.label}</span>
    </div>
  );
}
