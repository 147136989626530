import {
  $,
  $$,
  DialogStore,
  LanguageStore,
  RouterStore,
  type DialogProps,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { SearchMenu } from "@views/SearchMenu";
import { TextInput } from "@views/TextInput";
import styles from "./SearchDialog.module.css";
import { TaskEditDialog } from "@views/TaskListItem/TaskEditDialog/TaskEditDialog";
import { ProjectsStore } from "@stores/ProjectsStore";

interface SearchDialog extends DialogProps {}

/**
 * A dialog to confirm permanent deletion of some data.
 */
export function SearchDialog(props: SearchDialog, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const router = ctx.getStore(RouterStore);
  const dialog = ctx.getStore(DialogStore);
  const projects = ctx.getStore(ProjectsStore);
  const { $me } = ctx.getStore(AuthStore);
  const $avatar = $($me, (user) => user?.avatar ?? "");

  const $$inputValue = $$("");
  const $$open = $$(props.$$open);
  // const { onSave } = props;

  // const $$inputElement = $$<HTMLInputElement>();

  // ctx.onConnected(() => {
  //   $$inputElement.get()!.select();
  // });

  return (
    <Dialog
      transitionIn={props.transitionIn}
      transitionOut={props.transitionOut}
      onsubmit={() => {
        // onSave($$inputValue.get());
        $$open.set(false);
      }}
      accentColor={$($me, (m) => m?.color ?? "#888")}
    >
      <DialogHeader $$open={$$open} title={translate("views.searchMenu.dialogTitle")} icon={$avatar} />

      <DialogContent>
        <div class={styles.layout}>
          <SearchMenu
            onSelected={(result) => {
              $$open.set(false);

              switch (result.type) {
                case "task":
                  router.navigate(`/projects/${result.data.projectId}/tasks`);
                  setTimeout(() => {
                    const project = projects.$cache.get().find((p) => p.id === result.data.projectId);
                    dialog.open(TaskEditDialog, {
                      projectId: result.data.projectId,
                      task: result.data,
                      color: project?.color,
                    });
                  }, 300);
                  break;
                case "note":
                  router.navigate(`/projects/${result.data.projectId}/notes/${result.data.id}`);
                  break;
                default:
                  break;
              }

              ctx.log("Selected", result);
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
