import chroma from "chroma-js";

/**
 * Adjust accent color to be visible on a light or dark background.
 */
export function adjustAccentColor(color: string, dark = false) {
  let accentColor = chroma(color);

  if (dark) {
    if (accentColor.luminance() < 0.25) {
      accentColor = accentColor.luminance(0.25);
    }
  } else {
    if (accentColor.luminance() > 0.3) {
      accentColor = accentColor.luminance(0.3);
    }
  }

  return accentColor.hex();
}

/**
 * Returns an appropriate text color readable over a background of `color`.
 */
export function getTextColorForBackground(color: string | number | chroma.Color) {
  return chroma(color).luminance() > 0.5 ? "#000" : "#fff";
}

/**
 * Generates variables for a complete color scheme.
 */
export function makeTheme(color: string, dark = false) {
  const baseColor = chroma(dark ? "#000" : "#fff");
  const accentColor = chroma(adjustAccentColor(color, dark));

  // const accentContrast = accentColor.set("hsl.h", accentColor.get("hsl.h") - 180);
  const destructiveColor = chroma(dark ? "#f44" : "#c33");

  const accentOne = accentColor.luminance(dark ? 0.2 : 0.3).hex();

  return {
    accentContrast: getTextColorForBackground(accentOne),
    accent: [
      accentColor.hex(),
      accentOne,
      accentColor.luminance(dark ? 0.08 : 0.65).hex(),
      accentColor.luminance(dark ? 0.06 : 0.77).hex(),
      accentColor.luminance(dark ? 0.04 : 0.88).hex(),
      accentColor.luminance(dark ? 0.015 : 0.92).hex(),
    ],
    accentNeutral: dark ? "#aaa9b2" : "#867e78",
    grayscale: [
      // Slight sepia-tone light; pitch black OLED-friendly dark
      dark ? "#000" : "#fff",
      dark ? "#000" : "#fbf9f6",
      dark ? "#222222" : "#ede9e1",
      dark ? "#555555" : "#e6e1d8",
      dark ? "#666666" : "#d8d3c7",
    ],
    // Accent color shades for UI controls (a bit brighter and more neon in dark mode)
    controlContrast: dark ? "#fff" : "#fff",
    controlAccent: [
      accentColor.luminance(dark ? 0.2 : 0.4).hex(),
      accentColor.luminance(dark ? 0.25 : 0.35).hex(),
      accentColor.luminance(dark ? 0.07 : 0.72).hex(),
      accentColor.luminance(dark ? 0.3 : 0.85).hex(),
      accentColor.luminance(dark ? 0.35 : 0.8).hex(),
    ],
    text: getTextColorForBackground(baseColor),
    // textSecondary: dark ? "#888" : "#aba2a2",
    textSecondary: chroma(getTextColorForBackground(baseColor))
      .alpha(dark ? 0.6 : 0.44)
      .hex(),
    border: dark ? "#555" : "#d4cac4",
    destructiveContrast: getTextColorForBackground(destructiveColor),
    destructive: [
      destructiveColor.hex(),
      destructiveColor.luminance(dark ? 0.31 : 0.13).hex(),
      destructiveColor.luminance(dark ? 0.02 : 0.72).hex(),
    ],
  };
}

/**
 * Returns a set of CSS variables for a generated theme.
 */
export function makeThemeVariables(theme: ReturnType<typeof makeTheme>) {
  return {
    "--color-grayscale-0": theme.grayscale[0],
    "--color-grayscale-1": theme.grayscale[1],
    "--color-grayscale-2": theme.grayscale[2],
    "--color-grayscale-3": theme.grayscale[3],
    "--color-grayscale-4": theme.grayscale[4],
    "--color-accent-contrast": theme.accentContrast,
    "--color-accent-0": theme.accent[0],
    "--color-accent-1": theme.accent[1],
    "--color-accent-2": theme.accent[2],
    "--color-accent-3": theme.accent[3],
    "--color-accent-4": theme.accent[4],
    "--color-accent-5": theme.accent[5],
    "--color-control-contrast": theme.controlContrast,
    "--color-control-accent-0": theme.controlAccent[0],
    "--color-control-accent-1": theme.controlAccent[1],
    "--color-control-accent-2": theme.controlAccent[2],
    "--color-control-accent-3": theme.controlAccent[3],
    "--color-control-accent-4": theme.controlAccent[4],
    "--color-text": theme.text,
    "--color-text-secondary": theme.textSecondary,
    "--color-border": theme.border,
    "--color-destructive-contrast": theme.destructiveContrast,
    "--color-destructive-0": theme.destructive[0],
    "--color-destructive-1": theme.destructive[1],
    "--color-destructive-2": theme.destructive[2],
    // "--color-destructive-3": theme.destructive[3],
    "--color-input-border": theme.grayscale[4],
    "--color-input-border-focused": theme.accent[0],
  };
}
