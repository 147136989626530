import { $$, $ } from "@manyducks.co/dolla";

export type DuckLoaderState = ReturnType<typeof makeLoaderState>;

/**
 * Initializes loader state. The states must be passed to the CardLoader view, and the methods are used
 * for controlling the loader. This generally would go in a local store for the card.
 */
export function makeLoaderState(initialValue = true) {
  const $$visible = $$(initialValue);

  async function show() {
    return new Promise<void>((resolve) => {
      if (!$$visible.get()) {
        $$visible.set(true);
        resolve();
        // setTimeout(() => {
        //   resolve();
        // }, 350);
      } else {
        return resolve();
      }
    });
  }

  async function hide() {
    return new Promise<void>((resolve) => {
      if ($$visible.get()) {
        $$visible.set(false);
        setTimeout(() => {
          resolve();
        }, 350);
      } else {
        return resolve();
      }
    });
  }

  return {
    $visible: $($$visible),
    show,
    hide,
  };
}
