export default {
  "container": "Dialog_container_65a35",
  "open": "Dialog_open_65a35",
  "backdrop": "Dialog_backdrop_65a35",
  "form": "Dialog_form_65a35",
  "modal": "Dialog_modal_65a35",
  "header": "Dialog_header_65a35",
  "headerIcon": "Dialog_headerIcon_65a35",
  "headerText": "Dialog_headerText_65a35",
  "title": "Dialog_title_65a35",
  "subtitle": "Dialog_subtitle_65a35",
  "withSubtitle": "Dialog_withSubtitle_65a35",
  "content": "Dialog_content_65a35",
  "controls": "Dialog_controls_65a35",
  "modalButton": "Dialog_modalButton_65a35",
  "buttons": "Dialog_buttons_65a35"
};import "ni:sha-256;RHFeKECi-UKuOTH8oWZj21VJeHhBhvkf_EvpS1FnlE0";