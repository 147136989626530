import { $, LanguageStore, type Readable, type ViewContext } from "@manyducks.co/dolla";
import { type ProjectInvite } from "schemas";
import { ThemeStore } from "@stores/ThemeStore";
import ProjectAdmin from "@views/@icons/ProjectAdmin";
import ProjectCollaborator from "@views/@icons/ProjectCollaborator";
import ProjectViewer from "@views/@icons/ProjectViewer";
import { Badge } from "@views/Badge";
import { Button } from "@views/Button";
import styles from "./ProjectInviteListItem.module.css";

interface ProjectInviteListItemProps {
  invite: ProjectInvite | Readable<ProjectInvite>;
  onAccept: () => void;
  onDecline: () => void;
}

export function ProjectInviteListItem(props: ProjectInviteListItemProps, ctx: ViewContext) {
  const theme = ctx.getStore(ThemeStore);
  const { translate } = ctx.getStore(LanguageStore);
  const $invite = $(props.invite);

  const $theme = theme.getThemeVariables$($($invite, (i) => i.project.color));
  const $role = $($invite, (i) => i.role);

  return (
    <li class={styles.container} style={$theme}>
      <div class={styles.projectRow}>
        <span class={styles.projectName}>{$($invite, (i) => i.project.name)}</span>
        <Badge
          icon={$($role, (role) => {
            switch (role) {
              case "admin":
                return <ProjectAdmin />;
              case "collaborator":
                return <ProjectCollaborator />;
              case "viewer":
                return <ProjectViewer />;
            }
          })}
          label={$($role, (role) => translate(`workspace.project.roles.${role}.title`))}
        />
      </div>
      <div class={styles.fromUser}>
        <span>
          {$($invite, (i) => i.sender.name)} ({$($invite, (i) => i.sender.email)})
        </span>
      </div>
      <div class={styles.buttons}>
        <Button onClick={() => props.onDecline()}>
          {translate("workspace.nav.projectInvites.declineButtonText")}
        </Button>
        <Button onClick={() => props.onAccept()}>
          {translate("workspace.nav.projectInvites.acceptButtonText")}
        </Button>
      </div>
    </li>
  );
}
