import { sticky } from "@helpers/sticky";
import { $, RouterStore, type ViewContext } from "@manyducks.co/dolla";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { CardDetailStack } from "@views/CardDetailStack";
import { Details } from "./Details/Details";
import { List } from "./List/List";
import styles from "./ProjectNotes.module.css";

interface ProjectNotesProps {}

export function ProjectNotes(props: ProjectNotesProps, ctx: ViewContext) {
  ctx.name = "ProjectNotes";

  const router = ctx.getStore(RouterStore);
  const notes = ctx.getStore(NotesStore);
  const projects = ctx.getStore(ProjectsStore);

  const $projectId = $(router.$params, (p) => (p.projectId ? Number(p.projectId) : null));
  const $currentProject = $($projectId, projects.$cache, (id, projects) => projects.find((p) => p.id === id));
  const $latestProject = $($currentProject, sticky());

  const $noteId = $(router.$params, (p) => (p.noteId ? Number(p.noteId) : null));

  ctx.observe($projectId, (projectId) => {
    if (projectId != null) {
      notes.fetchIndexFor(projectId);
    }
  });

  return (
    <div class={styles.layout}>
      <CardDetailStack
        showDetail={$($noteId, (noteId) => noteId != null)}
        listContent={<List />}
        detailContent={<Details $project={$latestProject} $noteId={$noteId} />}
      />
    </div>
  );
}
