import { extractTitleFromDelta } from "@helpers/extractTitleFromDelta";
import { sortTasks } from "@helpers/sortTasks";
import { $, $$, HTTPStore, type StoreContext } from "@manyducks.co/dolla";
import { differenceInMinutes } from "date-fns";
import { produce } from "immer";
import { DeltaOperation } from "quill";
import { type Task, type TaskAssignment, type TaskComplete, type TaskStatus } from "schemas";
import * as Y from "yjs";
import { AuthStore } from "./AuthStore";

export type UpdateTaskMetaData = {
  title: string;
  priority: number | null;
  dueDate: string | null;
  status: string | null;
  assignedUserId: number | null;
  tags: string[];
  attachments: string[];
};

interface CreateTaskOptions {
  projectId: number;
  delta?: DeltaOperation[];
  tags?: string[];
  dueDate?: string;
}

interface FetchHistoryOptions {
  completedAtStart: Date;
  completedAtEnd: Date;
  assignedUserId?: number;
  projectId?: number;
}

export function TasksStore(ctx: StoreContext) {
  const http = ctx.getStore(HTTPStore);
  const auth = ctx.getStore(AuthStore);

  const $$cache = $$(new Map<number, Task>());

  const $$highlightTaskId = $$<number | null>(null);

  const $myTasks = $($$cache, auth.$me, (tasks, me) =>
    me == null ? [] : [...tasks.values()].filter((t) => t.assignedUserId === me.id).sort(sortTasks),
  );

  // Keys are project IDs
  const indexCacheMap = new Map<number | null, { lastFetchedAt: Date; resultIds: number[] }>();

  /**
   * Fetches the task index for a given project.
   */
  async function fetchIndexFor(projectId: number | null, fresh = false) {
    const now = new Date();
    const mapped = indexCacheMap.get(projectId);

    if (!fresh && mapped && differenceInMinutes(now, mapped.lastFetchedAt) < 5) {
      const cache = $$cache.get();
      const results: Task[] = [];
      for (const id of mapped.resultIds) {
        results.push(cache.get(id)!);
      }
      return results;
    }

    return Promise.all([
      http.get<Task[]>(projectId === null ? `/api/tasks/activity` : `/api/tasks?projectId=${projectId}`),
    ]).then(([tasksRes]) => {
      $$cache.update(
        produce((tasks) => {
          for (const task of tasksRes.body) {
            tasks.set(task.id, task);
          }
        }),
      );

      indexCacheMap.set(projectId, {
        lastFetchedAt: now,
        resultIds: tasksRes.body.map((task) => task.id),
      });

      return tasksRes.body;
    });
  }

  /**
   * Fetch To Do list items for the current user.
   */
  async function fetchToDos() {
    const res = await http.get<Task[]>("/api/tasks/todo");

    $$cache.update(
      produce((tasks) => {
        for (const task of res.body) {
          tasks.set(task.id, task);
        }
      }),
    );
  }

  async function fetchHistory(options: FetchHistoryOptions) {
    if (!options.assignedUserId && !options.projectId) {
      throw new Error(`Must pass either assignedUserId or projectId`);
    }

    if (options.completedAtStart > new Date()) {
      return;
    }

    const res = await http.get<Task[]>("/api/tasks/history", { query: options });

    ctx.log(res);

    $$cache.update(
      produce((tasks) => {
        for (const t of res.body) {
          const found = tasks.get(t.id);
          if (found) {
            Object.assign(found, t);
          } else {
            tasks.set(t.id, t);
          }
        }
      }),
    );
  }

  interface FetchCalendarOptions {
    projectId?: number;
    assignedUserId?: number;
    withAdjacent?: boolean;
  }
  async function fetchCalendar(year: number, month: number, options: FetchCalendarOptions = {}) {
    const res = await http.get<Task[]>(`/api/tasks/calendar/${year}/${month}`, { query: options });

    $$cache.update(
      produce((tasks) => {
        for (const t of res.body) {
          const found = tasks.get(t.id);
          if (found) {
            Object.assign(found, t);
          } else {
            tasks.set(t.id, t);
          }
        }
      }),
    );
  }

  async function ensureTaskIsLoaded(taskId: number) {
    const found = $$cache.get().get(taskId);
    if (!found) {
      try {
        await http.get<Task>(`/api/tasks/${taskId}`).then((res) => {
          $$cache.update(
            produce((tasks) => {
              tasks.set(res.body.id, res.body);
            }),
          );
        });
      } catch (err) {
        ctx.error(err);
      }
    }
  }

  const createTask = async (options: CreateTaskOptions) => {
    return http.post<Task>(`/api/tasks`, { body: options }).then(async (res) => {
      ctx.log(res);

      $$cache.update(
        produce((tasks) => {
          tasks.set(res.body.id, res.body);
        }),
      );

      return res.body;
    });
  };

  /**
   * Update locally cached tasks. Actual changes to the task have already been
   * sent to the server over sockets by the time this has been called.
   */
  const updateTask = async (taskId: number, ytext: Y.Text) => {
    $$cache.update(
      produce((tasks) => {
        const task = tasks.get(taskId);
        if (task) {
          task.title = extractTitleFromDelta(ytext.toDelta());
        }
      }),
    );

    ctx.log("updated local cache for task", taskId);
  };

  const updateTaskMeta = async (taskId: number, fields: UpdateTaskMetaData) => {
    $$cache.update(
      produce((tasks) => {
        const task = tasks.get(taskId);
        if (task) {
          Object.assign(task, {
            ...fields,
            attachments: task.attachments.filter((f) => fields.attachments.includes(f.uuid)),
          });
        }
      }),
    );

    return http
      .put(`/api/tasks/${taskId}`, {
        body: fields,
      })
      .then(async (res) => {
        ctx.log(res);
      });
  };

  const deleteTask = async (taskId: number) => {
    $$cache.update(
      produce((tasks) => {
        tasks.delete(taskId);
      }),
    );

    return http.delete(`/api/tasks/${taskId}`).then(async (res) => {
      ctx.log(res);
    });
  };

  const assignTo = async (assignedUserId: number | null, taskId: number) => {
    $$cache.update(
      produce((tasks) => {
        const task = tasks.get(taskId);
        if (task) {
          if (assignedUserId == null) {
            task.assignedAt = null;
            task.assignedUserId = null;
          } else {
            task.assignedUserId = assignedUserId;
            task.assignedAt = new Date().toISOString();
          }
        }
      }),
    );

    if (assignedUserId == null) {
      return http.delete(`/api/tasks/${taskId}/assignee`).then((res) => {
        ctx.log(res);
      });
    } else {
      return http.put(`/api/tasks/${taskId}/assignee`, { body: { assignedUserId } }).then((res) => {
        ctx.log(res);
      });
    }
  };

  const setCompleted = async (taskId: number, completed: boolean) => {
    $$cache.update(
      produce((tasks) => {
        const found = tasks.get(taskId);

        if (found) {
          if (completed) {
            found.completedAt = new Date().toISOString();
          } else {
            found.completedAt = null;
          }
        }
      }),
    );

    if (completed) {
      return http.put(`/api/tasks/${taskId}/complete`).then((res) => {
        ctx.log(res);
      });
    } else {
      return http.delete(`/api/tasks/${taskId}/complete`).then((res) => {
        ctx.log(res);
      });
    }
  };

  async function setPriority(taskId: number, priority: null | number) {
    $$cache.update(
      produce((tasks) => {
        const found = tasks.get(taskId);
        if (found) {
          found.priority = priority;
        }
      }),
    );

    return http.put(`/api/tasks/${taskId}/priority`, {
      body: {
        priority,
      },
    });
  }

  const updateStatus = async (taskId: number, status: string | null) => {
    $$cache.update(
      produce((tasks) => {
        const found = tasks.get(taskId);

        if (found) {
          found.status = status;
        }
      }),
    );

    if (status != null && status.trim() !== "") {
      return http.put(`/api/tasks/${taskId}/status`, { body: { status } }).then((res) => {
        ctx.log(res);
      });
    } else {
      return http.delete(`/api/tasks/${taskId}/status`).then((res) => {
        ctx.log(res);
      });
    }
  };

  async function setTags(taskId: number, tags: string[]) {
    $$cache.update(
      produce((tasks) => {
        const found = tasks.get(taskId);
        if (found) {
          found.tags = tags;
        }
      }),
    );

    await http.put(`/api/tasks/${taskId}/tags`, {
      body: {
        tags,
      },
    });
  }

  /**
   * @param taskId - ID of the task to set the due date for.
   * @param dueDate - YYYY-MM-DD string representing the due date, or null to unset.
   */
  async function setDueDate(taskId: number, dueDate: string | null) {
    await http.put(`/api/tasks/${taskId}/due`, {
      body: { dueDate },
    });
  }

  return {
    $cache: $($$cache),
    $myTasks,
    $$highlightTaskId,

    setTags,
    setDueDate,

    fetchIndexFor,
    ensureTaskIsLoaded,
    createTask,
    updateTask,
    updateTaskMeta,
    deleteTask,

    fetchToDos,
    fetchHistory,
    fetchCalendar,

    setPriority,
    assignTo,
    setCompleted,
    updateStatus,

    taskUpdateReceived: (task: Task) => {
      $$cache.update(
        produce((cache) => {
          const current = cache.get(task.id);

          if (current) {
            Object.assign(current, task);
          } else {
            cache.set(task.id, task);
          }
        }),
      );
    },

    taskDeleteReceived: (id: number) => {
      const task = $$cache.get().get(id);

      if (task) {
        $$cache.update(
          produce((cache) => {
            cache.delete(id);
          }),
        );
      }
    },

    taskAssignReceived: (taskAssignment: TaskAssignment) => {
      $$cache.update(
        produce((cache) => {
          const current = cache.get(taskAssignment.id);

          if (current) {
            Object.assign(current, taskAssignment);
          }
        }),
      );
    },

    taskCompleteReceived: (taskComplete: TaskComplete) => {
      $$cache.update(
        produce((cache) => {
          const current = cache.get(taskComplete.id);

          if (current) {
            Object.assign(current, taskComplete);
          }
        }),
      );
    },

    taskStatusReceived: (taskStatus: TaskStatus) => {
      $$cache.update(
        produce((cache) => {
          const current = cache.get(taskStatus.id);

          if (current) {
            Object.assign(current, taskStatus);
          }
        }),
      );
    },

    updateCache(cache: Array<number>) {
      if (cache.length > 0) {
        $$cache.update(
          produce((tasks) => {
            for (const id of cache) {
              tasks.delete(id);
            }
          }),
        );
      }
    },
  };
}
