import { $, type Readable, type ViewContext } from "@manyducks.co/dolla";
import DownArrowIcon from "@views/@icons/DownArrow";
import styles from "./Select.module.css";

interface SelectOption<T> {
  label: string | Readable<string>;
  value: T;
}

interface SelectProps<T> {
  id?: string;
  required?: boolean | Readable<boolean>;
  placeholder?: string | Readable<string>;
  value: T | Readable<T>;
  options: SelectOption<T>[];
  onChange: (value: T) => void;
  size?: "normal" | "small" | Readable<"normal" | "small">;
}

export function Select<T>({ id, options, onChange, ...props }: SelectProps<T>, ctx: ViewContext) {
  const $value = $(props.value);
  const $size = $(props.size ?? "normal");
  const $selected = $($value, (value) => {
    return options.findIndex((o) => o.value === value);
  });

  return (
    <div class={[styles.container, { [styles.small]: $($size, (s) => s === "small") }]}>
      <select
        id={id}
        class={[styles.select, { [styles.empty]: $($selected, (s) => s === -1) }]}
        value={$($selected, String)}
        required={props.required}
        onchange={(e: any) => {
          const index = Number(e.target.value);
          const value = options[index]?.value;
          onChange(value);
        }}
      >
        {$($(props.placeholder), (placeholder) => {
          if (placeholder) {
            return (
              <option value="-1" disabled selected={$($selected, (s) => s === -1)}>
                {placeholder}
              </option>
            );
          }
        })}
        {options.map((o, i) => {
          return (
            <option value={String(i)} selected={$($selected, (s) => s === i)}>
              {o.label}
            </option>
          );
        })}
      </select>

      <div class={styles.icon}>
        <DownArrowIcon />
      </div>
    </div>
  );
}
