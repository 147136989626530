import { $, type Readable, type Writable, type ViewContext } from "@manyducks.co/dolla";
import styles from "./Button.module.css";

type ButtonSize = "normal" | "small";
type ButtonVariant = "normal" | "destructive";
export type ButtonType = "button" | "submit" | "reset" | undefined;

export interface ButtonProps {
  /**
   * Button type. Note that type is 'button' by default and will not submit forms when clicked unless this is set to 'submit'.
   */
  type?: ButtonType;
  ref?: Writable<HTMLButtonElement | undefined>;
  onClick?: (event: MouseEvent) => void;
  disabled?: boolean | Readable<boolean>;
  size?: ButtonSize;
  variant?: ButtonVariant;
  tooltip?: string | Readable<string>;
}

/**
 * All purpose button. Does stuff when you click it.
 */
export function Button(props: ButtonProps, ctx: ViewContext) {
  const { type, size, variant, onClick } = props;

  const $disabled = $(props.disabled ?? false);
  const $tooltip = $(props.tooltip);

  return (
    <button
      onClick={(e) => {
        if (!$disabled.get() && onClick) {
          onClick(e);
        }
      }}
      className={[styles.button, styles[variant ?? "normal"], size && styles[size]]}
      type={type}
      ref={props.ref}
      disabled={$disabled}
      title={$tooltip}
    >
      {ctx.outlet()}
    </button>
  );
}
