import { type StoreContext } from "@manyducks.co/dolla";
import { Manager } from "socket.io-client";
import { AuthStore } from "./AuthStore";

/**
 * Socket.IO service to manage our sort of unique configuration needs.
 */
export function IOStore(ctx: StoreContext) {
  const { $me, $token } = ctx.getStore(AuthStore);

  const uri = window.location.origin;
  const manager = new Manager(uri, {
    autoConnect: false,
    transports: ["websocket"],
  });

  return {
    socket: (namespace = "/", options = {}) => {
      const auth = {
        userId: $me.get()?.id,
        token: $token.get(),
      };

      const _socket = manager.socket(namespace, {
        ...options,
        auth,
      });

      _socket.connect();

      return _socket;
    },
  };
}
