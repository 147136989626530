export default {
  "content": "Details_content_2b339",
  "projectEdit": "Details_projectEdit_2b339",
  "projectEditButton": "Details_projectEditButton_2b339",
  "projectHeader": "Details_projectHeader_2b339",
  "projectHeaderIcon": "Details_projectHeaderIcon_2b339",
  "projectHeaderText": "Details_projectHeaderText_2b339",
  "charmsBar": "Details_charmsBar_2b339",
  "cardSwitchList": "Details_cardSwitchList_2b339",
  "cardSwitchListItem": "Details_cardSwitchListItem_2b339",
  "cardSwitchIcon": "Details_cardSwitchIcon_2b339",
  "cardSwitchLabel": "Details_cardSwitchLabel_2b339",
  "cardLaunchButton": "Details_cardLaunchButton_2b339",
  "userList": "Details_userList_2b339",
  "userListItem": "Details_userListItem_2b339",
  "userLayout": "Details_userLayout_2b339",
  "userInfo": "Details_userInfo_2b339",
  "userIcon": "Details_userIcon_2b339",
  "roleBadge": "Details_roleBadge_2b339",
  "userText": "Details_userText_2b339",
  "userName": "Details_userName_2b339",
  "userStatus": "Details_userStatus_2b339",
  "userActions": "Details_userActions_2b339",
  "userMeta": "Details_userMeta_2b339",
  "cardLauncherIcon": "Details_cardLauncherIcon_2b339",
  "menuPanelIcon": "Details_menuPanelIcon_2b339",
  "addUserSection": "Details_addUserSection_2b339",
  "justifyEnd": "Details_justifyEnd_2b339",
  "toolButtonIcon": "Details_toolButtonIcon_2b339",
  "editForm": "Details_editForm_2b339",
  "editFormGroup": "Details_editFormGroup_2b339",
  "inviteListItem": "Details_inviteListItem_2b339",
  "inviteListItemName": "Details_inviteListItemName_2b339"
};import "ni:sha-256;LeJ47GC6287qmEjvVHoRUrvTg5inJspLBhg-FyqpuaI";