import { type DeltaOperation } from "quill";
import * as Y from "yjs";

export function extractTitleFromDelta(delta: DeltaOperation[]) {
  const titleOps: DeltaOperation[] = [];

  // Extract operations until the end of the header.
  for (const op of delta) {
    if (op.insert === "\n" && op.attributes?.header) {
      break;
    }
    titleOps.push(op);
  }

  // Use Y.Doc to convert deltas to a string.
  const doc = new Y.Doc();
  const text = doc.getText("title");
  text.applyDelta(titleOps);

  // Trim string to 100 characters and return result.
  const title = text.toString().split("\n")[0];
  if (title.length > 100) {
    return title.slice(0, 97) + "...";
  } else {
    return title;
  }
}
