import { $, repeat, type Readable, type Renderable, type ViewContext } from "@manyducks.co/dolla";
import styles from "./SegmentButton.module.css";

interface Segment<Id> {
  value: Id;
  content: Renderable;
  disabled?: boolean | Readable<boolean>;
  hidden?: boolean | Readable<boolean>;
}

interface SegmentButtonProps<Id> {
  value: Id | Readable<Id>;
  segments: Segment<Id>[] | Readable<Segment<Id>[]>;
  onChange: (newValue: Id, newSegment: Segment<Id>) => void;
}

export function SegmentButton<Id>(props: SegmentButtonProps<Id>, ctx: ViewContext) {
  const $value = $(props.value);
  const $segments = $($(props.segments), (segments) => segments.filter((s) => !s.hidden));

  return (
    <div
      role="radiogroup"
      class={styles.container}
      style={{
        gridTemplateColumns: $($segments, (s) => `repeat(${s.length}, 1fr)`),
      }}
    >
      {repeat(
        $segments,
        (s) => String(s.value),
        ($segment) => {
          const $selected = $($value, $segment, (value, segment) => segment.value === value);
          const $segmentValue = $($segment, (s) => s.value);
          const $content = $($segment, (s) => s.content);

          return (
            <button
              class={styles.button}
              type="button"
              role="radio"
              tabIndex={0}
              ariaChecked={$selected}
              onClick={() => {
                props.onChange($segmentValue.get(), $segment.get());
              }}
            >
              {$content}
            </button>
          );
        },
      )}
    </div>
  );
}
