import { $, $$, cond, repeat, LanguageStore, type Readable, type ViewContext } from "@manyducks.co/dolla";
import Close from "@views/@icons/Close";
import { Button } from "@views/Button";
import { TextInput } from "@views/TextInput";
import styles from "./TagInput.module.css";

export interface TagInputChange {
  tags: string[];
  added: string[];
  removed: string[];
}

interface TagInputProps {
  $value: Readable<string[]>;
  onChange: (change: TagInputChange) => void;
}

export function TagInput(props: TagInputProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  const $$inputValue = $$("");

  function onAdd() {
    const current = props.$value.get();
    const value = $$inputValue.get();
    const tags = value
      .split(",")
      .map((t) => t.trim())
      .filter((t) => t !== "");

    const newTags: string[] = [];

    compare: for (const tag of tags) {
      const normalized = tag.toLowerCase().trim();
      for (const c of current) {
        if (c.toLowerCase() === normalized) {
          continue compare;
        }
      }
      newTags.push(normalized);
    }

    props.onChange({ tags: [...current, ...newTags].sort(), added: newTags, removed: [] });
    $$inputValue.set("");
  }

  return (
    <section class={styles.container}>
      <form
        class={styles.inputGroup}
        onSubmit={(e) => {
          e.preventDefault();
          onAdd();
        }}
      >
        <TextInput $$value={$$inputValue} placeholder={translate("views.tagInput.placeholder")} />
        <Button type="submit">{translate("common.add")}</Button>
      </form>
      {cond(
        $(props.$value, (v) => v.length > 0),
        <ul class={styles.tagList}>
          {repeat(
            $(props.$value, (tags) => [...tags].sort()),
            (t) => t,
            ($tag) => {
              return (
                <li class={styles.tag}>
                  <span class={styles.tagName}>{$tag}</span>
                  <button
                    class={styles.tagRemoveButton}
                    onclick={(e) => {
                      const tag = $tag.get();
                      props.onChange({
                        tags: props.$value.get().filter((t) => t !== tag),
                        removed: [tag],
                        added: [],
                      });
                    }}
                  >
                    <Close />
                  </button>
                </li>
              );
            },
          )}
        </ul>,
      )}
    </section>
  );
}
