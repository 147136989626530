import { $$, $, type Readable, type ViewContext, type Writable } from "@manyducks.co/dolla";
import { nanoid } from "nanoid";
import styles from "./ColorInput.module.css";

interface ColorInputProps {
  /**
   * Input 'id' for use in forms.
   */
  id?: string | Readable<string>;

  /**
   * Input 'name' for use in forms.
   */
  name?: string | Readable<string>;

  /**
   * Two-way bindable color value.
   */
  value: Writable<string>;
}

/**
 * Provides a color picker to select a custom color.
 */
export function ColorInput(props: ColorInputProps, ctx: ViewContext) {
  const $$value = $$(props.value);
  const $id = $(props.id ?? nanoid());
  const $name = $(props.name);

  return (
    <div class={styles.container}>
      <input id={$id} class={styles.input} type="color" $$value={$$value} name={$name} />
      <label for={$id} class={styles.label} style={{ "--input-color": $$value }}>
        <span class={styles.outline} />
        <span class={styles.fill} />
      </label>
    </div>
  );
}
