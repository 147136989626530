import { TabBar } from "@views/TabBar";
import styles from "./Me.module.css";
import { LanguageStore, ViewContext } from "@manyducks.co/dolla";

export function Me(props: {}, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  return (
    <div class={styles.layout}>
      <TabBar
        tabs={[
          {
            href: "/me/tasks",
            active: (pattern) => pattern.startsWith("/me/tasks"),
            text: translate("workspace.me.tasks.tabName"),
          },
          {
            href: "/me/settings",
            active: (pattern) => pattern.startsWith("/me/settings"),
            text: translate("workspace.me.settings.tabName"),
          },
        ]}
      />

      <div class={styles.content}>{ctx.outlet()}</div>
    </div>
  );
}
