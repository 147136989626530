export default {
  "messageList": "ChatMessageList_messageList_75052",
  "message": "ChatMessageList_message_75052",
  "mine": "ChatMessageList_mine_75052",
  "noTextSpacer": "ChatMessageList_noTextSpacer_75052",
  "authorAvatar": "ChatMessageList_authorAvatar_75052",
  "authorAvatarSpacer": "ChatMessageList_authorAvatarSpacer_75052",
  "messageBubble": "ChatMessageList_messageBubble_75052",
  "messageContent": "ChatMessageList_messageContent_75052",
  "messageInfo": "ChatMessageList_messageInfo_75052",
  "authorName": "ChatMessageList_authorName_75052",
  "messageTimestamp": "ChatMessageList_messageTimestamp_75052"
};import "ni:sha-256;LxRKeIhRhUI574_slPtj0HhUA3ymohRcEGQJYH4q1dg";