import {
  $,
  $$,
  LanguageStore,
  cond,
  unwrap,
  type DialogProps,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogHeader } from "@views/Dialog";
import { NumericInput } from "@views/NumericInput";
import styles from "./ConfirmDelete.module.css";

/**
 * Returns a number between `min` and `max`.
 */
function fromRange(min: number, max: number, rounded = false) {
  // Start at min.
  let value = Math.random() * min;

  // Land somewhere between there and max.
  value += Math.random() * (max - min);

  if (rounded) {
    value = Math.round(value);
  }

  return value;
}

type Challenge = {
  question: string;
  answer: number;
};

/**
 * Ensures answer never equals 0.
 */
function getRange(forSubtraction: boolean): { left: number; right: number } {
  let left = fromRange(2, 12, true);
  let right = fromRange(2, 12, true);

  if (forSubtraction) {
    while (left - right === 0) {
      left = fromRange(2, 12, true);
      right = fromRange(2, 12, true);
    }
  }

  return { left, right };
}

/**
 * Generates a simple math problem to use as confirmation that you thought about it.
 */
function getChallenge(): Challenge {
  const rand = Math.random();

  if (rand < 0.333) {
    // Addition
    const { left, right } = getRange(false);

    return {
      question: `${left} + ${right}`,
      answer: left + right,
    };
  } else if (rand < 0.666) {
    // Subtraction
    const { left, right } = getRange(true);

    const greater = Math.max(left, right);
    const lesser = Math.min(left, right);

    return {
      question: `${greater} − ${lesser}`,
      answer: greater - lesser,
    };
  } else {
    // Multiplication
    const { left, right } = getRange(false);

    return {
      question: `${left} × ${right}`,
      answer: left * right,
    };
  }
}

interface ConfirmDeleteProps extends DialogProps {
  /**
   * Dialog title
   */
  title?: string | Readable<string>;

  /**
   * Name of the item being deleted, for confirmation.
   */
  itemName?: string | Readable<string>;

  /**
   * Message to customize the dialog to the resource being deleted.
   */
  message: string | Readable<string>;

  /**
   * Called when confirmation is successful
   */
  onConfirm: () => void;
}

/**
 * A dialog to confirm permanent deletion of some data.
 */
export function ConfirmDelete(props: ConfirmDeleteProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  const $$open = $$(props.$$open);
  const $message = $(props.message);
  const onConfirm = unwrap(props.onConfirm);
  const title = unwrap(props.title ?? translate("views.dialog.confirmDelete.defaultTitle"));
  const itemName = unwrap(props.itemName);

  const $$inputElement = $$<HTMLInputElement>();

  const $$inputValue = $$(0);
  const $$challenge = $$<Challenge>(getChallenge());
  const $question = $($$challenge, (x) => x.question);

  const $challengeMet = $($$challenge, $$inputValue, (challenge, value) => {
    return value === challenge.answer;
  });

  // New challenge each time dialog opens.
  ctx.observe($$open, (open) => {
    if (open) {
      $$challenge.set(getChallenge());
    }
  });

  ctx.onConnected(() => {
    $$inputElement.get()!.focus();
  });

  return (
    <Dialog
      onsubmit={() => {
        if ($challengeMet.get()) {
          onConfirm();
          $$open.set(false);
        }
      }}
      transitionIn={props.transitionIn}
      transitionOut={props.transitionOut}
      accentColor="#ff0000"
    >
      <DialogHeader $$open={$$open} title={title} icon={"/icons/danger.16.png"} />

      <DialogContent>
        <div class={styles.content}>
          {cond(
            itemName,
            <p class={styles.itemName}>
              {translate("views.dialog.confirmDelete.nameConfirmationMessage", { name: itemName! })}
            </p>,
          )}
          <p class={styles.instructions}>{$message}</p>
          <p class={styles.question}>{$question}</p>
          <div class={styles.input}>
            <NumericInput
              ref={$$inputElement}
              value={$$inputValue}
              placeholder={translate("views.dialog.confirmDeleteDialog.answerPlaceholder")}
            />
            <Button type="submit" variant="destructive" disabled={$($challengeMet, (x) => !x)}>
              {translate("views.dialog.confirmDelete.confirmButtonLabel")}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
