export default {
  "noteListItem": "NoteListItem_noteListItem_a1a12",
  "itemDivider": "NoteListItem_itemDivider_a1a12",
  "note": "NoteListItem_note_a1a12",
  "highlight": "NoteListItem_highlight_a1a12",
  "focused": "NoteListItem_focused_a1a12",
  "noteTitle": "NoteListItem_noteTitle_a1a12",
  "noteLayout": "NoteListItem_noteLayout_a1a12",
  "noteText": "NoteListItem_noteText_a1a12",
  "noteControls": "NoteListItem_noteControls_a1a12",
  "untitled": "NoteListItem_untitled_a1a12",
  "noteTagList": "NoteListItem_noteTagList_a1a12",
  "noteTag": "NoteListItem_noteTag_a1a12",
  "metaList": "NoteListItem_metaList_a1a12",
  "metaIcon": "NoteListItem_metaIcon_a1a12",
  "charmsBar": "NoteListItem_charmsBar_a1a12"
};import "ni:sha-256;csmL5vkCQVEWcXmXm1K7kZ2sc5PXSu1Y2g1JXQBWReM";