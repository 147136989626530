export default {
  "container": "List_container_dac75",
  "filterSortGroup": "List_filterSortGroup_dac75",
  "tagFilter": "List_tagFilter_dac75",
  "orderIcon": "List_orderIcon_dac75",
  "content": "List_content_dac75",
  "emptyMessage": "List_emptyMessage_dac75",
  "noteList": "List_noteList_dac75",
  "inputForm": "List_inputForm_dac75",
  "inputFormControls": "List_inputFormControls_dac75",
  "noNotes": "List_noNotes_dac75",
  "toolButtonIcon": "List_toolButtonIcon_dac75",
  "searchOrCreate": "List_searchOrCreate_dac75"
};import "ni:sha-256;H9cos5-lS3o7_hid_A54pWCGnROcEvEz8m7zE2eETpE";