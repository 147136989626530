import { type Socket } from "socket.io-client";
import { applyAwarenessUpdate, encodeAwarenessUpdate, type Awareness } from "y-protocols/awareness";
import * as Y from "yjs";
import { EventEmitter } from "@borf/bedrock";

interface SynchronizerEvents {
  synced: void;
}

/**
 * Synchronizes a ydoc and awareness instance over a socket.
 */
export class Synchronizer extends EventEmitter<SynchronizerEvents> {
  ydoc: Y.Doc;
  awareness: Awareness;
  socket: Socket;
  editMode = false;
  connected = false;

  constructor(socket: Socket, ydoc: Y.Doc, awareness: Awareness) {
    super();

    this.socket = socket;
    this.ydoc = ydoc;
    this.awareness = awareness;
  }

  async connect() {
    return new Promise<void>((resolve) => {
      this.ydoc.on("update", this._onLocalDocUpdate);
      this.socket.on("update", this._onRemoteDocUpdate);
      this.awareness.on("update", this._onLocalAwarenessUpdate);
      this.socket.on("awarenessUpdate", this._onRemoteAwarenessUpdate);
      this.socket.on("syncStep1", this._onSyncStep1);
      this.socket.on("syncStep2", this._onSyncStep2);
      this.socket.io.on("reconnect", this._onSocketReconnect);

      if (!this.socket.connected) {
        this.socket.once("connect", () => {
          this.connected = true;
          this.socket.emit("syncStep1", Y.encodeStateVector(this.ydoc));
        });
      } else {
        this.connected = true;
        this.socket.emit("syncStep1", Y.encodeStateVector(this.ydoc));
      }

      // Fired once server acknowledges syncStep2 has been applied.
      this.once("synced", () => {
        resolve();
      });
    });
  }

  async disconnect() {
    this.connected = false;

    return new Promise<void>((resolve) => {
      this.ydoc.off("update", this._onLocalDocUpdate);
      this.socket.off("update", this._onRemoteDocUpdate);
      this.awareness.off("update", this._onLocalAwarenessUpdate);
      this.socket.off("awarenessUpdate", this._onRemoteAwarenessUpdate);
      this.socket.off("syncStep1", this._onSyncStep1);
      this.socket.off("syncStep2", this._onSyncStep2);
      this.socket.io.off("reconnect", this._onSocketReconnect);

      if (this.socket.connected) {
        this.socket.once("disconnect", () => {
          resolve();
        });
        this.socket.disconnect();
      } else {
        resolve();
      }
    });
  }

  setEditMode(value: boolean) {
    this.editMode = value;
  }

  _onLocalDocUpdate = (update: Uint8Array) => {
    if (this.connected && this.editMode) {
      this.socket.emit("update", update);
    }
  };

  _onRemoteDocUpdate = (update: ArrayBuffer) => {
    if (this.connected) {
      Y.applyUpdate(this.ydoc, new Uint8Array(update));
    }
  };

  _onLocalAwarenessUpdate = ({ added, updated, removed }: any) => {
    if (this.connected && this.editMode) {
      const update = encodeAwarenessUpdate(this.awareness, [...added, ...updated, ...removed]);
      this.socket.emit("awarenessUpdate", update);
    }
  };

  _onRemoteAwarenessUpdate = (update: ArrayBuffer) => {
    if (this.connected) {
      applyAwarenessUpdate(this.awareness, new Uint8Array(update), this);
    }
  };

  _onSyncStep1 = (serverStateVector: ArrayBuffer) => {
    // Receive server's state vector; encode update.
    const update = Y.encodeStateAsUpdate(this.ydoc, new Uint8Array(serverStateVector));

    // Return update to server. Server applies update and acknowledges.
    this.socket.emit("syncStep2", update, () => {
      this.emit("synced", undefined);
    });
  };

  _onSyncStep2 = (serverUpdate: ArrayBuffer, callback: () => void) => {
    // Receive server's update; apply to doc.
    Y.applyUpdate(this.ydoc, new Uint8Array(serverUpdate));

    // Acknowledge server's update.
    callback?.();
  };

  _onSocketReconnect = () => {
    if (this.connected) {
      // this.ydoc.getText("content").delete(0, Infinity); // Clear local document before resyncing or content gets doubled.
      this.socket.emit("syncStep1", Y.encodeStateVector(this.ydoc));
    }
  };
}
