import { $, $$, cond, DialogProps, HTTPStore, LanguageStore, repeat, ViewContext } from "@manyducks.co/dolla";
import { ClockStore } from "@stores/ClockStore";
import { NavStore } from "@stores/NavStore";
import { TasksStore, UpdateTaskMetaData } from "@stores/TasksStore";
import ArrowUpDoubleLine from "@views/@icons/ArrowUpDoubleLine";
import ArrowUpSingleLine from "@views/@icons/ArrowUpSingleLine";
import Calendar from "@views/@icons/Calendar";
import TagIcon from "@views/@icons/Tag";
import UserIcon from "@views/@icons/User";
import { Button } from "@views/Button";
import { DatePicker } from "@views/DatePicker";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { SegmentButton } from "@views/SegmentButton";
import { TagInput } from "@views/TagInput";
import { UserPicker } from "@views/UserPicker";
import { startOfDay, subDays } from "date-fns";
import { File as FileSchema, Task } from "schemas";
import styles from "./TaskEditDialog.module.css";
import Star from "@views/@icons/Star";
import Paperclip from "@views/@icons/Paperclip";
import { CollapsibleListSection } from "@views/CollapsibleListSection";
import Close from "@views/@icons/Close";
import Plus from "@views/@icons/Plus";
import { promptForFiles } from "@helpers/promptForFiles";
import { FilesStore } from "@stores/FilesStore";
import { InputFile } from "Workspace/Chat/ChatEditor/ChatEditor";
import { produce } from "immer";
import { FileAttachment } from "@views/FileAttachment";

interface TaskEditDialogProps extends DialogProps {
  projectId: number;
  task?: Task;
  color?: string;
  onSubmitted?: () => void;
  onToListClicked?: () => void;
}

export function TaskEditDialog(props: TaskEditDialogProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);
  const clock = ctx.getStore(ClockStore);
  const tasks = ctx.getStore(TasksStore);
  const nav = ctx.getStore(NavStore);
  const { uploadFile } = ctx.getStore(FilesStore);
  const http = ctx.getStore(HTTPStore);

  const $task = $(tasks.$cache, (tasks) => {
    if (props.task) {
      return tasks.get(props.task.id);
    }
  });
  const $taskExists = $($task, (t) => t != null);

  // Close dialog if task was deleted.
  ctx.observe($taskExists, (exists) => {
    if (!exists) {
      props.$$open.set(false);
    }
  });

  const $taskIsFavorite = $($task, nav.$favorites, (task, favorites) => {
    if (task == null) return false;
    const { projectId, id } = task;
    return favorites.some((f) => f.path === `/projects/${projectId}/tasks/${id}`);
  });

  const $$submitting = $$<boolean>(false);

  const $$title = $$<string>(props.task?.title ?? "");
  const $$status = $$<string>(props.task?.status ?? "");
  const $$assignedUserId = $$<number | null>(props.task?.assignedUserId ?? null);
  const $$priority = $$<number | null>(props.task?.priority ?? null);
  const $$dueDate = $$<string | null>(props.task?.dueDate || null);
  const $$tags = $$<string[]>(props.task?.tags ?? []);

  const $$deletedAttachments = $$<string[]>([]);
  const $attachments = $(
    $task,
    $$deletedAttachments,
    (task, deletedUUIDs) => task?.attachments.filter((f) => !deletedUUIDs.includes(f.uuid)) ?? [],
  );

  const $$fileUploads = $$<InputFile[]>([]);

  const $$titleElement = $$<HTMLSpanElement>();
  const $$descriptionElement = $$<HTMLSpanElement>();

  const $$assignedUserEnabled = $$($$assignedUserId.get() != null);
  const $$priorityEnabled = $$($$priority.get() != null);
  const $$dueDateEnabled = $$($$dueDate.get() != null);
  const $$tagsEnabled = $$($$tags.get().length > 0);
  const $$attachmentsEnabled = $$($attachments.get().length > 0);

  // Things that can be edited: title, due date, assigned user, tags, status, priority

  ctx.beforeConnect(() => {
    const titleElement = $$titleElement.get();
    if (titleElement) {
      titleElement.textContent = $$title.get();
    }
    const descriptionElement = $$descriptionElement.get();
    if (descriptionElement) {
      descriptionElement.textContent = $$status.get();
    }
  });

  function addFiles(files: FileList | File[]) {
    for (const file of Array.from(files)) {
      const events = uploadFile(file);
      let uploadId: string;

      events.on("started", (e) => {
        ctx.log("upload started", e);
        uploadId = e.data.uploadId;
        const inputFile: InputFile = {
          file,
          uploadId,
          progress: 0,
          complete: false,
          events,
        };
        $$fileUploads.update(
          produce((current) => {
            current.push(inputFile);
          }),
        );
      });
      events.on("progress", (e) => {
        ctx.log("upload progress", e);
        $$fileUploads.update(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.progress = e.data.percent;
            }
          }),
        );
      });
      events.on("error", (e) => {
        ctx.log("upload error", e);
        $$fileUploads.update(
          produce((current) => {
            const found = current.find((f) => f.uploadId === uploadId);
            if (found) {
              found.error = e.data.error;
            }
          }),
        );
      });
      events.on("complete", (e) => {
        ctx.log("upload complete", e);

        const task = $task.get()!;
        http
          .post<Task>(`/api/tasks/${task.id}/attachments`, { body: { uuids: [uploadId] } })
          .then((res) => {
            ctx.info("Attachment added", res);
            $$fileUploads.update(
              produce((current) => {
                return current.filter((f) => f.uploadId !== uploadId);
              }),
            );
          })
          .catch((err) => {
            ctx.error(err);
          });
      });
    }
  }

  return (
    <Dialog
      onsubmit={async () => {
        if ($$submitting.get()) return;

        $$submitting.set(true);

        let title = $$title.get();
        let status = $$status.get();
        let assignedUserId = $$assignedUserId.get();
        let priority = $$priority.get();
        let dueDate = $$dueDate.get();
        let tags = $$tags.get();
        let attachments = $attachments.get();

        const data: UpdateTaskMetaData = {
          title: title.trim(),
          status: status.trim() || null,
          assignedUserId: $$assignedUserEnabled.get() ? assignedUserId : null,
          priority: $$priorityEnabled.get() ? priority : null,
          dueDate: $$dueDateEnabled.get() ? dueDate : null,
          tags: $$tagsEnabled.get() ? tags : [],
          attachments: $$attachmentsEnabled.get() ? attachments.map((f) => f.uuid) : [],
        };

        if (props.task) {
          await tasks.updateTaskMeta(props.task.id, data);
        } else {
          // tasks.createTask(props.projectId, data);
        }

        $$submitting.set(false);
        props.$$open.set(false);
      }}
      transitionIn={props.transitionIn}
      transitionOut={props.transitionOut}
      accentColor={props.color}
    >
      <DialogHeader
        $$open={props.$$open}
        title={translate("views.taskListItem.editDialog.dialogTitle")}
        buttons={[
          cond(
            props.task,
            <button
              class={[styles.favoriteButton, { [styles.active]: $taskIsFavorite }]}
              onClick={(e) => {
                e.preventDefault();

                const { projectId, id } = props.task!;
                const path = `/projects/${projectId}/tasks/${id}`;

                if ($taskIsFavorite.get()) {
                  const favorite = nav.$favorites.get().find((f) => f.path === path);
                  if (favorite) {
                    nav.removeFavorite(favorite.id);
                  }
                } else {
                  nav.addFavorite(path);
                }
              }}
            >
              <Star />
            </button>,
          ),
        ]}
      />
      <DialogContent>
        <section class={styles.titleAndDescription}>
          <div class={styles.titleContainer}>
            {cond(
              $($$title, (x) => x.trim().length === 0),
              <span class={styles.titlePlaceholder}>
                {translate("views.taskListItem.editDialog.titlePlaceholder")}
              </span>,
            )}
            <span
              ref={$$titleElement}
              class={styles.titleInput}
              contentEditable
              role="textbox"
              onInput={(e) => {
                const value = (e.currentTarget as HTMLElement).textContent ?? "";
                $$title.set(value);
              }}
            ></span>
          </div>

          <div class={styles.descriptionContainer}>
            {cond(
              $($$status, (x) => x.trim().length === 0),
              <span class={styles.descriptionPlaceholder}>
                {translate("views.taskListItem.editDialog.descriptionPlaceholder")}
              </span>,
            )}
            <span
              ref={$$descriptionElement}
              class={styles.descriptionInput}
              contentEditable
              role="textbox"
              onInput={(e) => {
                const value = (e.currentTarget as HTMLElement).textContent ?? "";
                $$status.set(value);
              }}
            ></span>
          </div>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$assignedUserEnabled }]}>
          <CollapsibleListSection
            title={translate("views.taskListItem.editDialog.fields.assign.label")}
            controls={[
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  $$assignedUserId.set(null);
                  $$assignedUserEnabled.set(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              <UserPicker
                $selectedId={$($$assignedUserId, (id) => id ?? undefined)}
                $projectId={$(props.projectId)}
                onchange={(newUserId) => {
                  $$assignedUserId.set(newUserId ?? null);
                }}
              />
            </div>
          </CollapsibleListSection>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$dueDateEnabled }]}>
          <CollapsibleListSection
            title={translate("views.taskListItem.editDialog.fields.dueDate.label")}
            controls={[
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  $$dueDate.set(null);
                  $$dueDateEnabled.set(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              <DatePicker
                $value={$$dueDate}
                $min={$(clock.$hour, () => startOfDay(subDays(new Date(), 1)))}
                onChange={(value) => {
                  $$dueDate.set(value);
                }}
                accentColor={props.color}
                placeholder="---"
              />
            </div>
          </CollapsibleListSection>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$priorityEnabled }]}>
          <CollapsibleListSection
            title={translate("views.taskListItem.editDialog.fields.priority.label")}
            controls={[
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  $$priority.set(null);
                  $$priorityEnabled.set(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              <SegmentButton
                value={$$priority}
                onChange={(value) => {
                  $$priority.set(value);
                }}
                segments={[
                  {
                    value: 2,
                    content: (
                      <div class={styles.metaPriorityItem}>
                        <ArrowUpDoubleLine />
                        <span>{translate("views.taskListItem.priority.now.title")}</span>
                      </div>
                    ),
                  },
                  {
                    value: 1,
                    content: (
                      <div class={styles.metaPriorityItem}>
                        <ArrowUpSingleLine />
                        <span>{translate("views.taskListItem.priority.soon.title")}</span>
                      </div>
                    ),
                  },
                  {
                    value: null,
                    content: (
                      <div class={styles.metaPriorityItem}>
                        <span>{translate("views.taskListItem.priority.none.title")}</span>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </CollapsibleListSection>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$attachmentsEnabled }]}>
          <CollapsibleListSection
            title={translate("views.taskListItem.editDialog.fields.attachments.label")}
            controls={[
              {
                id: "add",
                icon: <Plus />,
                title: "ADD",
                variant: "accent",
                onClick: () => {
                  // Select files to upload.
                  promptForFiles({ multiple: true, accept: "*" }).then((files) => {
                    addFiles(files);
                  });
                },
              },
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  $$deletedAttachments.set($attachments.get().map((f) => f.uuid));
                  $$attachmentsEnabled.set(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              {cond(
                $($attachments, (f) => f.length > 0),
                <ul class={styles.attachmentsList}>
                  {repeat(
                    $attachments,
                    (f) => f.id,
                    ($file) => (
                      <li>
                        <FileAttachment
                          $attachment={$file}
                          onDelete={() => {
                            const file = $file.get();
                            $$deletedAttachments.update((current) => [...current, file.uuid]);
                          }}
                        />
                      </li>
                    ),
                  )}
                </ul>,
                <p>No attachments.</p>,
              )}
            </div>
          </CollapsibleListSection>
        </section>

        <section class={[styles.formGroup, { [styles.visible]: $$tagsEnabled }]}>
          <CollapsibleListSection
            title={translate("views.taskListItem.editDialog.fields.tags.label")}
            controls={[
              {
                id: "clear",
                icon: <Close />,
                title: translate("views.taskListItem.editDialog.clearLabel"),
                onClick: () => {
                  $$tags.set([]);
                  $$tagsEnabled.set(false);
                },
              },
            ]}
          >
            <div class={styles.formGroupContent}>
              <TagInput
                $value={$$tags}
                onChange={({ tags }) => {
                  $$tags.set(tags);
                }}
              />
            </div>
          </CollapsibleListSection>
        </section>

        <ul class={styles.metaButtons}>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$assignedUserEnabled }]}
              onClick={() => {
                $$assignedUserEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <UserIcon />
              </div>
              {translate("views.taskListItem.editDialog.fields.assign.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$dueDateEnabled }]}
              onClick={() => {
                $$dueDateEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <Calendar />
              </div>
              {translate("views.taskListItem.editDialog.fields.dueDate.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$priorityEnabled }]}
              onClick={() => {
                $$priorityEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <ArrowUpDoubleLine />
              </div>
              {translate("views.taskListItem.editDialog.fields.priority.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$attachmentsEnabled }]}
              onClick={() => {
                $$attachmentsEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <Paperclip />
              </div>
              {translate("views.taskListItem.editDialog.fields.attachments.chipText")}
            </button>
          </li>
          <li>
            <button
              type="button"
              class={[styles.metaButton, { [styles.hidden]: $$tagsEnabled }]}
              onClick={() => {
                $$tagsEnabled.set(true);
              }}
            >
              <div class={styles.metaButtonIcon}>
                <TagIcon />
              </div>
              {translate("views.taskListItem.editDialog.fields.tags.chipText")}
            </button>
          </li>
        </ul>
      </DialogContent>
      <DialogFooter>
        {cond(
          props.onToListClicked,
          <Button
            type="button"
            disabled={$$submitting}
            onClick={() => {
              props.$$open.set(false);
              props.onToListClicked?.();
            }}
          >
            {translate("views.taskListItem.editDialog.toListButtonText")}
          </Button>,
        )}
        <div style={{ flex: "1 1 100%" }} />
        <Button type="submit" disabled={$$submitting}>
          {translate("views.taskListItem.editDialog.submitButtonText")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
