import { $, $$, LanguageStore, type DialogProps, type ViewContext } from "@manyducks.co/dolla";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { SegmentButton } from "@views/SegmentButton";
import { TextInput } from "@views/TextInput";
import { type Project } from "schemas";
import styles from "./InviteDialog.module.css";

type Role = "viewer" | "collaborator" | "admin";

interface SubmissionData {
  email: string;
  role: Role;
}

interface InviteDialogProps extends DialogProps {
  project: Project;
  onSubmit: (data: SubmissionData) => Promise<void>;
  onCancel: () => void;
}

export function InviteDialog(props: InviteDialogProps, ctx: ViewContext) {
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $$open = $$(props.$$open);

  const $$email = $$("");
  const $$role = $$<Role>("collaborator");

  const $$isSubmitting = $$(false);

  const $$formElement = $$<HTMLFormElement>();
  const $$emailInputElement = $$<HTMLInputElement>();

  ctx.onConnected(() => {
    const el = $$emailInputElement.get()!;
    el.focus();
    // el.scrollIntoView({
    //   behavior: "smooth",
    //   block: "center",
    // });
  });

  return (
    <Dialog
      accentColor={props.project.color}
      onsubmit={() => {
        const email = $$email.get();
        const role = $$role.get();

        $$isSubmitting.set(true);
        props.onSubmit({ email, role }).finally(() => {
          $$isSubmitting.set(false);
          $$open.set(false);
        });
      }}
      transitionIn={props.transitionIn}
      transitionOut={props.transitionOut}
    >
      <DialogHeader $$open={$$open} title={translate("workspace.project.inviteDialog.dialogTitle")} />

      <DialogContent>
        <section class={[styles.section, styles.email]}>
          <label for="invite-email">{translate("workspace.project.inviteDialog.emailHeading")}</label>
          <TextInput
            ref={$$emailInputElement}
            disabled={$$isSubmitting}
            $$value={$$email}
            id="invite-email"
            placeholder="person@example.com"
            autocomplete={false}
            type="email"
          />
        </section>

        <section class={[styles.section, styles.role]}>
          <label for="invite-role">{translate("workspace.project.inviteDialog.roleHeading")}</label>
          <SegmentButton
            id="invite-role"
            value={$($$role)}
            disabled={$$isSubmitting}
            onChange={$$role.set}
            segments={[
              {
                value: "viewer",
                content: <div>{translate("workspace.project.roles.viewer.title")}</div>,
              },
              {
                value: "collaborator",
                content: <div>{translate("workspace.project.roles.collaborator.title")}</div>,
              },
              {
                value: "admin",
                content: <div>{translate("workspace.project.roles.admin.title")}</div>,
              },
            ]}
          />
          <p class={styles.roleDescription}>
            {$($$role, (role) => {
              return translate(`workspace.project.roles.${role}.description`);
            })}
          </p>
        </section>
      </DialogContent>

      <DialogFooter>
        <Button
          type="button"
          disabled={$$isSubmitting}
          onClick={() => {
            $$open.set(false);
            props.onCancel();
          }}
        >
          {translate("common.cancel")}
        </Button>
        <Button
          type="submit"
          disabled={$(
            $$email,
            $$isSubmitting,
            (email, submitting) =>
              submitting || email.trim().length === 0 || !/^.+\@.+?\..+$/.test(email.trim()),
          )}
        >
          {translate("workspace.project.inviteDialog.submitButtonText")}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
