import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";

export interface RelativePastTimeOptions {
  referenceDate?: Date;
}

export function relativePastTime(lang: string, date: string | Date, options?: RelativePastTimeOptions) {
  date = new Date(date);
  const reference = options?.referenceDate ?? new Date();

  if (lang === "tok") {
    return getTenpoPona(date, reference);
  }

  // Just now (use translated string if null is returned)
  if (differenceInMinutes(reference, date) < 1) {
    return null;
  }

  // Minutes
  if (differenceInHours(reference, date) < 1) {
    return new Intl.RelativeTimeFormat(lang).format(differenceInMinutes(date, reference), "minutes");
  }

  // Hours
  if (differenceInDays(reference, date) < 1) {
    return new Intl.RelativeTimeFormat(lang).format(differenceInHours(date, reference), "hours");
  }

  // Days
  if (differenceInWeeks(reference, date) < 1) {
    return new Intl.RelativeTimeFormat(lang).format(differenceInDays(date, reference), "days");
  }

  // Weeks
  if (differenceInMonths(reference, date) < 1) {
    return new Intl.RelativeTimeFormat(lang).format(differenceInWeeks(date, reference), "weeks");
  }

  // Months
  if (differenceInYears(reference, date) < 1) {
    return new Intl.RelativeTimeFormat(lang).format(differenceInMonths(date, reference), "months");
  }

  // Years
  return new Intl.RelativeTimeFormat(lang).format(differenceInYears(date, reference), "years");
}

function getTenpoPona(date: Date, reference: Date) {
  // Just now
  if (differenceInMinutes(reference, date) < 1) {
    return "tenpo ni";
  }

  // Minutes
  if (differenceInHours(reference, date) < 1) {
    return `${differenceInMinutes(reference, date, { roundingMethod: "round" })} kipisi suno meso pini`;
  }

  // Hours
  if (differenceInDays(reference, date) < 1) {
    return `${differenceInHours(reference, date, { roundingMethod: "round" })} kipisi suno suli pini`;
  }

  // Days
  if (differenceInWeeks(reference, date) < 1 || differenceInMonths(reference, date) < 1) {
    return `${differenceInDays(reference, date)} suno pini`;
  }

  // Months
  if (differenceInYears(reference, date) < 1) {
    return `${differenceInMonths(reference, date)} mun pini`;
  }

  // Years
  return `${differenceInYears(reference, date)} sike pini`;
}
