export default {
  "container": "ChatEditor_container_b0c05",
  "editor": "ChatEditor_editor_b0c05",
  "placeholder": "ChatEditor_placeholder_b0c05",
  "searchMenu": "ChatEditor_searchMenu_b0c05",
  "attachments": "ChatEditor_attachments_b0c05",
  "inputFormAttachment": "ChatEditor_inputFormAttachment_b0c05",
  "attachmentToolbar": "ChatEditor_attachmentToolbar_b0c05",
  "attachmentMeta": "ChatEditor_attachmentMeta_b0c05",
  "attachmentSizeLabel": "ChatEditor_attachmentSizeLabel_b0c05",
  "attachmentProgressBar": "ChatEditor_attachmentProgressBar_b0c05",
  "form": "ChatEditor_form_b0c05"
};import "ni:sha-256;7eSDuNtOOEJvtHSDEUJG6yPrALouBvJOghIoH7rpeFg";