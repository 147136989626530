import { $, Readable, repeat, RouterStore, ViewContext } from "@manyducks.co/dolla";
import { NavStore } from "@stores/NavStore";
import Star from "@views/@icons/Star";
import styles from "./TabBar.module.css";

export interface Tab {
  text: string | Readable<string>;
  href: string | Readable<string>;
  active: (pattern: string) => boolean;
  hidden?: boolean | Readable<boolean>;
}

interface TabBarProps {
  tabs: Tab[] | Readable<Tab[]>;
}

export function TabBar(props: TabBarProps, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const nav = ctx.getStore(NavStore);

  const $currentPageIsFavorite = $(router.$path, nav.$favorites, (path, favorites) =>
    favorites.some((f) => f.path === path),
  );

  return (
    <nav class={styles.nav} style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}>
      <ul>
        {repeat(
          props.tabs,
          (tab) => $(tab.href).get(),
          ($tab) => (
            <li class={{ [styles.hidden]: $($tab, (t) => t.hidden) }}>
              <a
                href={$($tab, (t) => t.href)}
                class={{
                  [styles.active]: $(router.$pattern, $tab, (pattern, tab) =>
                    pattern ? tab.active(pattern) : false,
                  ),
                }}
              >
                {$($tab, (t) => t.text)}
              </a>
            </li>
          ),
        )}
      </ul>
      <div class={styles.buttons}>
        <button
          class={[styles.favoriteButton, { [styles.active]: $currentPageIsFavorite }]}
          onClick={(e) => {
            e.preventDefault();
            if ($currentPageIsFavorite.get()) {
              const path = router.$path.get();
              const favorite = nav.$favorites.get().find((f) => f.path === path);
              if (favorite) {
                nav.removeFavorite(favorite.id);
              }
            } else {
              nav.addFavorite(router.$path.get());
            }
          }}
        >
          <Star />
        </button>
      </div>
    </nav>
  );
}
