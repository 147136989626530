export default {
  "calendar": "CalendarView_calendar_655da",
  "header": "CalendarView_header_655da",
  "days": "CalendarView_days_655da",
  "day": "CalendarView_day_655da",
  "notInMonth": "CalendarView_notInMonth_655da",
  "isToday": "CalendarView_isToday_655da",
  "isSelected": "CalendarView_isSelected_655da",
  "dayButton": "CalendarView_dayButton_655da",
  "dayHeader": "CalendarView_dayHeader_655da",
  "dayMonthLabel": "CalendarView_dayMonthLabel_655da",
  "dayItems": "CalendarView_dayItems_655da",
  "dayItem": "CalendarView_dayItem_655da",
  "completed": "CalendarView_completed_655da",
  "hiddenMessage": "CalendarView_hiddenMessage_655da",
  "daySection": "CalendarView_daySection_655da",
  "empty": "CalendarView_empty_655da"
};import "ni:sha-256;Zu4Kz4uIg3uwQbImTyKbqSEN-mi0q0auTjfn6WjGsPI";