import { $$, $, type InputType, type Readable, type ViewContext, type Writable } from "@manyducks.co/dolla";
import styles from "./TextInput.module.css";

interface TextInputProps {
  ref?: Writable<HTMLInputElement> | Writable<HTMLInputElement | undefined>;
  value?: string | Writable<string>;
  $$value?: Writable<string>;
  class?: any | Readable<any>;
  autofocus?: boolean;
  autocomplete?: boolean;
  id?: string;
  type?: InputType;
  name?: string;
  placeholder?: string | Readable<string>;
  minlength?: number | Readable<number>;
  onChange?: (value: Event) => void;
  onKeyDown?: (value: KeyboardEvent) => void;
}

export function TextInput(props: TextInputProps, ctx: ViewContext) {
  const $$value = $$(props.$$value ?? props.value ?? "");
  const $class = $(props.class);
  const $placeholder = $(props.placeholder ?? "");

  const { autofocus, autocomplete, onChange, onKeyDown, id, type, name } = props;

  return (
    <input
      ref={props.ref}
      className={[styles.input, $class]}
      id={id}
      type={type}
      name={name}
      placeholder={$placeholder}
      minLength={props.minlength}
      autofocus={autofocus}
      autocomplete={autocomplete}
      $$value={$$value}
      onKeyDown={(e) => {
        if (onKeyDown) {
          onKeyDown(e);
        }
      }}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        }
      }}
    />
  );
}
