import { LanguageStore, ViewContext } from "@manyducks.co/dolla";
import styles from "./NoProjectSelected.module.css";

export function NoProjectSelected(props: {}, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  return (
    <div class={styles.layout} style={{ backgroundImage: "url(/textures/inspiration-geometry.png)" }}>
      <p class={styles.message}>{translate("workspace.project.noProjectSelectedMessage")}</p>
    </div>
  );
}
