export default {
  "loader": "DuckLoader_loader_5ba9c",
  "loaderPane": "DuckLoader_loaderPane_5ba9c",
  "visible": "DuckLoader_visible_5ba9c",
  "animating": "DuckLoader_animating_5ba9c",
  "container": "DuckLoader_container_5ba9c",
  "outerCircle": "DuckLoader_outerCircle_5ba9c",
  "cardLoaderOuterSpin": "DuckLoader_cardLoaderOuterSpin_5ba9c",
  "innerCircle": "DuckLoader_innerCircle_5ba9c",
  "cardLoaderInnerSpin": "DuckLoader_cardLoaderInnerSpin_5ba9c",
  "emoji": "DuckLoader_emoji_5ba9c",
  "cardLoaderDuckFloat": "DuckLoader_cardLoaderDuckFloat_5ba9c",
  "background": "DuckLoader_background_5ba9c"
};import "ni:sha-256;W6sgoHLqXiH5khHyflVL7YMkTPV9xEaWf0TrOVZeT9U";