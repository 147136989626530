import {
  $,
  DialogStore,
  LanguageStore,
  RouterStore,
  type Readable,
  type ViewContext,
} from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { NavStore } from "@stores/NavStore";
import { NotesStore } from "@stores/NotesStore";
import { ProjectsStore } from "@stores/ProjectsStore";
import { TasksStore } from "@stores/TasksStore";
import { ThemeStore } from "@stores/ThemeStore";
import Close from "@views/@icons/Close";
import { TaskEditDialog } from "@views/TaskListItem/TaskEditDialog/TaskEditDialog";
import { Favorite, Task } from "schemas";
import styles from "./FavoriteListItem.module.css";

interface FavoriteListItemProps {
  $favorite: Readable<Favorite>;
  onClick?: () => void;
}

export function FavoriteListItem(props: FavoriteListItemProps, ctx: ViewContext) {
  const router = ctx.getStore(RouterStore);
  const auth = ctx.getStore(AuthStore);
  const projects = ctx.getStore(ProjectsStore);
  const tasks = ctx.getStore(TasksStore);
  const notes = ctx.getStore(NotesStore);
  const theme = ctx.getStore(ThemeStore);
  const nav = ctx.getStore(NavStore);
  const dialog = ctx.getStore(DialogStore);
  const { translate, $currentLanguage } = ctx.getStore(LanguageStore);

  const $details = $(
    props.$favorite,
    auth.$me,
    projects.$cache,
    notes.$cache,
    tasks.$cache,
    $currentLanguage,
    (favorite, me, projectsCache, notesCache, tasksCache, _) => {
      let title = favorite.path;
      let icon = "/icons/chat.8.trans.png";
      let color = "#888";
      let _task: Task | undefined;

      if (favorite.path.startsWith("/projects/")) {
        const projectId = parseInt(favorite.path.split("/")[2]);
        const project = projectsCache.find((p) => p.id === projectId);
        if (!project) {
          color = "#f00";
          title = "MISSING PROJECT";
        } else {
          color = project.color;

          if (favorite.path.includes("/tasks")) {
            icon = "/icons/tasks.8.trans.png";

            const taskId = parseInt(favorite.path.split("/")[4]);
            if (!isNaN(taskId)) {
              // SIDE EFFECT
              tasks.ensureTaskIsLoaded(taskId);

              const task = tasksCache.get(taskId);
              if (task) {
                title = task.title;
                _task = task;
              } else {
                title = "MISSING TASK";
                color = "#f00";
              }
            } else {
              title = translate("workspace.nav.favorites.labels.tasksList_projectName", {
                projectName: project.name,
              }).get();
            }
          } else if (favorite.path.includes("/notes")) {
            icon = "/icons/notes.8.trans.png";

            const noteId = parseInt(favorite.path.split("/")[4]);
            if (!isNaN(noteId)) {
              // SIDE EFFECT
              notes.ensureNoteIsLoaded(noteId);

              const note = notesCache.get(noteId);
              if (note) {
                title = note.title;
              } else {
                title = "MISSING NOTE";
                color = "#f00";
              }
            } else {
              title = translate("workspace.nav.favorites.labels.notesList_projectName", {
                projectName: project.name,
              }).get();
            }
          } else {
            // Overview
            icon = "/icons/projects.8.trans.png";
            title = translate("workspace.nav.favorites.labels.overview_projectName", {
              projectName: project.name,
            }).get();
          }
        }
      } else if (favorite.path.startsWith("/me")) {
        color = me?.color || "#888";

        if (favorite.path.startsWith("/me/tasks")) {
          icon = "/icons/tasks.8.trans.png";
          title = translate("workspace.me.tasks.tabName").get();
        } else if (favorite.path.startsWith("/me/settings")) {
          icon = "/icons/settings.8.trans.png";
          title = translate("workspace.me.settings.tabName").get();
        }
      } else if (favorite.path.startsWith("/admin")) {
        color = me?.color || "#888";

        if (favorite.path.startsWith("/admin/server-stats")) {
          icon = "/icons/rooms.8.trans.png";
          title = translate("workspace.admin.serverStats.tabName").get();
        }
      }

      return { title, icon, color, _task };
    },
  );

  const $active = $(props.$favorite, router.$path, (favorite, path) => favorite.path === path);

  return (
    <li
      class={[styles.listItem, { [styles.active]: $active }]}
      style={theme.getThemeVariables$($($details, (x) => x.color))}
    >
      <a
        class={styles.link}
        href={$(props.$favorite, (f) => f.path)}
        onClick={(e) => {
          const details = $details.get();
          if (details._task) {
            // Open task dialog if it's a task.
            e.preventDefault();
            const project = projects.$cache.get().find((p) => p.id === details._task!.projectId);
            dialog.open(TaskEditDialog, {
              task: details._task,
              projectId: details._task.projectId,
              color: project?.color,
              onToListClicked: () => {
                router.navigate(`/projects/${details._task!.projectId}/tasks?task=${details._task!.id}`);
                props.onClick?.();
              },
            });
          } else {
            props.onClick?.();
          }
        }}
      >
        <div class={styles.icon}>
          <img src={$($details, (x) => x.icon)} alt="" />
        </div>
        <span class={styles.name}>{$($details, (x) => x.title)}</span>
        <div class={styles.controls}>
          <button
            onClick={(e) => {
              e.preventDefault();
              nav.removeFavorite(props.$favorite.get().id);
            }}
            class={styles.removeButton}
          >
            <Close />
          </button>
        </div>
      </a>
    </li>
  );
}
