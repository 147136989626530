export default {
  "container": "MoreMenu_container_f60fa",
  "small": "MoreMenu_small_f60fa",
  "button": "MoreMenu_button_f60fa",
  "card": "MoreMenu_card_f60fa",
  "open": "MoreMenu_open_f60fa",
  "icon": "MoreMenu_icon_f60fa",
  "menu": "MoreMenu_menu_f60fa",
  "listButton": "MoreMenu_listButton_f60fa",
  "destructive": "MoreMenu_destructive_f60fa"
};import "ni:sha-256;l8IS6g4neYGeJjy8sP1cXXxzuY0h-UTGdByea9Ym-C0";