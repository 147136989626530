import { $, $$, LanguageStore, type Readable, type ViewContext } from "@manyducks.co/dolla";
import Calendar from "@views/@icons/Calendar";
import DownArrow from "@views/@icons/DownArrow";
import { CalendarBlockView } from "@views/CalendarInput";
import { HoverMenu } from "@views/HoverMenu";
import styles from "./DatePicker.module.css";

// Works with values in YYYY-MM-DD format.

interface DatePickerProps {
  $value: Readable<string | null> | Readable<string | undefined>;
  $min?: Readable<Date> | Readable<Date | undefined>;
  onChange: (newValue: string | null) => void;
  $disabled?: Readable<boolean>;
  placeholder?: string | Readable<string>;
  accentColor?: string | Readable<string>;
}

export function DatePicker(props: DatePickerProps, ctx: ViewContext) {
  const { $currentLanguage } = ctx.getStore(LanguageStore);

  const $$menuIsOpen = $$(false);
  const $$menuAnchor = $$<HTMLElement>();

  return (
    <div class={styles.container}>
      <button
        type="button"
        ref={$$menuAnchor}
        class={styles.button}
        onClick={(e) => {
          $$menuIsOpen.set(true);
        }}
      >
        <div class={styles.icon}>
          <Calendar />
        </div>
        <div class={styles.label}>
          {$(props.$value, props.placeholder, $currentLanguage, (value, placeholder, lang) => {
            if (value) {
              const [y, m, d] = value.split("-").map((x) => Number(x));
              return new Intl.DateTimeFormat([lang!, "en-US"], {
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              }).format(new Date(y, m - 1, d));
            } else {
              return placeholder;
            }
          })}
        </div>
        <div class={styles.indicator}>
          <DownArrow />
        </div>
      </button>

      <HoverMenu
        $$open={$$menuIsOpen}
        $anchorRef={$$menuAnchor}
        color={props.accentColor}
        distanceFromAnchor={14}
        preferHorizontalAlignment="center"
        preferVerticalAlignment="below"
      >
        <div class={styles.calendar}>
          <CalendarBlockView
            $$date={$$(props.$value, {
              get: () => {
                const date = props.$value.get();
                if (date) {
                  const [y, m, d] = date.split("-").map((n) => Number(n));
                  return new Date(y, m - 1, d);
                }
              },
              set: (value) => {
                props.onChange(value ? value.toISOString().split("T")[0] : null);
              },
            })}
            $min={props.$min}
          />
        </div>
      </HoverMenu>
    </div>
  );
}
