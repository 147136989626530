import { $, $$, LanguageStore, type DialogProps, type ViewContext } from "@manyducks.co/dolla";
import { AuthStore } from "@stores/AuthStore";
import { Button } from "@views/Button";
import { Dialog, DialogContent, DialogFooter, DialogHeader } from "@views/Dialog";
import { TextInput } from "@views/TextInput";

interface UserStatusEditProps extends DialogProps {
  initialValue?: string;

  /**
   * Called when confirmation is successful
   */
  onSave: (value: string) => void;
}

/**
 * A dialog to confirm permanent deletion of some data.
 */
export function UserStatusEdit(props: UserStatusEditProps, ctx: ViewContext) {
  const { translate } = ctx.getStore(LanguageStore);

  const { $me } = ctx.getStore(AuthStore);
  const $avatar = $($me, (user) => user?.avatar ?? "");

  const $$inputValue = $$(props.initialValue ?? "");
  const $$open = $$(props.$$open);
  const { onSave } = props;

  const $$inputElement = $$<HTMLInputElement>();

  ctx.onConnected(() => {
    $$inputElement.get()!.select();
  });

  return (
    <Dialog
      transitionIn={props.transitionIn}
      transitionOut={props.transitionOut}
      onsubmit={() => {
        onSave($$inputValue.get());
        $$open.set(false);
      }}
      accentColor={$($me, (m) => m?.color ?? "#888")}
    >
      <DialogHeader $$open={$$open} title={translate("people.editStatusDialog.title")} icon={$avatar} />

      <DialogContent>
        <TextInput
          ref={$$inputElement}
          value={$$inputValue}
          placeholder={translate("people.editStatusDialog.inputPlaceholder")}
        />
      </DialogContent>

      <DialogFooter>
        <Button type="submit">{translate("people.editStatusDialog.saveButtonText")}</Button>
      </DialogFooter>
    </Dialog>
  );
}
