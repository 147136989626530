export default {
  "layout": "NavMenu_layout_3c1c9",
  "toolbar": "NavMenu_toolbar_3c1c9",
  "content": "NavMenu_content_3c1c9",
  "menuSection": "NavMenu_menuSection_3c1c9",
  "menuHeader": "NavMenu_menuHeader_3c1c9",
  "menuList": "NavMenu_menuList_3c1c9",
  "projectListItem": "NavMenu_projectListItem_3c1c9",
  "itemDivider": "NavMenu_itemDivider_3c1c9",
  "project": "NavMenu_project_3c1c9",
  "projectName": "NavMenu_projectName_3c1c9",
  "projectInfo": "NavMenu_projectInfo_3c1c9",
  "projectIcon": "NavMenu_projectIcon_3c1c9",
  "addProjectSection": "NavMenu_addProjectSection_3c1c9",
  "justifyEnd": "NavMenu_justifyEnd_3c1c9",
  "toolButtonIcon": "NavMenu_toolButtonIcon_3c1c9",
  "projectContent": "NavMenu_projectContent_3c1c9",
  "projectMenu": "NavMenu_projectMenu_3c1c9",
  "roleBadge": "NavMenu_roleBadge_3c1c9",
  "projectMenuHeader": "NavMenu_projectMenuHeader_3c1c9",
  "projectMenuHeaderText": "NavMenu_projectMenuHeaderText_3c1c9",
  "projectMenuHeaderIcon": "NavMenu_projectMenuHeaderIcon_3c1c9",
  "projectMenuMeta": "NavMenu_projectMenuMeta_3c1c9",
  "navLink": "NavMenu_navLink_3c1c9",
  "active": "NavMenu_active_3c1c9",
  "navLinkIcon": "NavMenu_navLinkIcon_3c1c9",
  "updateButton": "NavMenu_updateButton_3c1c9",
  "updateButtonIcon": "NavMenu_updateButtonIcon_3c1c9"
};import "ni:sha-256;7ue9phRheNLK_Xw_Yx4PSMQhkncghyzsJyBRwoIW72c";