export default {
  "layout": "ServerStats_layout_8c2a2",
  "content": "ServerStats_content_8c2a2",
  "sectionContent": "ServerStats_sectionContent_8c2a2",
  "sectionList": "ServerStats_sectionList_8c2a2",
  "sectionHeader": "ServerStats_sectionHeader_8c2a2",
  "sectionRow": "ServerStats_sectionRow_8c2a2",
  "headerTitle": "ServerStats_headerTitle_8c2a2",
  "sectionLabel": "ServerStats_sectionLabel_8c2a2",
  "sectionValue": "ServerStats_sectionValue_8c2a2",
  "meterList": "ServerStats_meterList_8c2a2",
  "meterMeta": "ServerStats_meterMeta_8c2a2",
  "meterTitle": "ServerStats_meterTitle_8c2a2",
  "meterValue": "ServerStats_meterValue_8c2a2",
  "meter": "ServerStats_meter_8c2a2",
  "fill": "ServerStats_fill_8c2a2",
  "cpuMeters": "ServerStats_cpuMeters_8c2a2",
  "cpuMeterBox": "ServerStats_cpuMeterBox_8c2a2",
  "oneQuarter": "ServerStats_oneQuarter_8c2a2",
  "threeQuarters": "ServerStats_threeQuarters_8c2a2",
  "cpuMeterGroup": "ServerStats_cpuMeterGroup_8c2a2",
  "cpuMeterValue": "ServerStats_cpuMeterValue_8c2a2",
  "cpuMeterLabel": "ServerStats_cpuMeterLabel_8c2a2"
};import "ni:sha-256;0R0Z4i_WP9JsseW-yqcTnW7jx64nNRyWUST6KRQvXys";