import { type ViewContext } from "@manyducks.co/dolla";
import { Button, ButtonProps } from "../Button";
import styles from "./BackButton.module.css";
import ChevronLeft from "../@icons/ChevronLeft";

interface BackButtonProps extends ButtonProps {}

export function BackButton(props: BackButtonProps, ctx: ViewContext) {
  return (
    <Button {...props}>
      <div class={styles.backIcon}>
        <ChevronLeft />
      </div>
      {ctx.outlet()}
    </Button>
  );
}
